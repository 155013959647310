const SSA_MEDICAL_HISTORY = {
  SAS: {
    value: 0,
    text: 'SAS'
  },
  ATG: {
    value: 1,
    text: 'ATG'
  },
  None: {
    value: 2,
    text: 'None'
  },
  NotProvided: {
    value: 3,
    text: 'Not Provided'
  }
};

type SSA_MEDICAL_HISTORY = typeof SSA_MEDICAL_HISTORY;

export default SSA_MEDICAL_HISTORY;
