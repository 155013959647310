import module from "../module";

module.directive("globalValidator", function() {
  return {
    restrict: "A",
    priority: 500,
    controller
  };
});

function controller($scope) {
  "ngInject";
  let delegates = [],
    invalid = false;

  this.valid = () => !invalid && delegates.every(validate => validate());

  this.setInvalid = function() {
    invalid = true;
  };

  this.addValidator = function(delegate) {
    // note: delegate may be function or array of functions
    delegates = delegates.concat(delegate);

    const registeredDelegates = new Set([].concat(delegate));
    return function removeValidator() {
      delegates = delegates.filter(_ => !registeredDelegates.has(_));
    };
  };

  // todo: remove (some directives use those scope methods)
  $scope.valid = this.valid;
  $scope.addValidator = this.addValidator;
}
