import module from "../../module";
import template from "./pdf-page-print.tmpl.html";

module.component("ppmPdfPagePrint", {
  bindings: {
    document: "<",
    patient: "<",
    page: "<",
    annotations: "<",
    onRender: "&"
  },
  controller,
  template
});

function controller($element) {
  "ngInject";

  const vm = this,
    canvas = $element[0].querySelector("canvas.page-image");

  vm.$onInit = function() {
    const renderResult = render(canvas, vm.page);
    vm.initialViewport = vm.page.getViewport(1);
    vm.imageViewport = renderResult.viewport;

    renderResult.promise.then(_ => vm.onRender({ page: vm.page }));
  };
}

function render(canvas, page) {
  const dimensions = calcDimensions(page);

  canvas.width = dimensions.canvasWidth;
  canvas.height = dimensions.canvasHeight;

  const promise = page.render({
    canvasContext: canvas.getContext("2d"),
    viewport: dimensions.viewport
  });

  const viewport = {
    width: dimensions.canvasWidth,
    height: dimensions.canvasHeight
  };

  return {
    promise,
    viewport
  };
}

function calcDimensions(page) {
  const scale = 4,
    scaledViewport = page.getViewport(scale),
    canvasWidth = Math.floor(scaledViewport.width),
    canvasHeight = Math.floor(scaledViewport.height);

  return {
    canvasWidth,
    canvasHeight,
    viewport: scaledViewport
  };
}
