import INotification from '#/interface/core/entity/notification';
import notificationSeverityEnum from '#/constants/notification-severity';
import { toArray } from '#/utils/enum';

import module from '../module';
import template from './notification-container.tmpl.html';

module.component('ppmNotificationContainer', {
  bindings: {
    notifications: '<'
  },
  controller,
  template
});

interface IController {
  showNotifications: boolean;
  notifications: INotification[];
  showClass(notificationSeverity: number): string;
  handleShowNotifications(): void;
}

function controller(this: IController) {
  'ngInject';
  const vm = this;

  vm.showNotifications = false;

  vm.showClass = function(notificationSeverity) {
    const severity = toArray(notificationSeverityEnum).find(_ => _.value === notificationSeverity);
    return (severity && severity.cssClass) || notificationSeverityEnum.warning.cssClass;
  };

  vm.handleShowNotifications = function() {
    vm.showNotifications = true;
  };
}
