import angular from "angular";
import { searchQuery, diffObject } from "#/utils/api";

import module from "./module";

module.factory("ApiPatientsService", ApiPatientsService);

/*@ngInject*/
function ApiPatientsService($q, $filter, ContactInfoClearService, ApiService) {
  const api = {};
  let enrollmentsReasons;

  // Patients
  api.get = function(id) {
    return ApiService.get(`patients/${id}`);
  };

  api.search = function(params) {
    /*const params = {
            term: '',
            start: 0,
            count: 1,
            sortArray: [],
            states: null or undefined,
            suspensionStatus: 0,
            customerId: false,
             enrollmentTypes: '',
        }*/
    const obj = searchQuery(params.term, params.start, params.count, params.sortArray);

    if (params.enrollmentTypes) obj.enrollmentTypes = params.enrollmentTypes;

    if (params.enrollmentSpecialities) obj.enrollmentSpecialities = params.enrollmentSpecialities;

    if (params.suspensionStatus) obj.suspensionStatus = params.suspensionStatus.value;

    if (params.states != null) obj.states = params.states;

    obj.customerId = params.customerId;

    return ApiService.get(`patients`, obj).then(data => {
      data.requestId = params.requestId;
      return data;
    });
  };

  api.getSimilarPatients = function(patient) {
    const name = patient.name,
      customerId = patient.customerID,
      { year, month, day } = patient.demographicData.dateOfBirth,
      ids = patient.patientIDs.iDs
        .map(_ => ({ value: _.value, typeId: _.typeID }))
        .filter(_ => _.value),
      isValidId = ids.length > 0,
      isValidName =
        name.givenNames.length > 0 &&
        name.surname &&
        [year, month, day].every(_ => Number.isFinite(_));

    if (!customerId || (!isValidName && !isValidId)) return $q.resolve([]);

    const query = {
      customerId
    };

    if (isValidId) query["patientId[]"] = ids;

    if (isValidName) {
      query.name = `${name.givenNames.join(" ")} ${name.surname}`;
      query.dateOfBirth = `${day}-${month}-${year}`;
    }

    return ApiService.get(`similar-patients`, query);
  };

  api.register = function(info) {
    var patientInfo = angular.copy(info);
    convertNumbersForSms(patientInfo);
    clearEmptyFields(patientInfo);
    clearEmptyContactInfo(patientInfo);
    return ApiService.post(`patients`, patientInfo);
  };

  api.change = function(patientId, info, oldInfo, influentDocumentId) {
    var patientInfo = angular.copy(info),
      diff;
    clearEmptyContactInfo(patientInfo);
    diff = diffObject(oldInfo, patientInfo, true);
    if (!diff) {
      return $q.when(true);
    }
    diff.influentDocumentId = influentDocumentId ? influentDocumentId : null;
    convertNumbersForSms(diff);
    return ApiService.patch(`patients/${patientId}`, diff);
  };

  // Patient's enrollments
  api.getEnrollment = function(patientId, serviceKey, enrollmentId) {
    return ApiService.get(`patients/${patientId}/enrollments/${serviceKey}/${enrollmentId}`).then(
      cleanResponse
    );
  };

  api.getEnrollments = function(patientId, enrollmentsEnum) {
    var promises = [];
    if (angular.isArray(enrollmentsEnum)) {
      promises = enrollmentsEnum.map(function(e) {
        return api.getEnrollment(patientId, e.serviceKey, e.id);
      });
    }

    return $q.all(promises).then(function(enrollments) {
      $filter("orderBy")(enrollments, ["state", "type"], true);
      return enrollments;
    });
  };

  api.getEnrollmentsByPatientId = function(patientId) {
    return api.get(patientId).then(function(patientData) {
      return api.getEnrollments(patientData.id, patientData.enrollments);
    });
  };

  api.updateEnrollment = function(patientId, enrollment, oldEnrollment, influentDocumentId) {
    if (enrollment.isNew) {
      return addEnrollment(patientId, enrollment, influentDocumentId);
    } else {
      return updateEnrollment(patientId, enrollment, oldEnrollment, influentDocumentId);
    }
  };

  api.getNotifications = function(patientId) {
    return ApiService.get(`patients/${patientId}/notifications`);
  };

  api.groupNotificationsByEnrollmentId = function(notifications) {
    const notificationsHash = {},
      result = [];

    notifications.forEach(notification => {
      if (notificationsHash[notification.target.enrollmentID]) {
        result
          .filter(n => n.id === notification.target.enrollmentID)[0]
          .notifications.push(notification);
        return;
      }

      result.push({
        id: notification.target.enrollmentID,
        notifications: [notification]
      });
      notificationsHash[notification.target.enrollmentID] = true;
    });
    return result;
  };

  api.suspend = function(patientId, reason) {
    return ApiService.post(`patients/${patientId}/suspend`, { reason });
  };
  api.unsuspend = function(patientId) {
    return ApiService.post(`patients/${patientId}/unsuspend`);
  };

  api.getEnrollmentsReasons = function() {
    if (!enrollmentsReasons) enrollmentsReasons = ApiService.get(`patients/enrollments-reasons`);
    return enrollmentsReasons;
  };

  api.notifyCallAttempt = function({ id, phone, calleeName }) {
    return ApiService.post(`patients/${id}/notify-call-attempt`, { phone, calleeName });
  };

  api.sendSms = function(patientId, smsData) {
    return ApiService.post(`patients/${patientId}/send-sms`, smsData);
  };

  api.sendConfirmationEmail = function(
    patientId,
    taskId,
    appointmentDate,
    appointmentTime,
    appointmentDuration,
    locationType,
    locationId,
    locationDetails
  ) {
    return ApiService.post(`patients/${patientId}/send-confirmation-email`, {
      taskId,
      appointmentDate,
      appointmentTime,
      appointmentDuration,
      locationType,
      locationId,
      locationDetails
    });
  };

  return api;

  //////////////////////////////////
  function convertNumbersForSms(patientInfo) {
    if (patientInfo.hasOwnProperty("contactInfo")) {
      patientInfo.contactInfo.phones.forEach(phone => {
        if (phone.canSendSms && phone.value.charAt(0) === "0") {
          phone.value = "+44" + phone.value.substring(1);
        }
      });
    }

    if (patientInfo.hasOwnProperty("nominatedContacts")) {
      patientInfo.nominatedContacts.contacts.forEach(contact => {
        if (contact.hasOwnProperty("contactInfo")) {
          contact.contactInfo.phones.forEach(phone => {
            if (phone.canSendSms && phone.value.charAt(0) === "0") {
              phone.value = "+44" + phone.value.substring(1);
            }
          });
        }
      });
    }

    if (patientInfo.hasOwnProperty("communicationRequirements")) {
      if (patientInfo.communicationRequirements.hasOwnProperty("preferredSms")) {
        let sms = patientInfo.communicationRequirements.preferredSms;
        if (sms && sms.value.charAt(0) === "0") {
          sms.value = "+44" + sms.value.substring(1);
        }
      }

      if (patientInfo.communicationRequirements.hasOwnProperty("preferredPhone")) {
        let phone = patientInfo.communicationRequirements.preferredPhone;

        if (phone && phone.value.charAt(0) === "0") {
          let phoneValue = null;

          let phoneWithCountryCode = "+44" + phone.value.substring(1);

          if (phone.nominatedContactId && patientInfo.hasOwnProperty("nominatedContacts")) {
            const nomContact = patientInfo.nominatedContacts.contacts.find(
              c => c.id === phone.nominatedContactId
            );
            phoneValue = nomContact.contactInfo.phones.find(p => p.value === phoneWithCountryCode);
          } else if (patientInfo.hasOwnProperty("contactInfo")) {
            phoneValue = patientInfo.contactInfo.phones.find(p => p.value === phoneWithCountryCode);
          }

          if (phoneValue) {
            phone.value = phoneWithCountryCode;
          }
        }
      }
    }
  }

  function clearEmptyFields(patientInfo) {
    var keys;
    for (var i in patientInfo) {
      if (!patientInfo.hasOwnProperty(i)) {
        continue;
      }
      if (angular.isArray(patientInfo[i]) && patientInfo[i].length === 0) {
        delete patientInfo[i];
        continue;
      }
      if (patientInfo[i] == null || angular.equals(patientInfo[i], {})) {
        delete patientInfo[i];
        continue;
      }
      if (angular.isObject(patientInfo[i])) {
        keys = Object.keys(patientInfo[i]);
        if (keys.length === 1) {
          if (angular.isArray(patientInfo[i][keys[0]]) && patientInfo[i][keys[0]].length === 0) {
            delete patientInfo[i];
            continue;
          }
        }
      }
    }
  }

  function clearEmptyContactInfo(patientInfo) {
    if (patientInfo.nominatedContacts && patientInfo.nominatedContacts.contacts) {
      patientInfo.nominatedContacts.contacts.forEach(contact =>
        ContactInfoClearService.clear(contact.contactInfo)
      );
    }
    if (patientInfo.contactInfo) {
      ContactInfoClearService.clear(patientInfo.contactInfo);
    }
  }

  function addEnrollment(patientId, enrollment, influentDocumentId) {
    var enrollmentCopy = angular.copy(enrollment);

    delete enrollmentCopy.isNew;

    enrollmentCopy.influentDocumentId = influentDocumentId || null;
    return ApiService.post(`patients/${patientId}/enrollments`, enrollmentCopy);
  }

  function updateEnrollment(patientId, enrollment, oldEnrollment, influentDocumentId) {
    var diff,
      enrollmentCopy = copyEnrollment(enrollment),
      oldEnrollmentCopy = copyEnrollment(oldEnrollment);

    if (!enrollmentCopy.isSwitchingService) {
      delete enrollmentCopy.targetServiceId;
    }
    delete enrollmentCopy.isSwitchingService;

    diff = diffObject(oldEnrollmentCopy, enrollmentCopy, true);
    if (!diff || !enrollment.id) {
      return $q.when(true);
    }

    angular.extend(enrollmentCopy, {
      holdReviewDate: enrollment.isOnHold ? enrollment.holdReviewDate : null,
      holdReason: enrollment.isOnHold ? enrollment.holdReason : "",
      stoppedReason: enrollment.isStopped ? enrollment.stoppedReason : "",
      influentDocumentId: influentDocumentId || null
    });

    return ApiService.patch(
      `patients/${patientId}/enrollments/${enrollment.serviceKey}/${enrollment.id}`,
      enrollmentCopy
    );

    // privates
    function copyEnrollment(enrollment) {
      var result = angular.copy(enrollment);
      delete result.id;
      delete result.type;
      delete result.name;
      delete result.serviceKey;
      delete result.state;
      delete result.influentDocumentId;

      return result;
    }
  }

  function cleanResponse(object) {
    var result = {};

    Object.keys(object)
      .filter(function(prop) {
        return prop[0] !== "$";
      })
      .forEach(function(prop) {
        result[prop] = object[prop];
      });

    return result;
  }
}
