const CARE_SERVICE_TYPE = [
  { type: 0, directive: 'None' },
  { type: 1, directive: 'Methotrexate', text: 'Methotrexate' },
  { type: 2, directive: 'Etanercept', text: 'Etanercept' },
  { type: 3, directive: 'Growthhormone', text: 'GrowthHormone' },
  { type: 4, directive: 'Tocilizumab', text: 'Tocilizumab' },
  { type: 5, directive: 'Abatacept', text: 'Abatacept' },
  { type: 6, directive: 'Gumed', text: 'GUMED' },
  { type: 7, directive: 'Methotrexateunlicensed', text: 'Methotrexate Unlicensed' },
  { type: 8, directive: 'Methotrexatelicensed', text: 'Methotrexate Licensed' },
  { type: 9, directive: 'Desferral', text: 'Desferral' },
  { type: 10, directive: 'Infliximab', text: 'Infliximab' },
  { type: 11, directive: 'Oraloncology', text: 'Oral Oncology' },
  { type: 12, directive: 'Kineret', text: 'Kineret' },
  { type: 13, directive: 'Pembropilot', text: 'Pembro Pilot' },
  { type: 14, directive: 'Immunosuppressants', text: 'Immunosuppressants' },
  { type: 15, directive: 'Opat', text: 'Opat' },
  { type: 16, directive: 'Terbutaline', text: 'Terbutaline' },
  { type: 17, directive: 'Teriparatide', text: 'Teriparatide' },
  { type: 18, directive: 'Romiplostim', text: 'Romiplostim' },
  { type: 19, directive: 'Remsima', text: 'Remsima' },
  { type: 20, directive: 'Remicade', text: 'Remicade' },
  { type: 21, directive: 'Denosumabxgeva', text: 'Denosumab Xgeva' },
  { type: 22, directive: 'Trastuzumabherceptin', text: 'Trastuzumab Herceptin' },
  { type: 23, directive: 'Sandostatin', text: 'Sandostatin' },
  { type: 24, directive: 'Phlebotomy', text: 'Phlebotomy' },
  { type: 25, directive: 'Ux023cl301', text: 'UX023-CL301' },
  { type: 26, directive: 'Ux023cl303', text: 'UX023-CL303' },
  { type: 27, directive: 'Privigen', text: 'Privigen' },
  { type: 28, directive: 'Gammaplex', text: 'Gammaplex' },
  { type: 29, directive: 'Gumedmca', text: 'GU Med MCA' },
  { type: 30, directive: 'Oncology', text: 'Oncology' },
  { type: 31, directive: 'Rituximab', text: 'Rituximab' },
  { type: 32, directive: 'Inflectra', text: 'Inflectra' },
  { type: 33, directive: 'Migalastat', text: 'Migalastat' },
  { type: 34, directive: 'Erelzi', text: 'Erelzi' },
  { type: 35, directive: 'Eliglustat', text: 'Eliglustat' },
  { type: 36, directive: 'Balancestudy', text: 'Balance Study (PB-102-F20)' },
  { type: 37, directive: 'Bridgestudy', text: 'Bridge Study (PB-102-F30)' },
  { type: 38, directive: 'Pembrolizumab', text: 'Pembrolizumab' },
  { type: 39, directive: 'Cerezyme', text: 'Lysosomal Storage Disorders - Cerezyme' },
  { type: 40, directive: 'Veletri', text: 'Veletri' },
  { type: 41, directive: 'Cphoral', text: 'Cardiovascular Pulmonary Hypertension - Oral' },
  { type: 42, directive: 'Nivolumab', text: 'Nivolumab' },
  { type: 43, directive: 'Vpriv', text: 'Lysosomal Storage Disorders - VPRIV' },
  { type: 44, directive: 'Replagal', text: 'Lysosomal Storage Disorders - Replagal' },
  { type: 45, directive: 'Fabrazyme', text: 'Lysosomal Storage Disorders - Fabrazyme' },
  { type: 46, directive: 'Intratect', text: 'Intratect' },
  { type: 47, directive: 'Cimziaselect', text: 'Cimzia Select' },
  { type: 48, directive: 'Brightstudypb102f50', text: 'Bright Study (PB-102-F50)' },
  { type: 49, directive: 'Bevacizumabavastin', text: 'Bevacizumab (Avastin)' },
  { type: 50, directive: 'Bortezomibvelcade', text: 'Bortezomib (Velcade)' },
  { type: 51, directive: 'Fulvestrantfaslodex', text: 'Fulvestrant (Faslodex)' },
  { type: 52, directive: 'Miglustat', text: 'Lysosomal Storage Disorders - Miglustat' },
  { type: 53, directive: 'Atezolizumab', text: 'Atezolizumab' },
  { type: 54, directive: 'Myozyme', text: 'Lysosomal Storage Disorders - Myozyme' },
  { type: 55, directive: 'Aimovig', text: 'Aimovig' },
  { type: 56, directive: 'Aimovigfoc', text: 'Aimovig - FOC' },
  { type: 57, directive: 'Aimovigprivate', text: 'Aimovig - Private' },
  { type: 58, directive: 'Amgevitaadalimumab', text: 'Amgevita - Adalimumab' },
  { type: 59, directive: 'Hyrimozadalimumab', text: 'Hyrimoz - Adalimumab' },
  { type: 60, directive: 'Aldurazayme', text: 'Lysosomal Storage Disorders - Aldurazayme' },
  { type: 61, directive: 'Mimpara', text: 'Mimpara' },
  { type: 62, directive: 'Vimizim', text: 'Lysosomal Storage Disorders - Vimizim' },
  { type: 63, directive: 'Brilliancestudy', text: 'Brilliance Study (PB-102-F60)' },
  { type: 64, directive: 'Dacepton', text: 'Dacepton' },
  { type: 65, directive: 'Elaprase', text: 'Lysosomal Storage Disorders - Elaprase' },
  { type: 66, directive: 'Ilumetritildrakizumab', text: 'Ilumetri (Tildrakizumab)' },
  { type: 67, directive: 'Frozenazacitidine', text: 'Frozen Azacitidine' },
  { type: 68, directive: 'Rapamune', text: 'Rapamune' },
  { type: 69, directive: 'Sandostatinscotland', text: 'Sandostatin Scotland' },
  { type: 70, directive: 'Cimzia', text: 'Cimzia' },
  { type: 71, directive: 'Ravicti', text: 'Lysosomal Storage Disorders - Ravicti' },
  { type: 72, directive: 'Vedolizumab', text: 'Vedolizumab' },
  { type: 73, directive: 'Ilaris', text: 'Ilaris' },
  { type: 74, directive: 'Oncologycancercentrenhs', text: 'Oncology Cancer Centre – NHS' },
  { type: 75, directive: 'Xolairomalizumab', text: 'Xolair - Omalizumab' },
  { type: 76, directive: 'Normosang', text: 'Normosang' },
  { type: 77, directive: 'Hyqvia', text: 'HyQvia' },
  { type: 78, directive: 'Flebogamma', text: 'Flebogamma' },
  { type: 79, directive: 'Trastuzumabherzuma', text: 'Trastuzumab (Herzuma)' },
  { type: 80, directive: 'Daceptonprivate', text: 'Dacepton Private' },
  { type: 81, directive: 'Pembrolizumabpemetrexed', text: 'Pembrolizumab + Pemetrexed' },
  { type: 82, directive: 'Adultrespiratoryopat', text: 'Adult respiratory OPAT' },
  { type: 83, directive: 'Transplantvadopat', text: 'Transplant & VAD OPAT' },
  { type: 84, directive: 'Paediatricrespiratoryopat', text: 'Paediatric respiratory OPAT' },
  { type: 85, directive: 'Ivig', text: 'IVIG' },
  { type: 86, directive: 'Brightstudypb102f51', text: 'Bright Study (PB-102-F51)' },
  { type: 87, directive: 'Exjade', text: 'Exjade' },
  { type: 88, directive: 'Cetuximab', text: 'Cetuximab' },
  { type: 89, directive: 'Daratumumab', text: 'Daratumumab' },
  { type: 90, directive: 'Durvalumab', text: 'Durvalumab' },
  { type: 91, directive: 'Eribulin', text: 'Eribulin' },
  { type: 92, directive: 'Natalizumab', text: 'Natalizumab' },
  { type: 93, directive: 'Daratumumabbortezomib', text: 'Daratumumab + Bortezomib' },
  { type: 94, directive: 'Zoledronicacid', text: 'Zoledronic Acid' },
  { type: 95, directive: 'Denosumabprolia', text: 'Denosumab - Prolia' },
  { type: 96, directive: 'Magnesiumsulphate', text: 'Magnesium Sulphate' },
  { type: 97, directive: 'Evenity', text: 'Evenity' },
  { type: 98, directive: 'Daratumumabzoledronicacid', text: 'Daratumumab + Zoledronic Acid' },
  { type: 99, directive: 'Brentuximab', text: 'Brentuximab' },
  { type: 100, directive: 'Daratumumabsubcut', text: 'Daratumumab Sub Cut' },
  { type: 101, directive: 'Daratumumabsubcutbortezomib', text: 'Daratumumab Sub Cut + Bortezomib' },
  { type: 102, directive: 'Infliximabflixabi', text: 'Infliximab - Flixabi' },
  { type: 103, directive: 'Imfinzipsppilot', text: 'Imfinzi PSP Pilot' },
  { type: 104, directive: 'Olatuton', text: 'Olatuton' },
  { type: 105, directive: 'Ajovy', text: 'Ajovy' },
  { type: 106, directive: 'Mayzent', text: 'Mayzent' },
  { type: 107, directive: 'Ajovyprivate', text: 'Ajovy - Private' },
  { type: 108, directive: 'Iqymune', text: 'Iqymune' },
  { type: 109, directive: 'Gamunex', text: 'Gamunex' },
  { type: 110, directive: 'Cuvitru', text: 'Cuvitru' },
  { type: 111, directive: 'Hizentra', text: 'Hizentra' },
  { type: 112, directive: 'Subgam', text: 'Subgam' },
  { type: 113, directive: 'Kiovig', text: 'Kiovig' },
  { type: 114, directive: 'Octagam', text: 'Octagam' },
  { type: 115, directive: 'Vigam', text: 'Vigam' },
  { type: 116, directive: 'Rituximabivrixathon', text: 'Rituximab IV - Rixathon' },
  { type: 117, directive: 'Pertuzumabtrastuzumabsubcut', text: 'Pertuzumab + Trastuzumab Sub Cut' },
  { type: 118, directive: 'Vedolizumabsubcut', text: 'Vedolizumab Sub Cut' },
  { type: 119, directive: 'Trastuzumabontruzant', text: 'Trastuzumab (Ontruzant)' },
  { type: 120, directive: 'Libtayo', text: 'Libtayo' },
  { type: 121, directive: 'Cemiplimab', text: 'Cemiplimab' },
  { type: 122, directive: 'Evenitynhs', text: 'Evenity NHS' },
  { type: 123, directive: 'Pertuzumabtrastuzumabiv', text: 'Pertuzumab + Trastuzumab IV' },
  { type: 124, directive: 'Revolade', text: 'Revolade' },
  { type: 125, directive: 'Phesgo', text: 'Phesgo' },
  { type: 126, directive: 'Kesimpta', text: 'Kesimpta' },
  { type: 127, directive: 'Lamzede', text: 'Lamzede' },
  { type: 128, directive: 'Kisqali', text: 'Kisqali' },
  { type: 129, directive: 'Vx19147101', text: 'VX19-147-101' },
  { type: 130, directive: 'Givlaari', text: 'Givlaari' },
  { type: 131, directive: 'Flolan', text: 'Flolan' },
  { type: 132, directive: 'Copaxone', text: 'Copaxone' },
  { type: 133, directive: 'Olipudasealfa', text: 'Olipudase Alfa' },
  { type: 134, directive: 'Fingolimod', text: 'Fingolimod' },
  { type: 135, directive: 'Cipaglucosidasealfa', text: 'Cipaglucosidase Alfa' },
  { type: 136, directive: 'Nexviadyme', text: 'Lysosomal Storage Disorders - Nexviadyme' },
  { type: 137, directive: 'Cimziaprivate', text: 'Cimzia Private' },
  { type: 138, directive: 'Kanuma', text: 'Lysosomal Storage Disorders - Kanuma' },
  { type: 139, directive: 'Oralneurology', text: 'Oral Neurology' },
  { type: 140, directive: 'Skilarence', text: 'Skilarence' },
  { type: 141, directive: 'Pombilitiopfolda', text: 'Lysosomal Storage Disorders - Pombiliti + Opfolda' },
  { type: 142, directive: 'Kesimptaprivate', text: 'Kesimpta Private' },
  { type: 143, directive: 'Xenpozyme', text: 'Xenpozyme' },
  { type: 144, directive: 'Ilumetridpp', text: 'Ilumetri DPP' },
  { type: 145, directive: 'Naglazyme', text: 'Lysosomal Storage Disorders - Naglazyme' },
  { type: 146, directive: 'Cytarabine', text: 'Cytarabine' },
  { type: 147, directive: 'Lamazym', text: 'Lamazym' },
  { type: 148, directive: 'Ipilimumabnivolumab', text: 'Ipilimumab/Nivolumab' },
  { type: 149, directive: 'Avelumab', text: 'Avelumab' },
  { type: 150, directive: 'Elfabrio', text: 'Lysosomal Storage Disorders - Elfabrio'},
  { type: 151, directive: 'Atezolizumabsubcut', text: 'Atezolizumab Sub Cut'},
  { type: 152, directive: 'Trastuzumabemtansine', text: 'Trastuzumab Emtansine'},
  { type: 153, directive: 'Dostarlimab', text: 'Dostarlimab'},
  { type: 32767, directive: 'Placeholder', text: 'Placeholder Service' },
];

type CARE_SERVICE_TYPE = typeof CARE_SERVICE_TYPE;

export default CARE_SERVICE_TYPE;
