import angular from "angular";
import { camelCaseToDashCase } from "#/utils/string";
import { entries } from "#/utils/array";

import module from "./module";

const reservedAttributes = new Set([
  "addService",
  "serviceType",
  "servicePrefix",
  "viewState",
  "ngRepeat"
]);

function asProperty([key, value]) {
  return `data-${camelCaseToDashCase(key)}="${value}"`;
}

function isReserved([key]) {
  return !key.startsWith("$") && !reservedAttributes.has(key);
}

module.directive("addService", function($compile, ApiServicesService) {
  "ngInject";
  return {
    restrict: "A",
    link: function(scope, elem, attrs) {
      attrs.$observe("serviceType", function() {
        const directive = ApiServicesService.getServiceDirectiveNameByType(
            Number(attrs.serviceType)
          ),
          componentTag = `${attrs.servicePrefix}-${directive.toLowerCase()}-service`,
          properties = entries(attrs)
            .filter(isReserved)
            .map(asProperty)
            .concat(attrs.viewState ? 'data-view-state="pageState.view"' : "")
            .join(" "),
          template = `<${componentTag} ${properties}></${componentTag}>`,
          el = angular.element(template);

        const link = $compile(el);
        // compiled element should be added to DOM before linking
        // to have access to parent controllers, bindings and dom elements
        // https://groups.google.com/d/msg/angular/hCOyqGd-PJg/ZG4FQeeK8zcJ
        elem.append(el);
        link(scope);
      });
    }
  };
});
