const SERVICE_REQUIRED = {
  Delivery: {
    type: 1,
    text: 'Delivery'
  },
  NurseAdmin: {
    type: 2,
    text: 'Nurse Admin'
  },
  GP_HCP_Training: {
    type: 3,
    text: 'GP/HCP Training'
  },
  NotProvided: {
    type: 0,
    text: 'Not Provided'
  }
};

type SERVICE_REQUIRED = typeof SERVICE_REQUIRED;

export default SERVICE_REQUIRED;
