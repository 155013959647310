const TASK_STATUS = {
  ToDo: { text: 'To Do', value: 0 },
  Done: { text: 'Done', value: 1 },
  Cancelled: { text: 'Cancelled', value: 2 },
  Failed: { text: 'Failed', value: 3 }
};

type TASK_STATUS = typeof TASK_STATUS;

export default TASK_STATUS;
