/**
 * Behaves the same as Object.assign but differs in that
 * the type checking fails when attempt is made to assign object literal
 * with properties that are not compatible with target interface
 * @param target Object to copy properties to
 * @param sources Objects to copy properties from
 */
export function strictAssign<T extends object>(target: T, ...sources: Partial<T>[]): void {
  Object.assign(target, ...sources);
}

/**
 * Behaves the same as Object.definePoperty but differs in that
 * the type checking fails when attempt is made to define absent property
 * at target interface
 * @param target Object on which to add or modify the property. This can be a native JavaScript
 * object (that is, a user-defined object or a built in object) or a DOM object.
 * @param propertyKey The property name.
 * @param descriptor Descriptor for the property. It can be for a data property or an accessor
 *  property.
 */
export function strictDefinePoperty<T, K extends keyof T>(
  target: T,
  propertyKey: K,
  descriptor: TypedPropertyDescriptor<T[K]>
): void {
  Object.defineProperty(target, propertyKey, descriptor);
}

export type KeyOf<T extends object> = T[keyof T];
