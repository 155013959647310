import module from "./module";

module.directive("pageTitle" /* @ngInject */, function($transitions, $state, PageTitleService) {
  return {
    link: function(scope, element, attr) {
      function updateTitle(toState) {
        if (toState.data && toState.data.pageTitle) {
          PageTitleService.setTitle(toState.data.pageTitle);
        } else {
          PageTitleService.setTitle();
        }
        if (attr.pageTitle === "full") {
          element.html(PageTitleService.fullTitle());
        } else {
          element.html(PageTitleService.title());
        }
      }

      updateTitle($state.current);

      const unsubscribe = $transitions.onSuccess({}, trans => {
        updateTitle(trans.to());
      });

      scope.$on("$destroy", unsubscribe);
    }
  };
});
