import module from "./module";

module.factory("ContactInfoClearService", function() {
  return {
    clear: contactInfo => {
      for (let ct in contactInfo) {
        contactInfo[ct] = contactInfo[ct].filter(c => c.value !== "");
      }
      return contactInfo;
    }
  };
});
