import { strictAssign } from '#/utils/object';
import * as ng from 'angular';

import module from '../module';
import template from './multi-select.tmpl.html';

module.component('ppmMultiSelect', {
  bindings: {
    multiSelectId: '<',
    items: '<',
    selectedIds: '<',
    onChange: '<',
    onClear: '<'
  },
  controller,
  template
});

interface IController extends ng.IOnChanges {
  multiSelectId: string;
  items: any[];
  selectedIds: any[];
  selectedItems: any[];
  handleSelect(value: any): void;
  onChange(ids: number[]): void;
  onClear(): void;
}

function controller(this: IController) {
  'ngInject';
  const vm = this;

  strictAssign(vm, { $onChanges, handleSelect });

  function $onChanges() {
    vm.selectedItems = vm.selectedIds || [];
    vm.items = vm.items && vm.items.map(_ =>
      Object.assign({}, _, { selected: vm.selectedItems.some(id => id === _.id) })
    );
  }

  function handleSelect(item: any) {
    if (item.selected) vm.selectedItems.push(item.id);
    else vm.selectedItems = vm.selectedItems.filter(_ => _ !== item.id);

    vm.onChange(vm.selectedItems);
  }
}
