import angular from "angular";
import module from "../module";
import template from "./address.tmpl.html";

module.directive("address" /* @ngInject */, function(
  $q,
  PageStateService,
  ApiReferenceDataService,
  NotificationsService
) {
  return {
    restrict: "A",
    replace: true,
    scope: {
      model: "=address",
      showDescriptionInput: "<?showDescriptionInput",
      showSpecialInstructionsInput: "<?showSpecialInstructionsInput",
      defaultDescription: "<?defaultDescription",
      onAddressChange: "&onAddressChange",
      onPostcodeChange: "&onPostcodeChange",
      isAddressPristine: "=?isAddressPristine"
    },
    template,
    priority: 100,
    controller: /*@ngInject */ function($scope) {
      $scope.housenumber = "";
      $scope.housename = "";
      $scope.selectedAddress = null;
      $scope.postcode = "";
      $scope.postCodeValid = false;
    },
    link: function(scope, elem, attr) {
      var maxLookupCount = 3,
        lookups = 0,
        fieldset = parentFieldset(elem);

      scope.hideHouse = angular.isDefined(attr.hideHouse);
      scope.manualEnter = false;
      scope.allowManualEnter = false;

      scope.showDescriptionInput = scope.showDescriptionInput || false;

      scope.pageState = PageStateService.getCurrentPageState();
      if (attr.viewState === "true") {
        scope.pageState.view = true;
        scope.pageState.edit = false;
      }

      function clearAddressCommon() {
        scope.housenumber = "";
        scope.housename = "";
        scope.selectedAddress = null;
        scope.postcode = "";
        scope.postCodeValid = false;
      }

      function clearAddressModel() {
        scope.model = {
          line1: "",
          line2: "",
          line3: "",
          city: "",
          county: "",
          postCode: "",
          country: "",
          description: "",
          postCodeValid: false
        };
        return true;
      }

      if (!scope.model) {
        clearAddressModel();
      }

      scope.findAddresses = function() {
        var term = [scope.housenumber, scope.housename, scope.postcode].join(" ").trim();
        scope.allowManualEnter = false;
        scope.getAddressByTerm(term).then(
          function(data) {
            lookups = 0;
            lookupAddress(data);
          },
          function() {
            return $q.when(true);
          }
        );
      };

      scope.onClick_manualEnter = function() {
        clearAddressModel();
        scope.model.description = scope.defaultDescription;
        scope.model.postCode = scope.postcode;
        scope.model.line1 = (scope.housename + " " + scope.housenumber).trim();
        scope.manualEnter = true;
      };

      function lookupAddress(addresses) {
        lookups++;
        scope.addresses = [];
        if (lookups > maxLookupCount) {
          NotificationsService.warning(
            fieldset,
            "",
            "Address not found. Try typing another terms or enter it manually",
            angular.noop,
            false,
            5000
          );
          scope.allowManualEnter = true;
          return;
        }
        if (addresses.length === 0) {
          scope.allowManualEnter = true;
          NotificationsService.warning(
            fieldset,
            "",
            "Address not found. Try typing another terms or enter it manually",
            angular.noop,
            false,
            5000
          );
        } else if (addresses.length === 1) {
          if (addresses[0].next === "Find") {
            scope.getAddressByTerm(addresses[0].text).then(
              function(data) {
                lookupAddress(data);
              },
              function() {
                return $q.when(true);
              }
            );
          } else if (addresses[0].next === "Retrieve") {
            scope.getAddressById(addresses[0].id).then(
              function(address) {
                scope.addresses = [];
                scope.model.line1 = address[0].line1;
                scope.model.line2 = address[0].line2;
                scope.model.line3 = address[0].line3;
                scope.model.city = address[0].city;
                scope.model.county = address[0].adminAreaName;
                scope.model.postCode = address[0].postalCode;
                scope.model.country = address[0].countryName;
                scope.model.description = scope.defaultDescription;
                scope.handleAddressChanged();
                scope.validatePostcode(scope.model.postCode);
              },
              function() {
                return $q.when(true);
              }
            );
          }
        } else {
          scope.addresses = addresses;
        }
      }

      scope.getAddressByTerm = function(prefix) {
        return ApiReferenceDataService.getAddressByTerm(prefix).then(
          function(data) {
            return data;
          },
          function(reject) {
            NotificationsService.error(
              fieldset,
              "",
              reject.statusText + ". Try typing another terms or enter address manually",
              angular.noop,
              false,
              5000
            );
            scope.allowManualEnter = true;
            scope.onClick_manualEnter();
            return reject;
          }
        );
      };

      scope.validatePostcode = function(postcode) {
        var regex = /^([A-Z][A-HJ-Y]?\d[A-Z\d]? \d[A-Z]{2}|GIR 0A{2})$/;
        scope.model.postCodeValid = regex.test(postcode);
      };

      scope.getAddressById = function(id) {
        return ApiReferenceDataService.getAddressById(id).then(
          function(data) {
            return data;
          },
          function(reject) {
            NotificationsService.error(
              fieldset,
              "",
              reject.statusText + ". Try typing another terms or enter address manually",
              angular.noop,
              false,
              5000
            );
            scope.allowManualEnter = true;
            scope.onClick_manualEnter();
            return reject;
          }
        );
      };

      scope.clear = function() {
        clearAddressCommon();
        clearAddressModel();
        scope.manualEnter = false;
        scope.allowManualEnter = false;
        scope.isAddressPristine = true;
      };

      scope.handleAddressChanged = function() {
        if (scope.onAddressChange !== undefined) {
          scope.onAddressChange();
        }

        if (scope.isAddressPristine !== undefined) {
          scope.isAddressPristine = scope.addressForm.$pristine;
        }
      };

      scope.handlePostcodeChanged = function() {
        scope.handleAddressChanged();
        if (scope.onPostcodeChange !== undefined) {
          scope.onPostcodeChange();
        }
        if (scope.onPostcodeChange) {
          scope.validatePostcode(scope.model.postCode);
        }
      };

      clearAddressCommon();

      scope.$watch("model", function(value) {
        if (angular.isUndefined(value) || value === null) {
          scope.clear();
        }
        scope.validatePostcode(scope.model.postCode);
      });

      scope.$watch("selectedAddress", function(value) {
        if (value === null || angular.isUndefined(value) || value === "") {
          return;
        }
        lookups = 0;
        lookupAddress([value]);
      });

      scope.$on("$destroy", function() {
        fieldset = undefined;
      });
    }
  };
});

function parentFieldset(elem) {
  let node = elem[0];

  while (node.nodeName !== "FIELDSET") node = node.parentNode;

  return angular.element(node);
}
