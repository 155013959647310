import module from "../module";
import template from "./pdf-viewer-embedded.tmpl.html";

module.component("ppmPdfViewerEmbedded", {
  bindings: {
    documentId: "<",
    version: "<"
  },
  controller,
  template
});

function controller(ApiPdfService, $interpolate, $element) {
  "ngInject";
  const vm = this;

  vm.$onChanges = function() {
    if (vm.version == null) {
      return;
    }

    ApiPdfService.getEmbedDocumentUrl({
      documentId: vm.documentId,
      version: vm.version
    }).then(url => {
      vm.url = url;

      // IE 11 & Edge doesn't render pdf if next templete is put directly to component template
      const template = '<object data="{{url}}" type="application/pdf"></object>',
        objectPdf = $interpolate(template)(vm),
        area = $element[0].querySelector(".document-area");

      area.innerHTML = objectPdf;
    });
  };
}
