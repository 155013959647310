import module from "../module";
import template from "./contact-info-picker.tmpl.html";

import { CONTACT_TYPE as contactTypeEnum } from "#/constants/index";

module.directive("contactInfoPicker" /* @ngInject */, function(PageStateService) {
  return {
    restrict: "A",
    require: "ngModel",
    replace: true,
    scope: {
      model: "=ngModel"
    },
    template,
    priority: 100,
    link: function(scope) {
      scope.contactTypeEnum = contactTypeEnum;
      scope.model = scope.model || {};
      scope.pageState = PageStateService.getCurrentPageState();

      for (var i in scope.contactTypeEnum) {
        if (scope.contactTypeEnum.hasOwnProperty(i)) {
          if (!scope.model[scope.contactTypeEnum[i].group.toLowerCase()]) {
            scope.model[scope.contactTypeEnum[i].group.toLowerCase()] = [];
          }
        }
      }

      scope.addAnotherContactMethod = function() {
        if (scope.newContactMethod === "") return;

        var contactInfo = getContactInfoByValue(scope.newContactMethod);

        if (contactInfo.group.toLowerCase() == "phones") {
          scope.model[contactInfo.group.toLowerCase()].push({
            type: contactInfo.value % 100,
            value: "",
            voicemail: false
          });
        } else {
          scope.model[contactInfo.group.toLowerCase()].push({
            type: contactInfo.value % 100,
            value: ""
          });
        }
        scope.newContactMethod = null;
      };

      function getContactInfoByValue(value) {
        return scope.contactTypeEnum.find(_ => _.value === value);
      }
    }
  };
});
