import * as angular from 'angular';

import REPOSITORY_KEY from '#/constants/repository-key';
import IRepositoryService from '#/interface/core/service/repository-service';

import module from '../module';

module.factory('RepositoryService', RepositoryService);

function RepositoryService(
  $rootScope: ng.IScope,
  $window: ng.IWindowService,
  $sessionStorage: any,
  DateService: any
): IRepositoryService {
  'ngInject';

  return {
    init,
    get,
    save,
    remove,
    getAndRemove,
    historyGet,
    historySave,
    historyRemove,
    historyGetAndRemove
  };

  function init() {
    $rootScope.$on('user-logout', function() {
      const lastUrl = $sessionStorage.repository[REPOSITORY_KEY.LAST_UNAUTHORIZED_URL];

      $sessionStorage.repository = {};
      $sessionStorage.repository[REPOSITORY_KEY.LAST_UNAUTHORIZED_URL] = lastUrl;
    });
    $sessionStorage.repository = $sessionStorage.repository || {};
  }

  function historySave(data: any, key: string, uniqueKeyPrefix?: string) {
    if (!angular.isNumber(historyState())) {
      return;
    }

    if (uniqueKeyPrefix) {
      removeByKeyPrefix(uniqueKeyPrefix);
    }

    $sessionStorage.repository[historyKey(key)] = copyData(data);
  }

  function historyRemove(key: string) {
    delete $sessionStorage.repository[historyKey(key)];
  }

  function historyGet(key: string) {
    if (!$sessionStorage.hasOwnProperty('repository')) {
      $sessionStorage.repository = {};
    }

    const result = angular.copy($sessionStorage.repository[historyKey(key)]);
    return result;
  }

  function historyGetAndRemove(key: string) {
    const result = historyGet(key);
    historyRemove(key);
    return result;
  }

  // save/get
  function save(data: any, key: string) {
    if (!angular.isNumber(historyState())) {
      return;
    }

    $sessionStorage.repository[key] = copyData(data);
  }

  function remove(key: string) {
    delete $sessionStorage.repository[key];
  }

  function get(key: string) {
    if (!$sessionStorage.hasOwnProperty('repository')) {
      $sessionStorage.repository = {};
    }

    const result = angular.copy($sessionStorage.repository[key]);
    return result;
  }

  function getAndRemove(key: string) {
    const result = get(key);
    remove(key);
    return result;
  }

  function copyData(data: string) {
    const json = JSON.stringify(
      data,
      function(key, value) {
        return key[0] === '$' ? undefined : value;
      },
      2
    );

    const result = JSON.parse(json);
    DateService.replaceStringWithDate(result);

    return result;
  }

  function historyState() {
    const history = $window.history;
    if (!history) {
      // just do not crash in IE8
      return undefined;
    }

    if (!angular.isNumber(history.state)) {
      $sessionStorage.lastHistoryState = $sessionStorage.lastHistoryState || 0;

      history.replaceState($sessionStorage.lastHistoryState++, document.title);
    }

    return history.state;
  }

  function historyKey(key: string) {
    return key + 'history' + historyState();
  }

  function removeByKeyPrefix(keyPrefix: string) {
    for (const i in $sessionStorage.repository) {
      if ($sessionStorage.repository.hasOwnProperty(i) && i.indexOf(keyPrefix) === 0) {
        historyRemove(i);
      }
    }
  }
}
