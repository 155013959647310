import $regexp from "#/constants/regexp";

import module from "./module";

module.directive(
  "numeric",
  /* @ngInject */ function() {
    return {
      require: "ngModel",
      link: function(_, __, ___, ctrl) {
        ctrl.$parsers.unshift(function(viewValue) {
          if ($regexp.numeric.test(viewValue)) {
            // it is valid
            ctrl.$setValidity("numeric", true);
            return viewValue;
          } else {
            // it is invalid, return undefined (no model update)
            ctrl.$setValidity("numeric", false);
            return undefined;
          }
        });
      }
    };
  }
);
