import module from "../module";
import template from "./pdf-page.tmpl.html";

module.component("ppmPdfPage", {
  bindings: {
    page: "<",
    annotations: "<",
    sortByDate: "<",
    hideAnnotations: "<",
    onCreateAnnotation: "&",
    onUpdateAnnotation: "&",
    onRemoveAnnotation: "&",
    onFocusChange: "&",
    onSortByDate: "&",
    onSortByPosition: "&"
  },
  controller,
  template
});

function controller($element, PrincipalService) {
  "ngInject";

  const vm = this,
    canvas = $element[0].querySelector("canvas.page-image"),
    canvasContainer = $element[0].querySelector(".pdf-page__canvas-container");

  vm.$onInit = function() {
    vm.imageViewport = render(canvas, canvasContainer, vm.page);
    vm.initialViewport = vm.page.getViewport(1);
  };

  vm.handleCreateAnnotation = function(rect) {
    const annotation = Object.assign(
      {
        id: Math.random(),
        text: "",
        page: vm.page.pageIndex,
        isInternal: PrincipalService.isSystemRole(),
        isOwn: true,
        isSystemUser: PrincipalService.isSystemRole()
      },
      rect
    );

    vm.onCreateAnnotation({ annotation });
  };
}

function render(canvas, canvasContainer, page) {
  const dimensions = calcDimensions(page);

  canvas.width = dimensions.canvasWidth;
  canvas.height = dimensions.canvasHeight;

  canvasContainer.style.width = `${dimensions.width}px`;
  canvasContainer.style.height = `${dimensions.height}px`;

  page.render({
    canvasContext: canvas.getContext("2d"),
    viewport: dimensions.viewport
  });

  return {
    width: dimensions.canvasWidth,
    height: dimensions.canvasHeight
  };
}

function calcDimensions(page) {
  const defaultScale = 1.4,
    devicePixelRatio = window.devicePixelRatio || 1,
    defaultViewport = page.getViewport(defaultScale),
    width = Math.ceil(defaultViewport.width),
    height = Math.ceil(defaultViewport.height),
    scale = defaultScale * devicePixelRatio,
    viewport = page.getViewport(scale),
    canvasWidth = Math.ceil(viewport.width),
    canvasHeight = Math.ceil(viewport.height);

  return { width, height, canvasWidth, canvasHeight, viewport, scale };
}
