import module from "../module";
import template from "./workflow-window.tmpl.html";

module.component("workflowWindow", {
  bindings: {
    onCancelGeneration: "&",
    patientId: "<",
    patientName: "<",
    patient: "<",
    taskType: "<"
  },
  transclude: true,
  controllerAs: "vm",
  controller: function($scope) {
    "ngInject";
    this.collapsed = true;

    var _this = this;
    $scope.$on("maximiseWorkflow", showWorkflowForm);

    Object.assign(_this, {
      handleTrigger,
      showWorkflow: false
    });

    function handleTrigger() {
      _this.collapsed = !_this.collapsed;
    }

    function showWorkflowForm() {
      _this.showWorkflow = true;
      handleTrigger();
    }
  },
  template
});
