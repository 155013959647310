import angular from "angular";
import module from "../module";
import template from "./date-picker.tmpl.html";

module.directive("datepicker" /* @ngInject */, function(DateService) {
  return {
    restrict: "A",
    require: "ngModel",
    replace: true,
    scope: {
      model: "=ngModel",
      disabled: "<ngDisabled",
      minYear: "@minYear",
      maxYear: "@maxYear",
      dpname: "@name",
      minDate: "<minDate",
      errorReason: "@errorReason"
    },
    template,
    priority: 90,
    link: function($scope, _, __, $ctrl) {
      $scope.model = angular.isDefined($scope.model) ? $scope.model : "";

      var isModelDate = angular.isDate($scope.model),
        yearsBack,
        minYearDefault,
        minYearFromModel;

      //initial default minYears
      if (angular.isUndefined($scope.minYear)) {
        yearsBack = 3;
        minYearDefault = new Date().getUTCFullYear() - yearsBack;
        minYearFromModel = isModelDate ? $scope.model.getUTCFullYear() - yearsBack : minYearDefault;

        $scope.minYear = minYearFromModel < minYearDefault ? minYearFromModel : minYearDefault;
      }

      if (isModelDate) {
        initFromModel();
      }

      $scope.years = DateService.getYears($scope.minYear, $scope.maxYear);
      $scope.months = DateService.getMonths();
      $scope.days = DateService.getDays($scope.month, $scope.year);

      $scope.updateDate = function() {
        if ($scope.day && $scope.month && $scope.year) {
          $scope.model = DateService.getUTCValidDate(
            $scope.day,
            $scope.month,
            $scope.year,
            $scope.minYear,
            $scope.maxYear
          );
          $scope.month = $scope.model.getUTCMonth() + 1;
          $scope.day = $scope.model.getUTCDate();

          if ($scope.minDate !== undefined) {
            $ctrl.$setValidity("minDate", DateService.isLaterDate($scope.minDate, $scope.model));
          }
        }
      };

      $scope.$watch("model", function(newValue) {
        if (angular.isUndefined(newValue)) {
          return;
        }
        if (newValue) {
          if (angular.isString(newValue) && DateService.isDateString(newValue)) {
            $scope.model = new Date(newValue);
          }
          if (angular.isDate(newValue)) {
            initFromModel();
          }
        } else {
          $scope.month = null;
          $scope.day = null;
          $scope.year = null;
        }
        $scope.days = DateService.getDays($scope.month, $scope.year);
      });

      $scope.$on("$destroy", function() {
        $scope.model = null;
        $scope.days = undefined;
        $scope.months = undefined;
        $scope.years = undefined;
      });

      $scope.onClick_clear = function() {
        $scope.model = null;
      };

      function initFromModel() {
        var date = DateService.getUTCBackwardCompatibleDate($scope.model);

        $scope.year = date.getUTCFullYear();
        $scope.month = date.getUTCMonth() + 1;
        $scope.day = date.getUTCDate();
      }
    }
  };
});
