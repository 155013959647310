import angular from "angular";

import module from "../module";
import template from "./navbar.tmpl.html";

module.directive("navbar" /* @ngInject */, function(PrincipalService, PopoutService) {
  function isSystemUser() {
    return PrincipalService.isSystemRole();
  }

  var menu = [
    { text: "Dashboard", sref: "dashboard.main" },
    { text: "Upload", sref: "documents.upload" },
    { text: "Documents", sref: "documents.list" },
    { text: "Patients", sref: "patients.list" },
    { text: "Users", sref: "users.list" },
    {
      text: function() {
        return isSystemUser() ? "Customers" : "Customer Settings";
      },
      sref: function() {
        return isSystemUser() ? "customers.list" : "customers.item.view";
      },
      param: function() {
        return isSystemUser() ? "" : "({id:'" + PrincipalService.getIdentity().customerID + "'})";
      }
    },
    { text: "Tasks", sref: "tasks.list" },
    { text: "Clinics", sref: "clinics.management" },
    {
      text: function() {
        return PrincipalService.getIdentity().email;
      },
      sref: "profile"
    },
    {
      text: function() {
        return PrincipalService.isPretender() ? "Exit" : "Logout";
      },
      sref: function() {
        return PrincipalService.isPretender() ? "account.unpretend" : "account.logout";
      }
    }
  ];

  function calculateVisibleMenu() {
    var scopemenu = [];

    if (PrincipalService.isAuthenticated()) {
      menu.forEach(function(item) {
        var text, sref, param;

        text = angular.isString(item.text) ? item.text : item.text();
        sref = angular.isString(item.sref) ? item.sref : item.sref();
        param = item.param ? item.param() : "";

        if (PrincipalService.isInRole(sref)) {
          scopemenu.push({
            text: text,
            sref: sref + param
          });
        }
      });
    }
    return scopemenu;
  }

  return {
    restrict: "A",
    replace: false,
    scope: true,
    template,
    priority: 100,
    compile: function compile(tElement) {
      if (PopoutService.isPopoutWindow()) {
        tElement.remove();
        return false;
      }
    },
    /* @ngInject */
    controller: function($scope, $rootScope) {
      if (PopoutService.isPopoutWindow()) {
        return;
      }
      $scope.isAuthenticated = PrincipalService.isAuthenticated();
      $scope.menu = calculateVisibleMenu();

      $scope.$onDestroy = $rootScope.$on("auth-update-navbar", function() {
        $scope.isAuthenticated = PrincipalService.isAuthenticated();
        $scope.menu = calculateVisibleMenu();
      });
    }
  };
});
