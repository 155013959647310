import module from "./module";
import { buildUrl } from "#/utils/api";
import REGEXP from "#/constants/regexp";

module.factory("ApiResourcesService", ApiResourcesService);
/* @ngInject */
function ApiResourcesService($resource) {
  var api = {};

  api.StartupProgress = $resource(null, null, {
    status: { method: "GET", url: buildUrl("status") }
  });

  // Pharmaxo
  api.Notes = $resource(
    buildUrl("patients/:patientId/notes"),
    { patientId: "@patientId" },
    {
      query: { method: "GET", isArray: false },
      create: { method: "POST" },

      pin: {
        url: buildUrl("patients/:patientId/notes/:noteId/pin"),
        method: "POST",
        params: { patientId: "@patientId", noteId: "@noteId" }
      },

      unpin: {
        url: buildUrl("patients/:patientId/notes/:noteId/unpin"),
        method: "POST",
        params: { patientId: "@patientId", noteId: "@noteId" }
      }
    }
  );

  api.Customers = $resource(
    buildUrl("customers/:id/:action/:serviceKey:status"),
    { id: "@id" },
    {
      query: { method: "GET", isArray: false },
      auditTrail: { method: "GET", isArray: false, params: { action: "trail" } },
      create: { method: "POST" },
      change: { method: "PATCH" },

      addService: { method: "POST", params: { action: "services" } },
      getService: {
        method: "GET",
        params: { action: "services", serviceKey: "", status: "@status" }
      },
      updateService: { method: "PATCH", params: { action: "services", serviceKey: "@serviceKey" } },
      getPatientIdentifierTypes: {
        url: buildUrl("customers/:customerId/patient-identifier-types"),
        method: "GET",
        params: { id: "@customerId" },
        isArray: true
      },
      convertPlaceholderService: {
        url: buildUrl("convertPlaceholderService"),
        method: "POST"
      },
      placeholderConvertionStatus: {
        url: buildUrl("customers/:customerId/placeholders/:placeholderServiceId/convertion-status"),
        method: "GET",
        params: { customerId: "@customerId", placeholderServiceId: "@placeholderServiceId" }
      }
    }
  );

  api.Users = $resource(
    buildUrl("users/:id/:action"),
    { id: "@id" },
    {
      query: { method: "GET", isArray: false },
      auditTrail: { method: "GET", isArray: false, params: { action: "trail" } },
      create: { method: "POST" },
      change: { method: "PATCH", params: { action: "" } },
      disable: { method: "POST", params: { action: "disable" } },
      enable: { method: "POST", params: { action: "enable" } },
      pretenderLogin: { method: "POST", params: { action: "login" } }
    }
  );

  api.Signatures = $resource(
    buildUrl("signature-image/:id"),
    { id: "@id" },
    {
      get: { method: "GET" }
    }
  );

  api.Services = $resource(
    buildUrl("masterdata/services/:type/:action"),
    { type: "@type" },
    {
      data: { method: "GET", params: { action: "data" } },
      customersettings: { method: "GET", params: { action: "customersettings" } },
      patientsettings: { method: "GET", params: { action: "patientsettings" } },
      placeholderConvertionTypes: {
        url: buildUrl("masterdata/placeholder-convertion-types"),
        method: "GET",
        isArray: true
      }
    }
  );

  api.Documents = $resource(
    buildUrl("documents/:id/:action"),
    { id: "@id", action: "" },
    {
      query: { method: "GET", isArray: false },
      search: { method: "GET", params: { id: "" }, isArray: false },
      change: { method: "PATCH" },

      auditTrail: { method: "GET", isArray: false, params: { action: "trail" } },

      approve: { method: "POST", params: { action: "approve" } },
      reject: { method: "POST", params: { action: "reject" } },
      review: { method: "POST", params: { action: "requires-review" } },

      suspend: { method: "POST", params: { id: "@id", action: "suspend" } },

      cancel: { method: "POST", params: { id: "@id", action: "cancel" } },
      unCancel: { method: "POST", params: { id: "@id", action: "unCancel" } },

      link: { method: "POST", params: { action: "link" } },
      definelifecycle: { method: "POST", params: { action: "definelifecycle" } },

      notifyMessagesSeen: { method: "POST", params: { action: "notify-messages-seen" } },

      confirmRxWithoutClinicalScreen: {
        method: "POST",
        params: { action: "confirm-rx-without-clinical-screen" }
      },

      addNote: {
        url: buildUrl("documents/:id/notes"),
        method: "POST",
        params: { id: "@id" },
        isArray: false
      },

      editNote: {
        url: buildUrl("document-notes/:noteId"),
        method: "PUT",
        params: { noteId: "@noteId" },
        isArray: false
      },

      addComment: {
        url: buildUrl("document-notes/:noteId/comments"),
        method: "POST",
        params: { noteId: "@noteId" },
        isArray: false
      },

      editComment: {
        url: buildUrl("document-notes/:noteId/comments/:commentId"),
        method: "PUT",
        params: { noteId: "@noteId", commentId: "@commentId" },
        isArray: false
      },

      getDocumentToken: {
        url: buildUrl("document-token/:id/:version"),
        method: "GET",
        params: { action: undefined, id: "@id", version: "@version" },
        isArray: false
      },

      getDocumentSplitInfo: {
        url: buildUrl("/documents/:id/split-info"),
        method: "GET",
        params: { id: "@id" },
        isArray: false
      },

      getDocumentPage: {
        url: buildUrl("/documents/:id/pages/:page"),
        method: "GET",
        params: { id: "@id", page: "@page" },
        responseType: "arraybuffer",
        transformResponse: function(data, headersGetter) {
          return {
            buffer: data,
            type: headersGetter()["content-type"]
          };
        }
      },

      splitDocument: {
        url: buildUrl("documents/:id/split-requests"),
        method: "POST",
        params: {
          id: "@id"
        }
      },

      documentValidationConfig: {
        url: buildUrl("document-validation-items"),
        method: "GET",
        params: { action: undefined }
      },

      documentVisitTypesConfig: {
        url: buildUrl("document-visit-types"),
        method: "GET",
        params: { action: undefined }
      }
    }
  );

  api.Pdf = $resource(
    buildUrl("document/:documentId/:version"),
    { documentId: "@documentId", version: "@version" },
    {
      get: {
        method: "GET",
        responseType: "arraybuffer",
        transformResponse: function(data, headersGetter) {
          const headers = headersGetter(),
            contentType = headers["content-type"],
            contentDisposition = headers["content-disposition"],
            filenameRegex = /attachment; filename=(.*)/,
            filename = filenameRegex.exec(contentDisposition)[1];

          return {
            buffer: data,
            filename,
            type: contentType
          };
        }
      }
    }
  );

  api.PdfAnnotations = $resource(
    buildUrl("documents/:documentId/:version/annotations/:annotationId"),
    { documentId: "@documentId", version: "@version", annotationId: "@annotationId" },
    {
      query: { method: "GET", isArray: true },
      create: { method: "POST" },
      update: { method: "PUT" },
      remove: { method: "DELETE" }
    }
  );

  api.RxRenewals = $resource(buildUrl("rxrenewals"), null, {
    query: { method: "GET", isArray: false },
    search: { method: "GET", isArray: false },
    generateRxForms: {
      url: buildUrl("rxrenewals/bulk_reports"),
      method: "POST",
      transformResponse: function(_, headers) {
        //getting print form GUID from last segment of location
        var printFormId = api.getIdFromLocation(headers());
        if (!printFormId) {
          throw new Error("Location header should be filled with generated form id");
        }
        return { id: printFormId };
      }
    },
    getRxForms: {
      url: buildUrl("rxrenewals/bulk_reports/:id"),
      method: "GET",
      params: { id: "@id" }
    }
  });

  api.Assets = $resource(
    buildUrl("assets/:id/:action"),
    { id: "@id" },
    {
      query: { method: "GET", isArray: false },
      auditTrail: { method: "GET", isArray: false, params: { action: "trail" } },
      create: { method: "POST" },
      enable: { method: "POST", params: { action: "enable" } },
      disable: { method: "POST", params: { action: "disable" } },
      assign: { method: "POST", params: { action: "assign" } }
    }
  );

  api.Dashboard = $resource(buildUrl("dashboard/:action/:id/:action2"), null, {
    getCustomerPatientsSummary: {
      method: "GET",
      params: { action: "customers", id: "@id", action2: "patients" }
    },
    getAllCustomerPatientsSummary: { method: "GET", params: { action: "patients" } },
    getCustomerDocumentsSummary: {
      method: "GET",
      params: { action: "customers", id: "@id", action2: "documents" }
    },
    getAllCustomerDocumentsSummary: { method: "GET", params: { action: "documents" } }
  });

  // Enums
  api.Languages = $resource(buildUrl("languages", "reference"));

  api.Ethnicities = $resource(buildUrl("ethnicities", "reference"));
  // GPs
  api.Practices = $resource(buildUrl("practices/:prefix/:count", "reference"), {
    count: 10,
    prefix: "@prefix"
  });

  api.Practitioners = $resource(
    buildUrl("practices/:practice/practitioners/:prefix/:count", "reference"),
    { count: 10, practice: "@practice", prefix: "@prefix" }
  );

  // Addresses
  api.Addresses = $resource(buildUrl("addresses", "reference"));
  // SnomedConcept
  api.Allergies = $resource(buildUrl("allergies/:prefix/:count", "reference"), {
    count: 10,
    prefix: "@prefix"
  });

  api.Conditions = $resource(buildUrl("conditions/:prefix/:count", "reference"), {
    count: 10,
    prefix: "@prefix"
  });

  api.Diagnoses = $resource(buildUrl("diagnoses", "reference"));

  api.Drugs = $resource(buildUrl("drugs/:prefix/:count", "reference"), {
    count: 10,
    prefix: "@prefix"
  });

  api.getIdFromLocation = function(headers) {
    var location = headers.location || headers.Location,
      lastSlashIndex,
      lastValue;
    if (!location) return undefined;

    //eslint-disable-next-line
    while (true) {
      lastSlashIndex = location.lastIndexOf("/");
      if (lastSlashIndex === -1 || location === "") {
        throw Error("No guid ID at location");
      }
      lastValue = location.substr(lastSlashIndex + 1, location.length - lastSlashIndex);
      if (REGEXP.guid.test(lastValue)) {
        return lastValue;
      } else {
        location = location.substr(0, lastSlashIndex);
      }
    }
  };

  return api;
}
