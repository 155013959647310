import angular from "angular";

//todo: bad practise to extend outer objects

var oldFind;

angular.$ = angular.element;

// jqLite `.find([selector])`
oldFind = angular.$.prototype.find;
angular.$.prototype.find = function(sel) {
  if (!sel) return oldFind.call(this);
  var ret = [].slice.call(this[0].querySelectorAll(sel));

  return angular.$(ret);
};

// jqLite `.insertBefore([element])`
angular.$.prototype.insertBefore = function(element) {
  if (!element) return angular.$();

  angular.forEach(this, function(el) {
    element.parent()[0].insertBefore(el, element[0]);
  });

  return this;
};
