import module from './module';

import IApiService from '#/interface/core/service/api-service';
import IApiAccountService, {
  ILogInResult,
  IAccountInfo,
} from '#/interface/core/service/api-account-service';
import { noop } from '#/utils/function';

module.factory('ApiAccountService', ApiAccountService);

function ApiAccountService(ApiService: IApiService): IApiAccountService {
  'ngInject';

  return {
    changePassword,
    resetPassword,
    resetPasswordNoAuth,
    requestToken,
    logout,
    unpretend,
    accountInfo,
    reauthenticate
  };

  function changePassword(password: string, token: string) {
    return ApiService.request('password', {
      method: 'PATCH',
      data: { password },
      headers: {
        Authorization: token
      }
    }).then(noop);
  }

  function resetPassword(email: string) {
    return ApiService.post('password', { email });
  }

  function resetPasswordNoAuth(email: string) {
    return ApiService.post('reset-password', { email });
  }

  function requestToken(email: string, password: string) {
    return ApiService.post<ILogInResult>('login', { email, password });
  }

  function logout() {
    return ApiService.post('logout');
  }

  function unpretend() {
    return ApiService.post('pretender-logout');
  }

  function accountInfo(email: string) {
    return ApiService.get<IAccountInfo>(`account-info`, { email });
  }

  function reauthenticate(password: string) {
    return ApiService.post<ILogInResult>('reauthenticate', { password });
  }
}
