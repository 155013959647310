const SELF_SERVICE_OPT_OUT_REASONS = [
  'No reason given',
  "Can't view texts",
  'Confidentiality concerns/worried SMS is not secure',
  "Don't have internet/mobile data connection",
  "Don't own a mobile",
  'Lack of phone privacy/shared mobile',
  'Not confident',
  'Poor signal area',
  'Prefer not to',
  'Prefer to speak to someone',
  'Too complicated',
  'Visually impaired',
  'Vulnerable patient'
];

type SELF_SERVICE_OPT_OUT_REASONS = typeof SELF_SERVICE_OPT_OUT_REASONS;

export default SELF_SERVICE_OPT_OUT_REASONS;
