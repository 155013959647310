import angular from "angular";
import module from "../module";

module.directive("resize", function($window) {
  var w = angular.element($window);
  return {
    restrict: "A",
    priority: 100,
    link: function(scope, element, attr) {
      if (!scope.$eval(attr.resize)) {
        return;
      }

      function getWindowDimensions() {
        return {
          h: Math.max(w[0].innerHeight, document.documentElement.offsetHeight),
          w: document.documentElement.offsetWidth
        };
      }

      function onResize() {
        scope.$eval(attr.resize)(getWindowDimensions(), element);
      }

      function cleanUp() {
        w.off("resize", onResize);
      }

      w.bind("resize", function() {
        onResize();
      });

      scope.$on("$destroy", cleanUp);
      onResize();
    }
  };
});
