const PATIENT_NOTE_COMMUNICATION_SOURCE = [
  { value: 0, text: 'Pharmaxo (pharmacy)' },
  { value: 1, text: 'Pharmaxo (patient services)' },
  { value: 2, text: 'Patient' },
  { value: 3, text: 'Hospital' },
  { value: 4, text: 'Nurse' }
];

type PATIENT_NOTE_COMMUNICATION_SOURCE = typeof PATIENT_NOTE_COMMUNICATION_SOURCE;

export default PATIENT_NOTE_COMMUNICATION_SOURCE;
