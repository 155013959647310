const ADMIN_METHOD = {
  GPSharedCare: {
    type: 1,
    text: 'GP/Shared Care'
  },
  Hospital: {
    type: 2,
    text: 'Hospital'
  },
  HomecareNurse: {
    type: 3,
    text: 'Homecare/Nurse'
  },
  SelfInjected: {
    type: 4,
    text: 'Self Injected'
  },
  NotProvided: {
    type: 0,
    text: 'Not Provided'
  }
};

type ADMIN_METHOD = typeof ADMIN_METHOD;

export default ADMIN_METHOD;
