import { entries } from "#/utils/array";
import ANCILLARY_PROVIDED_FLAG from "#/constants/ancillary-provided-flag";
import module from "../module";

module.factory("AncillariesService", AncillariesService);
function AncillariesService() {
  "ngInject";
  const ancillariesService = {
    fillNewAncillaries,
    fillExistedAncillaries,
    removeNotProvided
  };

  return ancillariesService;

  function fillNewAncillaries(customerHash, generalHash) {
    entries(generalHash).forEach(([prop, generalAncillaries]) => {
      customerHash[prop] = generalAncillaries.map(_ => ({
        id: _.id,
        term: _.term,
        navCode: _.navCode,
        provided: ANCILLARY_PROVIDED_FLAG.NotProvided
      }));
    });

    return customerHash;
  }

  function fillExistedAncillaries(customerHash, generalHash) {
    customerHash = customerHash ? customerHash : {};
    entries(generalHash).forEach(([prop, generalAncillaries]) => {
      const customerAncillaries = Array.isArray(customerHash[prop]) ? customerHash[prop] : [];

      const mergedAncillaries = generalAncillaries.map(generalAncillary => ({
        id: generalAncillary.id,
        term: generalAncillary.term,
        navCode: generalAncillary.navCode,
        provided: setProvidedState(customerAncillaries, generalAncillary),
        availableForSelfService: setAvailableForSelfServiceState(
          customerAncillaries,
          generalAncillary
        )
      }));

      customerHash[prop] = mergedAncillaries;
    });

    return customerHash;
  }

  function setAvailableForSelfServiceState(customerAncilList, generalAncil) {
    if (customerAncilList.length === 0 || !customerAncilList.some(a => a.id === generalAncil.id)) {
      return false;
    }

    const customerAncil = customerAncilList.find(a => a.id === generalAncil.id);

    if (customerAncil === undefined) {
      return false;
    }

    return customerAncil.availableForSelfService;
  }

  function setProvidedState(customerAncilList, generalAncil) {
    if (customerAncilList.length === 0 || !customerAncilList.some(a => a.id === generalAncil.id)) {
      return ANCILLARY_PROVIDED_FLAG.NotProvided;
    }

    const customerAncil = customerAncilList.find(a => a.id === generalAncil.id);

    if (customerAncil === undefined) {
      return ANCILLARY_PROVIDED_FLAG.NotProvided;
    }

    if (customerAncil.billable === undefined) {
      return ANCILLARY_PROVIDED_FLAG.Billed;
    }

    return customerAncil.billable
      ? ANCILLARY_PROVIDED_FLAG.Billed
      : ANCILLARY_PROVIDED_FLAG.FreeOfCharge;
  }

  function removeNotProvided(ancillaryDictionary) {
    Object.keys(ancillaryDictionary).forEach(prop => {
      const ancillaries = ancillaryDictionary[prop].filter(
        _ => _.provided !== ANCILLARY_PROVIDED_FLAG.NotProvided
      );
      ancillaries.forEach(_ => {
        _.billable = _.provided === ANCILLARY_PROVIDED_FLAG.Billed;
        delete _.provided;
      });
      ancillaryDictionary[prop] = ancillaries;
    });

    return ancillaryDictionary;
  }
}
