import module from "./module";

module.factory("ApiDashboardService", ApiDashboardService);

/*@ngInject*/
function ApiDashboardService(ApiResourcesService) {
  var api = {},
    apiDashboard = ApiResourcesService.Dashboard;

  api.getCustomerPatientsSummary = function(customerID) {
    return apiDashboard.getCustomerPatientsSummary({ id: customerID }, null).$promise;
  };

  api.getAllCustomerPatientsSummary = function() {
    return apiDashboard.getAllCustomerPatientsSummary({}, null).$promise;
  };

  api.getCustomerInactivePatients = function(customerID) {
    return api.getCustomerPatientsSummary(customerID).then(function(data) {
      return data.inactivePatientsList;
    });
  };

  api.getCustomerActivePatients = function(customerID) {
    return api.getCustomerPatientsSummary(customerID).then(function(data) {
      return data.activePatientsList;
    });
  };

  api.getCustomerDocumentsSummary = function(customerID) {
    return apiDashboard.getCustomerDocumentsSummary({ id: customerID }, null).$promise;
  };

  api.getAllCustomerDocumentsSummary = function() {
    return apiDashboard.getAllCustomerDocumentsSummary({}, null).$promise;
  };

  return api;
}
