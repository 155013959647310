import module from "../module";
import template from "./pdf-page-annotations.tmpl.html";

module.component("ppmPdfPageAnnotations", {
  bindings: {
    annotations: "<",
    sortByDate: "<",
    onUpdateAnnotation: "&",
    onRemoveAnnotation: "&",
    onFocusChange: "&",
    onSortByDate: "&",
    onSortByPosition: "&"
  },
  controller,
  template
});

function controller($element, PrincipalService) {
  "ngInject";
  const vm = this;

  vm.canEditInternalState = PrincipalService.isSystemRole();

  $element[0].addEventListener("input", handleTextareaInput);

  vm.$onDestroy = function() {
    $element[0].removeEventListener("input", handleTextareaInput);
  };

  vm.$onChanges = function() {
    setTimeout(_ => Array.from($element[0].querySelectorAll("textarea")).forEach(autosizeTextarea));
  };

  function autosizeTextarea(element) {
    element.style.height = "initial";
    element.style.height = `${element.scrollHeight +
      element.offsetHeight -
      element.clientHeight}px`;
  }

  function handleTextareaInput(event) {
    const target = event.target;
    if (target.tagName !== "TEXTAREA") return;

    autosizeTextarea(target);
  }
}
