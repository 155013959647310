const PATIENT_NOTE_TYPE = [
  { value: 0, text: 'General note' },
  { value: 1, text: 'Prescription intervention' },
  { value: 2, text: 'Adverse drug event' },
  { value: 3, text: 'Complaint' },
  { value: 4, text: 'Failed delivery' },
  { value: 5, text: 'SMS sent' }
];

type PATIENT_NOTE_TYPE = typeof PATIENT_NOTE_TYPE;

export default PATIENT_NOTE_TYPE;
