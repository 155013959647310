import IModuleLazyLoadService from '#/interface/core/service/module-lazy-load-service';
import module from './module';

type ILoad = () => ng.IPromise<any>;

module.factory('ModuleLazyLoadService', function(
  $q: ng.IQService,
  $ocLazyLoad: oc.ILazyLoad
): IModuleLazyLoadService {
  'ngInject';

  return {
    loadAccount: loadOnce(loadAccount),
    loadDashboard: loadOnce(loadDashboard),
    loadUsers: loadOnce(loadUsers),
    loadPrintforms: loadOnce(loadPrintforms),
    loadRxRenewals: loadOnce(loadRxRenewals),
    loadPatients: loadOnce(loadPatients),
    loadCustomers: loadOnce(loadCustomers),
    loadDocuments: loadOnce(loadDocuments),
    loadTasks: loadOnce(loadTasks),
    loadClinics: loadOnce(loadClinics),
  };

  function loadAccount() {
    return $q
      .resolve(import(/* webpackChunkName: 'account' */ '../../ui.modules/ui.account/bootstrap'))
      .then(moduleLoadThunk('ppm.ui.account'));
  }

  function loadDashboard() {
    return $q
      .resolve(
        import(/* webpackChunkName: 'dashboard' */ '../../ui.modules/ui.dashboard/bootstrap')
      )
      .then(moduleLoadThunk('ppm.ui.dashboard'));
  }

  function loadUsers() {
    return $q
      .resolve(import(/* webpackChunkName: 'users' */ '../../ui.modules/ui.users/bootstrap'))
      .then(moduleLoadThunk('ppm.ui.users'));
  }

  function loadRxRenewals() {
    return $q
      .resolve(
        import(/* webpackChunkName: 'rx-renewals' */ '../../ui.modules/ui.rx-renewals/bootstrap')
      )
      .then(moduleLoadThunk('ppm.ui.rx-renewals'));
  }

  function loadPrintforms() {
    return $q
      .resolve(
        import(/* webpackChunkName: 'printforms' */ '../../ui.modules/ui.printforms/bootstrap')
      )
      .then(moduleLoadThunk('ppm.ui.printforms'));
  }

  function loadPatients() {
    return $q
      .resolve(import(/* webpackChunkName: 'patients' */ '../../ui.modules/ui.patients/bootstrap'))
      .then(moduleLoadThunk('ppm.ui.patients'));
  }

  function loadCustomers() {
    return $q
      .resolve(
        import(/* webpackChunkName: 'customers' */ '../../ui.modules/ui.customers/bootstrap')
      )
      .then(moduleLoadThunk('ppm.ui.customers'));
  }

  function loadDocuments() {
    return $q
      .resolve(
        import(/* webpackChunkName: 'documents' */ '../../ui.modules/ui.documents/bootstrap')
      )
      .then(moduleLoadThunk('ppm.ui.documents'));
  }

  function loadTasks() {
    return $q
      .resolve(import(/* webpackChunkName: 'tasks' */ '../../ui.modules/ui.tasks/bootstrap'))
      .then(moduleLoadThunk('ppm.ui.tasks'));
  }

  function loadClinics() {
    return $q
      .resolve(
        import(/* webpackChunkName: 'clinics' */ '../../ui.modules/ui.clinics/bootstrap')
      )
      .then(moduleLoadThunk('ppm.ui.clinics'));
  }

  function loadOnce(action: ILoad): ILoad {
    let result: ng.IPromise<any> | undefined;
    return function() {
      if (result !== undefined) return result;

      result = action();

      result.catch(error => {
        result = undefined;
        return $q.reject(error);
      });

      return result;
    };
  }

  function moduleLoadThunk(name: string): ILoad {
    return () => /*$ocLazyLoad.load({ name })*/ $ocLazyLoad.inject(name);
  }
});
