const OBJECT_VIEW = {
  name: "{{surname | uppercase}}, {{ givenNames | joinBy:' ' }} ({{title}})", // tslint:disable-line
  dateWithAge: "{{(date|partialdate) + ((date|age)?' (' + (date|age) + ') ':'')}}", // tslint:disable-line
  nominatedcontact: "{{name | view:'name'}}, {{relationshipToPatient}}", // tslint:disable-line
  nominatedcontactaudittrail: "{{name | view:'name'}}, {{relationshipToPatient}},<span ng-if='{{contactInfo.emails.length > 0}}'><br /> Emails: {{contactInfo.emails | confirmationEmailMap:'value':'isConfirmationEmail' | joinBy: ', '}}</span>", // tslint:disable-line
  address:
    "{{description ? description + ':' : ''}}" +
    "{{[line1,line2,line3,city,county,postCode,country] | joinBy:', '}}" +
    "{{ specialInstructions ? '<br /> With special instructions for courier: ' + specialInstructions : '' }}", // tslint:disable-line
  deliveryAddress: "{{[description,line1,line2,line3,city,county,postCode,country] | joinBy:', '}}", // tslint:disable-line
  clinicLocationDetails: "Clinic: {{clinicDescription}}, Chair: {{chairDescription}}" // tslint:disable-line
};

type OBJECT_VIEW = typeof OBJECT_VIEW;

export default OBJECT_VIEW;
