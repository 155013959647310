import module from "../module";
import template from "./pdf-page-annotation-layer.tmpl.html";
import { Observable } from "rxjs/Observable";
import { animationFrame } from "rxjs/scheduler/animationFrame";

module.component("ppmPdfPageAnnotationLayer", {
  bindings: {
    pdfViewport: "<",
    imageViewport: "<",
    annotations: "<",
    enumerate: "<",
    onCreateAnnotation: "&",
    onFocusChange: "&"
  },
  controller,
  template
});

function controller($element) {
  "ngInject";

  const vm = this,
    selectBox = $element[0].querySelector(".js-select-box"),
    svg = $element[0].querySelector("svg");

  function mouseEvents$(type, filter = _ => true) {
    return Observable.fromEvent(window, type).filter(filter);
  }

  const animationFrame$ = Observable.interval(0, animationFrame),
    mousedown$ = mouseEvents$("mousedown", e => e.target === svg && e.button === 0).sample(
      animationFrame$
    ),
    selection$ = mousedown$.map(e => {
      const containerPoint = e => getPoint(svg, e),
        initialPoint = containerPoint(e),
        moves$ = mouseEvents$("mousemove")
          .takeUntil(mouseEvents$("mouseup", e => e.button === 0))
          .sample(animationFrame$)
          .map(containerPoint),
        rects$ = Observable.of(initialPoint)
          .merge(moves$)
          .map(point => calcSelectRect(initialPoint, point));

      return rects$.publish();
    });

  const stop = selection$.subscribe(rects$ => {
    const MIN_RECT_SIDE_SIZE = 5;

    document.body.classList.add("pdf-annotating-mode");
    selectBox.style.display = "inline";

    rects$
      .last()
      .filter(rect => rect.width > MIN_RECT_SIDE_SIZE && rect.height > MIN_RECT_SIDE_SIZE)
      .subscribe(rect => vm.onCreateAnnotation({ rect }));

    let subscription;
    rects$.subscribe({
      next: rect => applyBox(selectBox, rect),
      complete: _ => {
        document.body.classList.remove("pdf-annotating-mode");
        selectBox.style.display = "";
        subscription.unsubscribe();
      }
    });

    subscription = rects$.connect();
  });

  vm.$onDestroy = stop;
}

function getPoint(svg, event) {
  const domPoint = svg.createSVGPoint();
  domPoint.x = event.clientX;
  domPoint.y = event.clientY;

  // https://www.sitepoint.com/how-to-translate-from-dom-to-svg-coordinates-and-back-again/
  const svgPoint = domPoint.matrixTransform(svg.getScreenCTM().inverse()),
    x = limit(0, svg.viewBox.baseVal.width, svgPoint.x),
    y = limit(0, svg.viewBox.baseVal.height, svgPoint.y);

  return { x, y };

  function limit(min, max, value) {
    return Math.min(Math.max(min, value), max);
  }
}

function calcSelectRect(point1, point2) {
  const minX = Math.min(point1.x, point2.x),
    maxX = Math.max(point1.x, point2.x),
    minY = Math.min(point1.y, point2.y),
    maxY = Math.max(point1.y, point2.y);

  return {
    y: minY,
    x: minX,
    width: maxX - minX,
    height: maxY - minY
  };
}

function applyBox(element, rect) {
  element.setAttribute("x", rect.x);
  element.setAttribute("y", rect.y);
  element.setAttribute("width", rect.width);
  element.setAttribute("height", rect.height);
}
