import module from "../module";
import template from "./pdf-viewer.tmpl.html";

import { Observable } from "rxjs/Observable";
import { Subject } from "rxjs/Subject";

import "./pdf-page";
import "./pdf-page-annotation-layer";
import "./pdf-page-annotations";

module.component("ppmPdfViewer", {
  bindings: {
    documentId: "<",
    version: "<",
    hideAnnotations: "<"
  },
  controller,
  template
});

function controller($q, PdfService, ApiPdfService) {
  "ngInject";

  const vm = this,
    annotationChange$ = new Subject(),
    annotationRemove$ = new Subject();

  let unloadPdf = _ => _,
    stopChangeUpdates = _ => _;

  vm.pages = [];
  vm.sortByDate = true;

  setupChangeUpdates();

  vm.handleCreateAnnotation = function(annotation) {
    const page = vm.pages[annotation.page];
    page.annotations = page.annotations.concat(annotation);

    if (vm.sortByDate) {
      sortAnnotationsByDate(page.annotations);
    } else {
      sortAnnotationsByPosition(page.annotations);
    }

    ApiPdfService.createAnnotation(pdfId(), annotation).then(data => {
      annotation.id = data.annotationId;
      annotation.timestamp = data.timestamp;
    });
  };

  vm.handleUpdateAnnotation = function(annotation) {
    annotationChange$.next(annotation);
  };

  vm.handleRemoveAnnotation = function(annotation) {
    const page = vm.pages[annotation.page];
    page.annotations = page.annotations.filter(a => a.id !== annotation.id);

    ApiPdfService.removeAnnotation(pdfId(), annotation.id);

    annotationRemove$.next(annotation);
  };

  vm.handleFocusChange = function(annotation, focused) {
    annotation.active = focused;
  };

  vm.handleSortByDate = function() {
    vm.sortByDate = true;
    vm.pages.map(page => sortAnnotationsByDate(page.annotations));
  };

  vm.handleSortByPosition = function() {
    vm.sortByDate = false;
    vm.pages.map(page => sortAnnotationsByPosition(page.annotations));
  };

  vm.$onChanges = function() {
    if (vm.version == null) {
      return;
    }

    unloadPdf();

    vm.pages = [];

    const pdfPromise = ApiPdfService.getPdfArraybuffer(pdfId()).then(data =>
        PdfService.getDocument(data)
      ),
      annotationsPromise = ApiPdfService.getAnnotations(pdfId());

    $q.all([pdfPromise, annotationsPromise]).then(([pdf, annotations]) => {
      handlePdfLoaded(pdf, annotations);
    });
  };

  vm.$onDestroy = function() {
    stopChangeUpdates();
    unloadPdf();
  };

  function setupChangeUpdates() {
    const debounceInterval$ = Observable.interval(750);
    let lastUpdate$ = Observable.empty();

    const subscription = annotationChange$
      .groupBy(annotation => annotation.id)
      .map(annotation$ =>
        annotation$
          .debounce(_ => lastUpdate$.concat(debounceInterval$))
          .takeUntil(
            annotationRemove$.filter(removedAnnotation => removedAnnotation.id === annotation$.key)
          )
      )
      .subscribe(annotation$ => {
        const childSubscription = annotation$.subscribe(annotation => {
          const updatePromise = ApiPdfService.updateAnnotation(pdfId(), annotation);

          lastUpdate$ = Observable.fromPromise(updatePromise).filter(_ => false);
        });

        subscription.add(childSubscription);
      });

    stopChangeUpdates = _ => subscription.unsubscribe();
  }

  function handlePdfLoaded(pdf, allAnnotations) {
    unloadPdf = _ => pdf.destroy();

    const pageCount = pdf.numPages,
      pagePromises = "."
        .repeat(pageCount)
        .split("")
        .map((_, index) => index + 1)
        .map(i => pdf.getPage(i));

    $q.all(pagePromises).then(
      pages =>
        (vm.pages = pages.map(page => {
          const annotations = allAnnotations.filter(a => a.page === page.pageIndex);
          if (vm.sortByDate) {
            sortAnnotationsByDate(annotations);
          } else {
            sortAnnotationsByPosition(annotations);
          }
          return { page, annotations };
        }))
    );
  }

  function sortAnnotationsByPosition(annotations) {
    annotations.sort((a1, a2) => a1.y - a2.y);
  }

  function sortAnnotationsByDate(annotations) {
    annotations.sort((a1, a2) => a1.timestamp - a2.timestamp);
  }

  function pdfId() {
    return {
      documentId: vm.documentId,
      version: vm.version
    };
  }
}
