import module from "./module";

module.factory("SortService", function() {
  "ngInject";
  const compare = comparer();

  return {
    naturalSorter
  };

  function naturalSorter(valueGetter = x => x) {
    return array =>
      array.slice().sort((left, right) => compare(valueGetter(left), valueGetter(right)));
  }

  function comparer() {
    // Only recent browser support Intl api (i.e. IE11, Safari 10.2/Sep 2016) so fallback is used
    // for now
    if (Intl == null) {
      return (left, right) => {
        left = (left + "").toLowerCase();
        right = (right + "").toLowerCase();

        return left.localeCompare(right);
      };
    }

    const collator = Intl.Collator(undefined, { numeric: true });
    return collator.compare;
  }
});
