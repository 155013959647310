import IDocument, {
  IDocumentValidationItem,
  IDocumentValidationItems,
  DocumentDataType
} from '#/interface/core/entity/document';
import IEnrollment from '#/interface/core/entity/enrollment';
import { DOCUMENT_STATE, DOCUMENT_STATUS, RECEIVED_METHOD } from '#/constants/index';
import { isPrescription } from '#/constants/document-kind';
import { NO_VALUE } from '#/constants/list/yes-no';
import { DOCUMENT_KIND } from '#/constants/index';
import { toArray } from '#/utils/enum';

interface DocumentKindCapabilities {
  requiresEnrollment: boolean;
  expireRenewalDate: boolean;
  validFromDate: boolean;
  requiresOriginalDocument: boolean;
  approvePermissionRequired: boolean;
  poOnArrivalRequired: boolean;
  hasAnaphylaxisFlag: boolean;
  hasUrgentFlag: boolean;
  isConsentFormAvailable: boolean;
  similarEnrollmentActiveScriptAvailable: boolean;
  documentDataType: DocumentDataType | null;
}

function getDocumentStatus(document: IDocument): number {
  if (isDocumentProcessed(document)) return DOCUMENT_STATUS.Closed.value;

  switch (document.state) {
    case DOCUMENT_STATE.New.value:
      return initial(document);

    case DOCUMENT_STATE.Approved.value:
      return DOCUMENT_STATUS.Approved.value;

    case DOCUMENT_STATE.Rejected.value:
      return DOCUMENT_STATUS.Rejected.value;

    case DOCUMENT_STATE.RequiresReview.value:
      return DOCUMENT_STATUS.Reviewed.value;

    default:
      throw new Error(`Bad state value: ${document.state}`);
  }

  // tslint:disable-next-line
  function initial(document: IDocument) {
    if (isDocumentGenerated(document)) return DOCUMENT_STATUS.Generated.value;

    if (document.linkInfo.linkingMethod === 0) return DOCUMENT_STATUS.Unidentified.value;

    return DOCUMENT_STATUS.Unapproved.value;
  }
}

function getDocumentKindCapabilities(documentKind: number): DocumentKindCapabilities {
  const patientVisitFormDocumentCapabilities = {
    requiresEnrollment: false,
    expireRenewalDate: false,
    validFromDate: false,
    requiresOriginalDocument: false,
    approvePermissionRequired: false,
    poOnArrivalRequired: false,
    hasAnaphylaxisFlag: false,
    hasUrgentFlag: false,
    isConsentFormAvailable: false,
    similarEnrollmentActiveScriptAvailable: false,
    documentDataType: DocumentDataType.PatientVisitForm,
  };

  switch (documentKind) {
    case DOCUMENT_KIND.Prescription.value:
      return {
        requiresEnrollment: true,
        expireRenewalDate: true,
        validFromDate: true,
        requiresOriginalDocument: true,
        approvePermissionRequired: true,
        poOnArrivalRequired: true,
        hasAnaphylaxisFlag: true,
        hasUrgentFlag: true,
        isConsentFormAvailable: false,
        similarEnrollmentActiveScriptAvailable: true,
        documentDataType: null,
      };
    case DOCUMENT_KIND.ConsentForm.value:
      return {
        requiresEnrollment: true,
        expireRenewalDate: false,
        validFromDate: true,
        requiresOriginalDocument: false,
        approvePermissionRequired: false,
        poOnArrivalRequired: false,
        hasAnaphylaxisFlag: false,
        hasUrgentFlag: false,
        isConsentFormAvailable: true,
        similarEnrollmentActiveScriptAvailable: false,
        documentDataType: null,
      };
    case DOCUMENT_KIND.NursingConsentForm.value:
      return {
        requiresEnrollment: false,
        expireRenewalDate: false,
        validFromDate: true,
        requiresOriginalDocument: false,
        approvePermissionRequired: false,
        poOnArrivalRequired: false,
        hasAnaphylaxisFlag: false,
        hasUrgentFlag: false,
        isConsentFormAvailable: true,
        similarEnrollmentActiveScriptAvailable: false,
        documentDataType: null,
      };
    case DOCUMENT_KIND.RegistrationForm.value:
      const capabilities: DocumentKindCapabilities = {
        requiresEnrollment: true,
        expireRenewalDate: false,
        validFromDate: false,
        requiresOriginalDocument: false,
        approvePermissionRequired: false,
        poOnArrivalRequired: false,
        hasAnaphylaxisFlag: false,
        hasUrgentFlag: false,
        isConsentFormAvailable: false,
        similarEnrollmentActiveScriptAvailable: false,
        documentDataType: null,
      };

      if (documentKind === DOCUMENT_KIND.RegistrationForm.value) {
        capabilities.documentDataType = DocumentDataType.RegistrationForm;
      }

      return capabilities;
    case DOCUMENT_KIND.ChangeOfDependencyForm.value:
    case DOCUMENT_KIND.MissedInfusionForm.value:
    case DOCUMENT_KIND.PatientInformationForm.value:
      return {
        requiresEnrollment: true,
        expireRenewalDate: false,
        validFromDate: true,
        requiresOriginalDocument: false,
        approvePermissionRequired: false,
        poOnArrivalRequired: false,
        hasAnaphylaxisFlag: false,
        hasUrgentFlag: false,
        isConsentFormAvailable: false,
        similarEnrollmentActiveScriptAvailable: false,
        documentDataType: null,
      };
    case DOCUMENT_KIND.PatientVisitForm.value:
    case DOCUMENT_KIND.CombinedPVFAndCompetencyAssessment.value:
      return patientVisitFormDocumentCapabilities;
    case DOCUMENT_KIND.Assessment.value:
    case DOCUMENT_KIND.HomeRiskAssessment.value:
    case DOCUMENT_KIND.CompetencyAssessment.value:
    case DOCUMENT_KIND.ProofOfDelivery.value:
    case DOCUMENT_KIND.AncillariesForm.value:
      return {
        requiresEnrollment: false,
        expireRenewalDate: true,
        validFromDate: true,
        requiresOriginalDocument: false,
        approvePermissionRequired: false,
        poOnArrivalRequired: false,
        hasAnaphylaxisFlag: false,
        hasUrgentFlag: false,
        isConsentFormAvailable: false,
        similarEnrollmentActiveScriptAvailable: false,
        documentDataType: null,
      };
    default:
      return {
        requiresEnrollment: false,
        expireRenewalDate: false,
        validFromDate: false,
        requiresOriginalDocument: false,
        approvePermissionRequired: false,
        poOnArrivalRequired: false,
        hasAnaphylaxisFlag: false,
        hasUrgentFlag: false,
        isConsentFormAvailable: false,
        similarEnrollmentActiveScriptAvailable: false,
        documentDataType: null,
      };
  }
}

function getTitleAndSubtitle(documentKind: number) {
  const kind = toArray(DOCUMENT_KIND).find(_ => _.value === documentKind);

  return kind && kind.labels;
}

function getValidationItemsArray(
  documentKind: number,
  validationConfig: IDocumentValidationItems[]
) {
  const kindKey = Object.keys(DOCUMENT_KIND).find(
    key => (DOCUMENT_KIND as any)[key].value === documentKind
  );

  if (!kindKey) {
    throw new Error('Unknown document kind = ' + documentKind);
  }

  const { checkboxes = [], dropdowns = [] } =
    validationConfig.find(_ => _.documentType === kindKey) || {};

  const checkbox = checkboxes.map(function(validationItem): IDocumentValidationItem {
    return {
      key: validationItem,
      value: false,
      type: 0
    };
  });

  const dropdown = dropdowns.map(function(validationItem): IDocumentValidationItem {
    return {
      key: validationItem,
      value: null,
      type: 1
    };
  });

  return checkbox.concat(dropdown);
}

function isDocumentGenerated({ files }: Pick<IDocument, 'files'>) {
  return files.length === 1 && files[0].receivedMethod === RECEIVED_METHOD.SystemGenerated.value;
}

function isDocumentProcessed(document: IDocument) {
  if (document.state !== DOCUMENT_STATE.Approved.value) return false;

  return (
    !getDocumentKindCapabilities(document.documentKind).requiresOriginalDocument ||
    document.originalDocumentReceived
  );
}

function isDocumentOriginal(document: IDocument) {
  return (
    getDocumentKindCapabilities(document.documentKind).requiresOriginalDocument &&
    document.originalDocumentReceived
  );
}

function isDocumentUnapproved(document: IDocument) {
  return (
    document.state === DOCUMENT_STATE.New.value &&
    !isDocumentGenerated(document) &&
    document.linkInfo.linkingMethod !== 0
  );
}

function validationItemsChecked(document: IDocument, clinicalScreenRequired?: boolean) {
  const items = document.validationItems || [],
    itemsChecked = items.filter(_ => _.type !== 1).every(_ => !!_.value),
    itemsSelected = items.filter(_ => _.type === 1).every(_ => _.value !== null);

  if (isPrescription(document.documentKind) && clinicalScreenRequired)
    return document.clinicalScreenConfirmed !== null && itemsChecked;

  return itemsChecked && itemsSelected;
}

function isClinicalScreenRequired(document: IDocument, patientEnrollments: IEnrollment[]) {
  const selectedEnrollment = findSelectedEnrollment(document, patientEnrollments);

  return (
    selectedEnrollment &&
    selectedEnrollment.clinicalScreenRequired &&
    isPrescription(document.documentKind)
  );
}

function isSentToReferringHospital(document: IDocument) {
  const item = document.validationItems.find(_ => _.key === 'sentToReferringHospital');

  return item && item.value != null && !item.value;
}

function isPurchaseOrderRefRequiredForEnrollment(
  document: IDocument,
  patientEnrollments: IEnrollment[]
) {
  const selectedEnrollment = findSelectedEnrollment(document, patientEnrollments);

  return selectedEnrollment && selectedEnrollment.purchaseOrderRequired;
}

function isPurchaseOrderRefRequired(document: IDocument, patientEnrollments: IEnrollment[]) {
  return (
    isPurchaseOrderRefRequiredForEnrollment(document, patientEnrollments) &&
    document.purchaseOrderRefOnArrival !== NO_VALUE
  );
}

function findSelectedEnrollment(document: IDocument, patientEnrollments: IEnrollment[]) {
  return (
    patientEnrollments && patientEnrollments.find(_ => _.id === document.linkInfo.enrollmentId)
  );
}

function isPromptRequired(document: IDocument, patientEnrollments: IEnrollment[]) {
  return (
    document.linkInfo.enrollmentId &&
    !document.activePurchaseOrderReference &&
    document.purchaseOrderRefOnArrival == null &&
    isPrescription(document.documentKind) &&
    isPurchaseOrderRefRequiredForEnrollment(document, patientEnrollments)
  );
}

function cycleField(document: IDocument) {
  if (!isPrescription(document.documentKind) || !document.cycle) return;

  return ` (cycle ${document.cycle})`;
}

export {
  getDocumentStatus,
  isDocumentGenerated,
  isDocumentProcessed,
  isDocumentOriginal,
  isDocumentUnapproved,
  validationItemsChecked,
  isClinicalScreenRequired,
  isPurchaseOrderRefRequired,
  isPurchaseOrderRefRequiredForEnrollment,
  isPromptRequired,
  cycleField,
  isSentToReferringHospital,
  getDocumentKindCapabilities,
  getTitleAndSubtitle,
  getValidationItemsArray
};
