import angular from "angular";
import module from "./module";

module.directive("ppmViewportObserver", function($window) {
  "ngInject";
  return {
    restrict: "A",
    scope: {
      ppmViewportObserver: "&"
    },
    link: function(scope, elem) {
      var element = elem[0],
        lastResult;

      function checkViewport() {
        if (!inDom(element)) {
          // IE11 throws unspecified error when getBoundingClientRect is called on element that is not attached to the dom
          return;
        }

        var rect = element.getBoundingClientRect(),
          result = {
            aboveViewport: rect.top < 0
          };

        if (!angular.equals(lastResult, result)) {
          lastResult = result;
          scope.ppmViewportObserver(result);
          scope.$apply();
        }
      }

      function inDom(node) {
        return $window.document.body.contains(node);
      }

      $window.addEventListener("scroll", checkViewport);
      scope.$on("$destroy", function() {
        $window.removeEventListener("scroll", checkViewport);
      });
    }
  };
});
