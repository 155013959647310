function createWrapper($q) {
  class PdfjsWrapper {
    getDocument(url) {
      return pdfjs().then(lib => lib.getDocument(url));
    }
  }

  function pdfjs() {
    return $q.resolve(import("pdfjs-dist")).then(lib => {
      lib.PDFJS.workerSrc = "/scripts/pdf.worker.js";
      return lib;
    });
  }

  return new PdfjsWrapper();
}

export default createWrapper;
