import angular from "angular";
import module from "./module";

import { searchQuery, diffObject, trimDollarFields, buildUrl } from "#/utils/api";

module.factory("ApiUsersService", ApiUsersService);

/* @ngInject */
function ApiUsersService(
  $q,
  $filter,
  ApiResourcesService,
  ContactInfoClearService,
  FileUploader,
  PrincipalService
) {
  var user = ApiResourcesService.Users,
    signature = ApiResourcesService.Signatures,
    api = {};

  api.get = function(id) {
    return user.get({ id }, null).$promise.then(trimDollarFields);
  };

  api.create = function(userDetail) {
    ContactInfoClearService.clear(userDetail.contactInfo);
    return user.create(null, userDetail).$promise;
  };

  api.saveChanges = function(id, newUserInfo, oldUserInfo) {
    var userInfo = angular.copy(newUserInfo);
    ContactInfoClearService.clear(userInfo.contactInfo);
    userInfo = diffObject(oldUserInfo, userInfo);
    if (!userInfo) {
      return $q.when(true);
    }
    return user.change({ id: id }, userInfo).$promise;
  };

  api.disable = function(id) {
    return user.disable({ id: id }, null).$promise;
  };

  api.enable = function(id) {
    return user.enable({ id: id }, null).$promise;
  };

  api.remove = function(id) {
    return user.remove({ id: id }, null).$promise;
  };

  api.restore = function(id) {
    return user.restore({ id: id }, null).$promise;
  };

  api.search = function(term, start, count, sortArray, customerId, requestId = undefined) {
    var obj = searchQuery(term, start, count, sortArray);
    if (customerId) obj.customerId = customerId;

    return ApiResourcesService.Users.query(obj, null).$promise.then(data => {
      data.requestId = requestId;
      return data;
    });
  };

  api.getTokenAs = function(userId) {
    return user.pretenderLogin({ id: userId }, null).$promise;
  };

  api.getSignatureImageUploader = function() {
    var availableExtensions = ["BMP", "JPG", "JPEG", "PNG"],
      maxSize = 1024 * 1024 * 50, // 50 Mb
      settings = {
        url: buildUrl("signature-image"),
        filters: [
          {
            name: "extension",
            fn: function(item) {
              var extension = /[^.]+$/.exec(item.name);
              if (!extension) {
                return false;
              }
              return availableExtensions.indexOf(extension[0].toUpperCase()) !== -1;
            },
            error: "Wrong file type, please add image"
          },
          {
            name: "filesize",
            fn: function(item) {
              return item.size <= maxSize;
            },
            error:
              "File is too big, supported size <= " +
              $filter("number")(maxSize / 1024 / 1024, 2) +
              "Mb"
          }
        ],
        autoUpload: true
      };

    settings.headers = {
      Authorization: "Token" + PrincipalService.getIdentity().token
    };

    return new FileUploader(settings);
  };

  api.getSignatureImage = function(id) {
    return signature.get({ id }).$promise;
  };

  return api;
}
