import angular from "angular";
import module from "./module";

module.factory("ApiAuditTrailService", ApiAuditTrailService);

/* @ngInject */
function ApiAuditTrailService(ApiResourcesService, ApiService) {
  var service = {};

  service.getUserAuditTrail = function getUserAuditTrail(userId) {
    return ApiResourcesService.Users.auditTrail({ id: userId }, null).$promise;
  };

  service.getCustomerAuditTrail = function getCustomerAuditTrail(customerId) {
    return ApiResourcesService.Customers.auditTrail({ id: customerId }, null).$promise;
  };

  service.getPatientAuditTrail = function getPatientAuditTrail(patientId) {
    return ApiService.get(`patients/${patientId}/trail`, null);
  };

  service.getDocumentAuditTrail = function getDocumentAuditTrail(documentId) {
    return ApiResourcesService.Documents.auditTrail({ id: documentId }, null).$promise;
  };

  service.getTaskAuditTrail = function(taskId) {
    return ApiService.get(`tasks/${taskId}/trail`, null);
  };

  service.messageKey = function(message) {
    const event = message.event;
    if (!event) return message.text;

    const index = event.indexOf("::");
    return index === -1 ? event : event.substr(0, index);
  };

  service.getFilterKeys = function(events) {
    const allMessages = [].concat(...events.map(_ => _.messages)),
      allKeys = allMessages.map(service.messageKey);

    return allKeys.reduce((acc, key) => (acc.indexOf(key) === -1 ? acc.concat(key) : acc), []);
  };

  service.filterEvents = function(filterKey, events) {
    if (!filterKey) return events;

    return events.filter(event =>
      event.messages.map(service.messageKey).some(_ => _ === filterKey)
    );
  };

  service.filterPatientNotes = function filterPatientNotes(
    events,
    eventTypeValue,
    communicationSourceValues,
    noteTypeValues
  ) {
    var hasNoteFilters = communicationSourceValues.length || noteTypeValues.length;

    if (!hasNoteFilters) {
      return service.filterEvents(eventTypeValue, events);
    }

    return events.filter(service.isNoteCreatedEvent).filter(function(event) {
      return event.messages.some(function(message) {
        var isCommunicationSource, isNoteType;

        if (angular.isDefined(message.data)) {
          isCommunicationSource = communicationSourceValues.some(function(value) {
            return message.data.communicationSource === value;
          });
          isNoteType = noteTypeValues.some(function(value) {
            return message.data.type === value;
          });
        }

        if (!communicationSourceValues.length) return isNoteType;

        if (!noteTypeValues.length) return isCommunicationSource;

        return isNoteType && isCommunicationSource;
      });
    });
  };

  service.isNoteEvent = function isNoteEvent(event) {
    const PATIENT_NOTE_CREATED_EVENT = "Note::Created",
      eventName = Array.isArray(event.messages) ? event.messages[0].event : null;
    const PATIENT_NOTE_PINNED_EVENT = "Note::Pinned";
    const PATIENT_NOTE_UNPINNED_EVENT = "Note::Unpinned";

    return (
      eventName === PATIENT_NOTE_CREATED_EVENT ||
      eventName === PATIENT_NOTE_PINNED_EVENT ||
      eventName === PATIENT_NOTE_UNPINNED_EVENT
    );
  };

  return service;
}
