const DOCUMENT_STATE = {
  New: { value: 0 },
  RequiresReview: { value: 1 },
  Rejected: { value: 2 },
  Approved: { value: 3 }
};

type DOCUMENT_STATE = typeof DOCUMENT_STATE;

export default DOCUMENT_STATE;
