import { ICourierDeliveryService } from '#/interface/core/entity/courier-delivery-service';
import { IApiCourierDeliveryService } from '#/interface/core/service/api-courier-delivery-service';
import IApiService from '#/interface/core/service/api-service';
import module from './module';

module.factory('ApiCourierDeliveryService', ApiCourierDeliveryService);

function ApiCourierDeliveryService(ApiService: IApiService): IApiCourierDeliveryService {
  'ngInject';

  return {
    getAvailableCourierDeliveryServices,
  };

  function getAvailableCourierDeliveryServices(postcode: string, storageRequirements: number): ng.IPromise<ICourierDeliveryService[]> {
    return ApiService.get(`delivery-services?postCode=${postcode}&storageRequirement=${storageRequirements}`);
  }
}
