import * as angular from 'angular';
import { strictAssign } from '#/utils/object';

import module from '../module';
import template from './dropdown.tmpl.html';

module.component('ppmDropdown', {
  transclude: {
    content: 'ppmDropdownContent',
    menu: 'ppmDropdownMenu'
  },
  bindings: {
    isMultiSelect: '<',
    dropdownId: '<'
  },
  controller,
  template
});

interface IController extends ng.IOnDestroy, ng.IPostLink {
  open: boolean;
  isMultiSelect: boolean;
  dropdownId: string;
}

function controller(this: IController, $element: JQLite, $scope: ng.IScope) {
  'ngInject';
  const vm = this;

  strictAssign(vm, {
    $onDestroy,
    $postLink
  });

  function $onDestroy() {
    $element.off('click', handleClick);
  }

  function handleClick() {
    if (vm.open) return;

    vm.open = true;
    $scope.$apply();
    window.setTimeout(() => {
      if (vm.isMultiSelect) {
        handleMultiSelectClick();
        return;
      }

      angular.element(document).one('click', () => {
        vm.open = false;
        $scope.$apply();
      });
    });
  }

  function handleMultiSelectClick() {
    angular.element(document).on('click', $event => {
      const multiSelect = document.getElementById(vm.dropdownId);

      if ((multiSelect && multiSelect.contains($event.target)) || !vm.open) return;

      vm.open = false;
      angular.element(document).off('click');
      $scope.$apply();
    });
  }

  function $postLink() {
    $element.on('click', handleClick);
  }
}
