const WORKFLOW_COURIER = [
  { value: 0, text: 'LF&E' },
  { value: 1, text: 'DPD' },
  { value: 100, text: 'Staff Delivery' },
  { value: 101, text: 'Other' },
];

type WORKFLOW_COURIER = typeof WORKFLOW_COURIER;

export default WORKFLOW_COURIER;
