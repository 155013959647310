import angular from "angular";
import module from "../module";

import { CARE_SERVICE_TYPE } from "#/constants/index";

module.factory("CustomerHelperService", function(SortService) {
  "ngInject";

  var service = {
    distinctServices,
    isPlaceholder
  };

  return service;

  function distinctServices(customers) {
    if (!angular.isArray(customers)) {
      customers = [customers];
    }

    // make set for enrollments by type
    var enrollmentsByType = {};
    CARE_SERVICE_TYPE.forEach(function(enrollment) {
      enrollmentsByType[enrollment.type] = enrollment;
    });

    //distinct services by type and name from customers
    var pushedServiceHash = {},
      services = [];

    customers.forEach(function(customer) {
      customer.services.forEach(function(service) {
        var searchKeySuffix = service.key.name ? "-" + service.key.name : "",
          searchKey = service.key.type + searchKeySuffix,
          name,
          nameSuffix;

        if (!pushedServiceHash[searchKey]) {
          pushedServiceHash[searchKey] = true;

          nameSuffix = service.key.name ? " - " + service.key.name : "";
          name = enrollmentsByType[service.key.type].text + nameSuffix;

          services.push({
            type: service.key.type,
            typeWithName: searchKey,
            text: name,
            serviceName: enrollmentsByType[service.key.type].text,
            specialtyText: service.key.name
          });
        }
      });
    });

    services = SortService.naturalSorter(service => service.text)(services);

    return services;
  }

  function isPlaceholder(service) {
    return service.key.type === 32767;
  }
});
