const SERVICE_UNIT_OF_MEASURE = [
  { value: 0, text: 'Devices' },
  { value: 1, text: 'Syringes' },
  { value: 2, text: 'Tablets' },
  { value: 3, text: 'Capsules' },
  { value: 4, text: 'Tubes' },
  { value: 5, text: 'Bottles' },
  { value: 6, text: 'Days' },
  { value: 7, text: 'Weeks' },
];

type SERVICE_UNIT_OF_MEASURE = typeof SERVICE_UNIT_OF_MEASURE;

export default SERVICE_UNIT_OF_MEASURE;
