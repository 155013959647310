import module from './module';
import IApiService, { IRequestConfig } from '#/interface/core/service/api-service';
import { buildUrl } from '#/utils/api';

module.factory('ApiService', ApiService);

function ApiService($http: ng.IHttpService): IApiService {
  'ngInject';

  return {
    get,
    post,
    postWithFullResponse,
    patch,
    put,
    request,
    apiDelete
  };

  function get<T = void>(resource: string, params?: object, headers?: object): ng.IPromise<T> {
    const url = buildUrl(resource);
    return $http
      .get<T>(url, { params, headers })
      .then(_ => _.data);
  }

  function post<T = void>(resource: string, data?: any): ng.IPromise<T> {
    const url = buildUrl(resource);
    return $http.post<T>(url, data).then(_ => _.data);
  }

  function postWithFullResponse(resource: string, data?: any, headers?: object): ng.IPromise<any> {
    const url = buildUrl(resource);
    return $http.post(url, data, { headers }).then(response => response);
  }

  function patch<T = void>(resource: string, data?: any): ng.IPromise<T> {
    const url = buildUrl(resource);
    return $http.patch<T>(url, data).then(_ => _.data);
  }

  function put<T = void>(resource: string, data?: any): ng.IPromise<T> {
    const url = buildUrl(resource);
    return $http.put<T>(url, data).then(_ => _.data);
  }

  function apiDelete<T = void>(resource: string, data?: any): ng.IPromise<T> {
    const url = buildUrl(resource);
    return $http.delete<T>(url, data).then(_ => _.data);
  }

  function request<T = void>(resource: string, config: IRequestConfig): ng.IHttpPromise<T> {
    const url = buildUrl(resource),
      ngConfig: ng.IRequestConfig = Object.assign({ url }, config);

    return $http<T>(ngConfig);
  }
}
