import { ISearchParams, IApiTasksService } from '#/interface/core/service/api-tasks-service';
import { ITask, INewTask, ITaskType, IReason } from '#/interface/core/entity/task';
import IApiService from '#/interface/core/service/api-service';
import IPage from '#/interface/core/entity/page';
import { searchQuery, diffObject } from '#/utils/api';
import module from './module';
import ISortField from '#/interface/core/entity/sort-field';

module.factory('ApiTasksService', ApiTasksService);

function ApiTasksService($q: ng.IQService, $timeout: ng.ITimeoutService, ApiService: IApiService): IApiTasksService {
  'ngInject';
  type TimeoutPromise = ng.IPromise<void>;

  interface UndoableTask {
    task: ITask;
    promise: TimeoutPromise;
  }

  const undoableTasks = new Map<string, UndoableTask>(),
    tasksUnderProcessing = new Set<string>(),
    api: IApiTasksService = {
      getAllTasks,
      getOutstandingTasks,
      getHistoricalTasks,
      getTaskTypes,
      getTaskCancellationReasons,
      getTaskFailureReasons,
      getTaskDidNotAttendReasons,
      getNumberOfTasksOutstandingForEnrollment,
      taskUpdateInProgress,
      updateUndoableTasks,
      canUndo,
      create,
      done,
      undo,
      notDone,
      canceled,
      failed,
      arrived,
      didNotArrive,
      change,
      generateWorkflow,
      getExportedTasks,
      recordData,
      getTaskRescheduleReasons,
      confirmPvf
    };

  function taskUpdateInProgress(taskId: string) {
    return tasksUnderProcessing.has(taskId);
  }

  function canUndo(taskId: string) {
    return undoableTasks.has(taskId);
  }

  function updateUndoableTasks(task: ITask) {
    undoableTasks.forEach(_ => {
      if (task.linkedEnrollmentId === _.task.linkedEnrollmentId)
        _.task.anticipatedDeliveryDate = task.anticipatedDeliveryDate;
    });
  }

  function getAllTasks(params: ISearchParams) {
    const obj: ISearchParams = assignSearchParams(params);
    return ApiService.get<IPage<ITask>>('tasks', obj);
  }

  function getOutstandingTasks(params: ISearchParams | ISearchParams & { requestId: number | undefined }) {
    const obj: ISearchParams = assignSearchParams(params);
    return ApiService.get<IPage<ITask> & { requestId: number }>('tasks/outstanding', obj).then(assignOrderedTasks(obj, params)).then((data: any) => {
      data.requestId = (params as (ISearchParams & { requestId: number | undefined })).requestId;
      return data;
    });
  }

  function assignOrderedTasks(obj: ISearchParams, params: ISearchParams): any {
    return (_: { items: any[]; }) => {
      const tasks = [...undoableTasks.values()]
        .filter(
          undoableTask => !obj.patientId || undoableTask.task.linkedPatientId === obj.patientId
        )
        .map(undoableTask => undoableTask.task),
        newItems = _.items.concat(tasks),
        orderedNewItems = newItems.slice().sort((a, b) => {
          if (!params.sortArray || params.sortArray.length === 0)
            return a.scheduledDate.getTime() - b.scheduledDate.getTime();

          let result: number = 0;

          params.sortArray.find((it: any) => {
            result = orderByField(a, b, it);
            return result !== 0;
          });

          return result;
        });

      return Object.assign({}, _, { items: orderedNewItems });
    };
  }

  function getExportedTasks(params: ISearchParams, includeHistorical: boolean) {
    const obj = assignSearchParams(params);
    const headers = { Accept: 'text/csv' };
    const endpoint = includeHistorical ? 'tasks' : 'tasks/outstanding';
    return ApiService.get(endpoint, obj, headers);
  }

  function assignSearchParams(params: any) {
    const obj: ISearchParams = searchQuery('', params.start, params.count, params.sortArray);
    obj.taskTypes = params.taskTypes || null;
    obj.patientId = params.patientId || null;
    obj.documentId = params.documentId || null;
    obj.toScheduledDate = params.toScheduledDate || null;
    obj.fromScheduledDate = params.fromScheduledDate || null;
    obj.customerId = params.customerId || null;
    obj.enrollmentTypes = params.enrollmentTypes || null;
    obj.enrollmentSpecialties = params.enrollmentSpecialties || null;
    obj.userId = params.userId || null;
    obj.unassigned = params.unassigned || null;
    obj.locationId = params.locationId || null;
    return obj;
  }

  function orderByField(aTask: any, bTask: any, field: ISortField) {
    return field.order
      ? getField(aTask, field.field) - getField(bTask, field.field)
      : getField(bTask, field.field) - getField(aTask, field.field);
  }

  function getField(obj: any, fieldName: string) {
    const field = obj[fieldName];

    if (field instanceof Date) return field.getTime();

    return field;
  }

  function getHistoricalTasks(params: ISearchParams) {
    const obj: ISearchParams = searchQuery('', params.start, params.count, params.sortArray);
    obj.patientId = params.patientId || null;
    obj.documentId = params.documentId || null;
    return ApiService.get<IPage<ITask>>(`tasks/historical`, obj);
  }

  function create(taskInfo: INewTask, withReturnHeaders: boolean = false) {
    return withReturnHeaders ? ApiService.postWithFullResponse('tasks', taskInfo) : ApiService.post('tasks', taskInfo);
  }

  function change(taskId: string, scheduledDate: Date | null, scheduledTime: any, length: any, assignedUser: any, locationType: number | null, locationId: string, locationDetails: any, reasonId: number | null) {
    let utcDate = scheduledDate; // so if it is null or undefined, utcDate will be whatever scheduledDate is.

    if (scheduledDate !== null && scheduledDate !== undefined) {
      utcDate = new Date(Date.UTC(scheduledDate.getFullYear(), scheduledDate.getMonth(), scheduledDate.getDate()));
    }

    return ApiService.patch(`tasks/${taskId}/`, { scheduledDate: utcDate, scheduledTime, length, assignedUser, locationType, locationId, locationDetails, reasonId });
  }

  function getTaskTypes() {
    return ApiService.get<ITaskType[]>('task-types');
  }

  function getTaskCancellationReasons() {
    return ApiService.get<IReason[]>('task-cancellation-reasons');
  }

  function getTaskFailureReasons() {
    return ApiService.get<IReason[]>('task-failure-reasons');
  }

  function getTaskRescheduleReasons() {
    return ApiService.get<IReason[]>('task-reasons/2');
  }

  function getTaskDidNotAttendReasons() {
    return ApiService.get<IReason[]>('task-reasons/3');
  }

  function getNumberOfTasksOutstandingForEnrollment(enrollmentId: string) {
    return ApiService.get(
      'tasks/outstanding-for-enrollment/' + enrollmentId
    ).then((data) => {
      return data;
    });
  }

  function done(task: ITask, onBehalfOfUserId: string | null) {
    const result = ApiService.post(`tasks/${task.id}/complete`, { onBehalfOfUserId });

    registerProcessing(task.id, result);

    result.then(() => {
      const timeout = $timeout(() => {
        undoableTasks.delete(task.id);
      }, 60000);

      undoableTasks.set(task.id, {
        promise: timeout,
        task
      });
    });

    return result;
  }

  function notDone(taskId: string) {
    cancelWaitingForUndo(taskId);

    const result = ApiService.post(`tasks/${taskId}/mark-as-not-done`, null);
    registerProcessing(taskId, result);

    return result;
  }

  function undo(taskId: string) {
    cancelWaitingForUndo(taskId);

    const result = ApiService.post(`tasks/${taskId}/undo`, null);
    registerProcessing(taskId, result);

    return result;
  }

  function canceled(taskId: string, reasonId: number) {
    cancelWaitingForUndo(taskId);

    const result = ApiService.post(`tasks/${taskId}/cancel`, { reasonId });
    registerProcessing(taskId, result);

    return result;
  }

  function failed(taskId: string, reasonId: number) {
    cancelWaitingForUndo(taskId);

    const result = ApiService.post(`tasks/${taskId}/fail`, { reasonId });
    registerProcessing(taskId, result);

    return result;
  }

  function arrived(taskId: string, arrivalTime: Date | null) {
    cancelWaitingForUndo(taskId);

    const result = ApiService.put(
      `tasks/${taskId}/patient-arrival`,
      {
        arrivalTime: arrivalTime === null ? null : `${arrivalTime.toISOString().split('T')[0]}T${arrivalTime.toLocaleTimeString('en-GB')}`
      });

    registerProcessing(taskId, result);

    return result;
  }

  function didNotArrive(taskId: string) {
    cancelWaitingForUndo(taskId);

    const result = ApiService.post(
      `tasks/${taskId}/patient-did-not-arrive`, null
    )

    registerProcessing(taskId, result);

    return result;
  }

  function cancelWaitingForUndo(taskId: string) {
    const undoableTask = undoableTasks.get(taskId);
    $timeout.cancel(undoableTask && undoableTask.promise);
    undoableTasks.delete(taskId);
  }

  function registerProcessing(taskId: string, promise: ng.IPromise<any>) {
    tasksUnderProcessing.add(taskId);

    const finalize = () => tasksUnderProcessing.delete(taskId);
    promise.then(finalize, finalize);
  }

  function generateWorkflow(taskId: string, taskDataType: number, markAsCompleted: boolean, workflowModel: any) {
    return ApiService.postWithFullResponse(`tasks/${taskId}/generate-workflow/`, { markasCompleted: markAsCompleted, taskData: { type: taskDataType, data: workflowModel }});
  }

  function recordData(taskId: string, taskDataType: number, taskData: any, oldTaskData: any) {
    if (oldTaskData !== undefined) {
      const diff = diffObject(oldTaskData, taskData, true);
      if (!diff) {
        return $q.resolve(true);
      }
    }

    return ApiService.postWithFullResponse(`tasks/${taskId}/record-data/`, { type: taskDataType, data: taskData });
  }

  function confirmPvf(taskId: string, taskDataType: number, signatureToken: string, pvfData: any) {
    const headers = { 'x-ESig-Token': signatureToken }
    return ApiService.postWithFullResponse(`tasks/${taskId}/confirm-pvf/`, { type: taskDataType, data: pvfData }, headers);
  }

  return api;
}
