import module from "./module";
import { buildUrl } from "#/utils/api";

module.factory("ApiPdfService", ApiPdfService);

function ApiPdfService($sce, ApiResourcesService) {
  "ngInject";
  const api = ApiResourcesService.PdfAnnotations;

  return {
    getPdfArraybuffer,
    download,
    getAnnotations,
    createAnnotation,
    updateAnnotation,
    removeAnnotation,
    getEmbedDocumentUrl
  };

  /*  documentParams = {
            documentId, 
            version, 
            showAnnotations,
        }

        returns pdf array buffer
    */
  function getPdfArraybuffer(documentParams) {
    const tempParams = Object.assign({ showAnnotations: false }, documentParams);

    return ApiResourcesService.Pdf.get(tempParams).$promise.then(data => data.buffer);
  }

  function download(documentParams) {
    const tempParams = Object.assign({ showAnnotations: false }, documentParams);

    ApiResourcesService.Pdf.get(tempParams)
      .$promise.then(data => {
        return {
          blob: new Blob([data.buffer], { type: data.type }),
          filename: data.filename
        };
      })
      .then(downloadBlob);

    function downloadBlob({ blob, filename }) {
      if (window.navigator.msSaveBlob) {
        window.navigator.msSaveBlob(blob, filename);
        return;
      }

      const blobUrl = window.URL.createObjectURL(blob),
        anchor = document.createElement("a");

      anchor.href = blobUrl;
      anchor.download = filename;

      document.body.appendChild(anchor);
      anchor.click();

      window.setTimeout(function() {
        document.body.removeChild(anchor);
        window.URL.revokeObjectURL(blobUrl);
      }, 100);
    }
  }

  /*  documentParams = {
            documentId, 
            version, 
        }
    */
  function getAnnotations(documentParams) {
    return api.query(documentParams).$promise;
  }

  function createAnnotation(documentParams, annotation) {
    return api.create(documentParams, annotation).$promise;
  }

  function updateAnnotation(documentParams, annotation) {
    const params = Object.assign(documentParams, { annotationId: annotation.id });
    return api.update(params, annotation).$promise;
  }

  function removeAnnotation(documentParams, annotationId) {
    const params = Object.assign(documentParams, { annotationId });
    return api.remove(params).$promise;
  }

  function getEmbedDocumentUrl(documentParams) {
    const documentApi = ApiResourcesService.Documents,
      requestParams = {
        id: documentParams.documentId,
        version: documentParams.version
      },
      result = documentApi.getDocumentToken(requestParams, null).$promise.then(data => {
        const token = encodeURIComponent(data.token),
          url = buildUrl(`document/?token=${token}&.pdf`);

        return $sce.trustAsResourceUrl(url);
      });

    return result;
  }
}
