import angular from "angular";
import module from "./module";

module.factory("PopoutService" /* @ngInject */, function(
  $transitions,
  $window,
  $state,
  $q,
  $interval
) {
  const ChildWindowNames = [],
    parentWindow = $window.opener,
    service = {},
    isMainWindow = parentWindow == null;

  service.isMainWindow = function() {
    return isMainWindow;
  };

  service.isPopoutWindow = function() {
    return !service.isMainWindow();
  };

  service.init = init;
  service.open = open;
  service.close = close;

  if (service.isPopoutWindow()) {
    $transitions.onSuccess({}, function() {
      // just for sure that parent window has direct access for child state (optimization)
      if (!parentWindow || !parentWindow[$window.name] || !parentWindow[$window.name].childState) {
        parentWindow[$window.name].childState = $state;
      }
    });
  }

  return service;

  function close(windowName) {
    if (service.isPopoutWindow()) {
      if (parentWindow && parentWindow[windowName]) {
        delete parentWindow[windowName];
      }
      $window.close();
      return true;
    }

    if (isWindowOpen(windowName)) {
      const index = ChildWindowNames.indexOf(windowName);

      if (index !== -1) {
        const closeState = $window[windowName] && $window[windowName].onCloseGotoState;
        if (closeState && closeState.state) {
          open(closeState.state, closeState.params, windowName);
        } else {
          ChildWindowNames.splice(index, 1);
          open("app.close", null, windowName);
        }
      }
    }

    return false;
  }

  function open(state, stateParams, windowName, windowParams, onCloseGotoState) {
    var result = openPopoutWindow(state, stateParams, windowName, windowParams, onCloseGotoState);
    if (result) {
      return $q.when(true);
    } else {
      return $q.reject(
        "Pop-up windows were blocked in your browser. Please allow pop-ups for this site and refresh the page"
      );
    }
  }

  function openPopoutWindow(state, stateParams, windowName, windowParams, onCloseGotoState) {
    if (!service.isMainWindow()) return false;

    const childObject = $window[windowName],
      childState = childObject && !childObject.child.closed && childObject.childState;

    if (childState) {
      const reload =
        angular.equals(childState.current.name, state) &&
        angular.equals(childState.params, stateParams);

      childState.go(state, stateParams, { reload });
      childObject.child.focus();
      return true;
    }

    if (state === "app.close") return true;

    const child = $window.open($state.href(state, stateParams), windowName, windowParams);
    if (child == null) return false;

    // avoid adding existed After refresh main page
    if (ChildWindowNames.indexOf(windowName) === -1) {
      ChildWindowNames.push(windowName);
      $window[windowName] = { child, onCloseGotoState };
      child.focus();
    }

    return true;
  }

  function isWindowOpen(windowName) {
    const childObject = $window[windowName];
    return childObject && !childObject.child.closed;
  }

  function init() {
    if (service.isPopoutWindow()) {
      if (!parentWindow[$window.name]) {
        $state.go("app.close");
      } else {
        parentWindow[$window.name].childState = $state;
        document.body.classList.add("popout-window");
        var stop = $interval(function() {
          if (parentWindow.closed) {
            $state.go("app.close");
            stop();
          }
        }, 500);
      }
    } else {
      angular.element(document.body).addClass("with-navbar");
      ChildWindowNames.length = 0;
    }
  }
});
