const HEALTHCARE_PROFESSIONAL = [
  { value: 0, text: 'Doctor' },
  { value: 1, text: 'Pharmacist' },
  { value: 2, text: 'Nurse' },
  { value: 3, text: 'Administrative Staff' }
];

type HEALTHCARE_PROFESSIONAL = typeof HEALTHCARE_PROFESSIONAL;

export default HEALTHCARE_PROFESSIONAL;
