import angular from "angular";
import module from "../module";

module
  .directive("sortable", function() {
    return {
      restrict: "A",
      scope: {
        sortArray: "=sortable",
        onSortChange: "&?onSortChange"
      },
      priority: 100,
      /*@ngInject */
      controller: function($scope) {
        var sortObj,
          obj = null,
          onChange =
            angular.isDefined($scope.onSortChange) && $scope.onSortChange()
              ? $scope.onSortChange()
              : angular.noop;

        $scope.sortArray = angular.isArray($scope.sortArray) ? $scope.sortArray : [];
        this.getSortItem = getSortItem;

        function getSortItem(fieldName) {
          if (sortObj && sortObj.field === fieldName) {
            return sortObj;
          }
          obj = null;
          angular.forEach($scope.sortArray, function(item) {
            if (item.field === fieldName) {
              obj = item;
              return;
            }
          });
          return obj;
        }

        this.onClickSort = function(field, devastate) {
          sortObj = getSortItem(field);
          if (sortObj !== null) {
            sortObj.order = !sortObj.order;
            if (devastate) {
              $scope.sortArray = [sortObj];
            }
          } else {
            if (devastate) {
              $scope.sortArray = [];
            }
            $scope.sortArray.push({
              field: field,
              order: sortObj === null ? true : sortObj.order
            });
          }

          // let dual binding be processed before onChange
          $scope.$apply();
          onChange();
        };
      },
      link: function(scope) {
        scope.sortArray = angular.isArray(scope.sortArray) ? scope.sortArray : [];
      }
    };
  })
  .directive("sortField" /* @ngInject */, function($compile) {
    return {
      restrict: "A",
      require: "^sortable",
      scope: false,
      priority: 100,
      link: function(scope, elm, attr, ctrl) {
        var field = attr.sortField;
        if (!field) {
          return;
        }
        scope.getSortItem = ctrl.getSortItem;
        var sortElem = angular.element(
          "<span class='icon icon__sort ' " +
            "ng-class='{ascending:getSortItem(\"" +
            field +
            '").order===true,descending:getSortItem("' +
            field +
            "\").order===false }' >" +
            "</span>"
        );
        elm.append(sortElem);
        $compile(sortElem)(scope);
        elm.bind("click", function(event) {
          ctrl.onClickSort(field, !event.shiftKey);
        });
        elm.on("$destroy", function() {
          elm.off("click");
        });
      }
    };
  });
