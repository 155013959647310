import module from "./module";

import { searchQuery } from "#/utils/api";

module.factory("ApiNotesService", ApiNotesService);

function ApiNotesService($rootScope, ApiResourcesService) {
  "ngInject";

  const api = {
    getNotes,
    addPatientNote,
    addChangeListener,
    pinNote,
    unpinNote
  };

  return api;

  function getNotes(params) {
    var obj = searchQuery("", params.start || 0, params.count || 1, params.sortArray || []);
    obj.patientId = params.patientId;
    return ApiResourcesService.Notes.query(obj, null).$promise;
  }

  function addPatientNote(patientId, note) {
    const result = ApiResourcesService.Notes.create({ patientId: patientId }, note).$promise;

    result.then(handleNotesChange);

    return result;
  }

  function pinNote(patientId, noteId) {
    const result = ApiResourcesService.Notes.pin({ patientId: patientId, noteId: noteId }).$promise;

    result.then(handleNotesChange);

    return result;
  }

  function unpinNote(patientId, noteId) {
    const result = ApiResourcesService.Notes.unpin({ patientId: patientId, noteId: noteId })
      .$promise;

    result.then(handleNotesChange);

    return result;
  }

  function addChangeListener(listener) {
    return $rootScope.$on("NOTES_CHANGED", listener);
  }

  function handleNotesChange() {
    $rootScope.$emit("NOTES_CHANGED");
  }
}
