import module from "../module";
import template from "./workflow.tmpl.html";

module.component("workflow", {
  bindings: {
    onCancelGeneration: "&",
    patientId: "<",
    patientName: "<",
    task: "<",
    taskType: "<",
    isRegeneration: "<"
  },
  controllerAs: "vm",
  controller: function($scope) {
    "ngInject";

    var _this = this;
    $scope.$on("childMaximiseWorkflow", handleMaximiseWorkflow);

    Object.assign(_this, {
      handleMaximiseWorkflow
    });

    function handleMaximiseWorkflow() {
      $scope.$broadcast("maximiseWorkflow", undefined);
    }
  },

  template
});
