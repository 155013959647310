import module from "../module";
import template from "./enum-picker.tmpl.html";

import { SEX as sexEnum, GENDER as genderEnum } from "#/constants/index";

module
  .directive("sexPicker" /* @ngInject */, function() {
    return {
      restrict: "A",
      require: "ngModel",
      replace: true,
      scope: {
        model: "=ngModel"
      },
      template,
      priority: 100,
      controller: /*@ngInject */ function($scope) {
        $scope.enums = sexEnum;
      }
    };
  })
  .directive("genderPicker" /* @ngInject */, function() {
    return {
      restrict: "A",
      require: "ngModel",
      replace: true,
      scope: {
        model: "=ngModel"
      },
      template,
      priority: 100,
      controller: /*@ngInject */ function($scope) {
        $scope.enums = genderEnum;
      }
    };
  });
