import module from "../module";
import template from "./workflow-form.tmpl.html";
import angular from "angular";
import { fieldError } from "#/utils/form";

import { isAddressEmpty } from "../../../utils/address-helpers";

import {
  WORKFLOW_CONFIRMATION_METHOD as workflowConfirmationMethodEnum,
  WORKFLOW_DELIVERY_CHARGES as workflowDeliveryCharges,
  WORKFLOW_COURIER_ENUM as workflowCourierEnum,
  NURSING_REQUIREMENT_VISIT_TYPE_ENUM as visitTypeEnum,
  CT_WORKFLOW_TYPE_ENUM as ctWorkflowTypeEnum,
  TASK_DATA_TYPE as taskDataType
} from "#/constants/index";

import { buildUrl } from "#/utils/api";
import config from "../../../constants/site";
Date.prototype.addDays = function(days) {
  var date = new Date(this.valueOf());
  date.setDate(date.getDate() + days);
  return date;
};

module.component("workflowForm", {
  bindings: {
    onCancelGeneration: "&",
    patientId: "<",
    patientName: "<",
    task: "<",
    isRegeneration: "<"
  },
  controllerAs: "vm",
  controller: function(
    $scope,
    $rootScope,
    $filter,
    ApiPatientsService,
    ApiCustomersService,
    ApiWorkingDayService,
    ApiTasksService,
    ApiServicesService,
    ApiCourierDeliveryService,
    NotificationsService,
    PrincipalService,
    PatientAddressService,
    ApiClinicsService
  ) {
    "ngInject";
    this.workflowConfirmationMethodEnum = workflowConfirmationMethodEnum;
    this.workflowCourierEnum = workflowCourierEnum;
    this.workflowDeliveryCharges = workflowDeliveryCharges;
    this.visitTypeEnum = visitTypeEnum;
    this.taskDataType = taskDataType;
    const LFE = "LF&E";
    var _this = this;
    _this.$onInit = function() {
      _this.stopWorkingDayBefore = _this.isRegeneration;
      loadData();
    };

    $scope.$on("patientUpdated", () => {
      _this.patientUpdatedEventOccurred = true;
      loadData();
    });

    var now = new Date();
    var tomorrow = new Date().addDays(1);

    Object.assign(_this, {
      detailOnlySelected: false,
      otherCollectionSelected: false,
      nursingRequiredSelected: false,
      availableStorageRequirements: [],
      availableServiceLevels: [],
      availableCourierServices: [],
      workflowCourierList: [],
      availableAddresses: [],
      clinicLocations: [],
      patientAddresses: [],
      freeAncils: [],
      billableAncils: [],
      hasAddresses: false,
      selectedAddress: null,
      patientAddress: undefined,
      selectedStorageRequirement: undefined,
      deliveryChargesSelected: 0,
      todaysDate: new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate())),
      canGenerate: true,
      isCompounded: false,
      loadingMessage: null,
      markAsCompleted: true,
      patientUpdatedEventOccurred: false,
      stopWorkingDayBefore: false,
      handleUpdateCourier,
      handleUpdateCharge,
      handleContactNameClick,
      handleUpdateSelectedAncils,
      handleGenerateWorkflow,
      handleUpdateAddress,
      handleStorageRequirementChange,
      handleDeliveryAddressTypeChange,
      handleShipmentDate: loadWorkingDayBefore,
      courierLookup,
      getServiceLevelsForCourier,
      loadData,
      fieldError,
      isValid,
      getDeliveryChecked,
      getAncillaryChecked,
      handleUpdateModelNursingRequired,
      modelNursingRequiredValue: undefined,
      showNursingRequired: false,
      availableVisitTypes: [],
      updateDateOfDelivery,
      model: {
        currentStockAtHome: 0,
        numberOfBoxes: 0,
        dateOfDelivery: new Date(
          Date.UTC(tomorrow.getFullYear(), tomorrow.getMonth(), tomorrow.getDate())
        ),
        shipmentDate: "",
        confirmationNumber: "",
        contactName: "",
        plannedDateOfFirstUse: new Date(),
        confirmationRequired: 0,
        courierOptions: {
          courier: undefined,
          courierDetail: "",
          serviceLevel: undefined,
          otherService: "",
          sharpsCollectionRequired: false,
          otherCollection: "",
          deliveryAddressId: null,
          deliveryAddress: null,
          deliveryAddressType: 0,
          noConsignmentNeeded: null
        },
        nursingRequired: 1,
        isNewPatient: false,
        deliveryCharges: [],
        selectedAncillaries: {
          consumables: [],
          equipment: [],
          coMeds: [],
          assessments: []
        },
        notes: "",
        consignmentLabelNotes: ""
      },
      selfServiceData: null
    });

    function handleUpdateCourier(courierNumber, isFirstLoad) {
      _this.model.courierOptions.courierDetail = "";
      _this.model.courierOptions.otherService = "";
      _this.cutoffForCourierPassed = false;
      _this.cutoffForCourier = "";
      _this.model.courierOptions.noConsignmentNeeded = courierNumber === 0 ? false : null;
      _this.availableServiceLevels = getServiceLevelsForCourier(courierNumber);

      const courier = _this.availableCourierServices.find(c => c.courierId === courierNumber);

      if (courier) {
        _this.serviceLevelLookupSucceeded = courier.lookUpSucceeded;
      } else {
        _this.serviceLevelLookupSucceeded = true;
      }

      _this.model.courierOptions.serviceLevel = _this.availableServiceLevels[0];

      initialiseCourierDataForRegeneration(isFirstLoad);

      _this.detailOnlySelected =
        _this.model.courierOptions.courier === _this.workflowCourierEnum.StaffDelivery ||
        _this.model.courierOptions.courier === _this.workflowCourierEnum.Other;

      handleCutOffTime();
    }

    function handleCutOffTime() {
      if (_this.model.courierOptions.courier === workflowCourierEnum.LFAndE) {
        _this.cutoffForCourier = config.courierCutoffs.LFAndE;
        const cutoffTimeForShipmentDate = new Date(`${_this.model.shipmentDate.toDateString()}
          ${_this.cutoffForCourier}`);
        if (new Date() >= cutoffTimeForShipmentDate) {
          _this.cutoffForCourierPassed = true;
        } else {
          _this.cutoffForCourierPassed = false;
        }
      }
    }

    function handleUpdateCharge(charge) {
      const index = _this.model.deliveryCharges.map(c => c.code).indexOf(charge.code);
      if (index > -1) {
        _this.model.deliveryCharges.splice(index, 1);
        _this.deliveryChargesSelected--;
      } else {
        _this.model.deliveryCharges.push(charge);
        _this.deliveryChargesSelected++;
      }
    }

    function getDeliveryChecked(charge) {
      return !!_this.model.deliveryCharges.find(c => c.code === charge.code);
    }

    function getAncillaryChecked(ancillary) {
      switch (ancillary.type) {
        case "consumables":
          return !!_this.model.selectedAncillaries.consumables.find(a => a.id === ancillary.id);
        case "equipment":
          return !!_this.model.selectedAncillaries.equipment.find(a => a.id === ancillary.id);
        case "coMeds":
          return !!_this.model.selectedAncillaries.coMeds.find(a => a.id === ancillary.id);
        case "assessments":
          return !!_this.model.selectedAncillaries.assessments.find(a => a.id === ancillary.id);
      }
    }

    function handleContactNameClick() {
      _this.model.contactName = "";
    }

    function handleUpdateSelectedAncils(ancil) {
      if (ancil.type === "consumables") {
        const index = _this.model.selectedAncillaries.consumables.map(c => c.id).indexOf(ancil.id);
        if (index > -1) {
          _this.model.selectedAncillaries.consumables.splice(index, 1);
        } else {
          _this.model.selectedAncillaries.consumables.push(ancil);
        }
      }

      if (ancil.type === "equipment") {
        const index = _this.model.selectedAncillaries.equipment.map(c => c.id).indexOf(ancil.id);
        if (index > -1) {
          _this.model.selectedAncillaries.equipment.splice(index, 1);
        } else {
          _this.model.selectedAncillaries.equipment.push(ancil);
        }
      }

      if (ancil.type === "coMeds") {
        const index = _this.model.selectedAncillaries.coMeds.map(c => c.id).indexOf(ancil.id);
        if (index > -1) {
          _this.model.selectedAncillaries.coMeds.splice(index, 1);
        } else {
          _this.model.selectedAncillaries.coMeds.push(ancil);
        }
      }

      if (ancil.type === "assessments") {
        const index = _this.model.selectedAncillaries.assessments.map(c => c.id).indexOf(ancil.id);
        if (index > -1) {
          _this.model.selectedAncillaries.assessments.splice(index, 1);
        } else {
          _this.model.selectedAncillaries.assessments.push(ancil);
        }
      }
    }

    function handleUpdateAddress(addressNumber) {
      if (addressNumber === -1) {
        _this.model.courierOptions.deliveryAddressId = null;
        _this.model.courierOptions.deliveryAddress = null;
        _this.model.consignmentLabelNotes = "";
      } else {
        _this.model.courierOptions.deliveryAddressId = _this.availableAddresses[addressNumber].id;
        _this.model.courierOptions.deliveryAddress = _this.availableAddresses[addressNumber].raw;
        _this.model.consignmentLabelNotes =
          _this.availableAddresses[addressNumber].specialInstructions;
      }

      _this.courierLookup(false);
    }

    function handleStorageRequirementChange() {
      _this.courierLookup(false);
      _this.model.serviceStorageRequirements = _this.selectedStorageRequirement.id;
    }

    function handleUpdateModelNursingRequired() {
      if (!_this.nursingRequiredSelected) {
        _this.modelNursingRequiredValue = _this.model.nursingRequired;
        _this.model.nursingRequired = visitTypeEnum[0].value;
      } else if (_this.modelNursingRequiredValue !== undefined) {
        _this.model.nursingRequired = _this.modelNursingRequiredValue;
      } else {
        _this.model.nursingRequired = _this.availableVisitTypes[0].value;
      }
    }

    function handleGenerateWorkflow(workflowType) {
      _this.canGenerate = false;
      if (_this.model.shipmentDate > _this.model.dateOfDelivery) {
        _this.canGenerate = true;
        return alert(
          "Shipment date cannot be after delivery date this will not stop the workflow from being generated"
        );
      }

      const dateOfDeliveryUtc = new Date(
        Date.UTC(
          _this.model.dateOfDelivery.getFullYear(),
          _this.model.dateOfDelivery.getMonth(),
          _this.model.dateOfDelivery.getDate()
        )
      );
      const shipmentDateUtc = new Date(
        Date.UTC(
          _this.model.shipmentDate.getFullYear(),
          _this.model.shipmentDate.getMonth(),
          _this.model.shipmentDate.getDate()
        )
      );

      _this.model.dateOfDelivery = dateOfDeliveryUtc;
      _this.model.shipmentDate = shipmentDateUtc;

      if (_this.model.plannedDateOfFirstUse) {
        const plannedDateOfFirstUseUtc = new Date(
          Date.UTC(
            _this.model.plannedDateOfFirstUse.getFullYear(),
            _this.model.plannedDateOfFirstUse.getMonth(),
            _this.model.plannedDateOfFirstUse.getDate()
          )
        );

        _this.model.plannedDateOfFirstUse = plannedDateOfFirstUseUtc;
      }

      const shouldMarkAsComplete =
        _this.markAsCompleted &&
        _this.task.status === 0 &&
        workflowType !== _this.taskDataType.ClinicalTrialWorkflow;
      ApiTasksService.generateWorkflow(
        _this.task.id,
        workflowType,
        shouldMarkAsComplete,
        workflowType === _this.taskDataType.DispenseAndDeliverWorkflow ? _this.model : null
      )
        .then(response => {
          if (response.data.warnings.length) {
            alert(response.data.warnings.join("/n"));
          }

          const token = PrincipalService.getIdentity().token;
          const link = document.createElement("a");
          link.href = buildUrl(
            `${response.headers("location")}?token=${encodeURIComponent(token)}`
          );
          link.target = "_blank";
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
          _this.onCancelGeneration();
          return response.json();
        })
        .catch(error => {
          let errorMessage = error.statusText;
          if (errorMessage === undefined || errorMessage === "") {
            errorMessage =
              "An error occurred while generating this workflow, please try closing this window and generating a new one. If the problem persists please contact your system administrator.";
          }

          NotificationsService.error(
            angular.element(document.getElementById("workflow-api")),
            "Generation Failed",
            errorMessage,
            () => {
              _this.canGenerate = true;
            },
            false,
            15000
          );

          setTimeout(_this.onCancelGeneration, 15000);
        })
        .finally(() => {
          $rootScope.$emit("TASKS_CHANGED");
        });
    }

    function isValid() {
      return !(
        (_this.isCompounded && !_this.model.plannedDateOfFirstUse) ||
        (!_this.isCompounded && !_this.model.currentStockAtHome === null) ||
        _this.model.deliveryCharges.length === 0 ||
        !_this.hasAddresses ||
        _this.selectedAddress === null ||
        (_this.model.confirmationRequired === 2 && !_this.model.confirmationNumber) ||
        _this.model.courierOptions.courier === undefined ||
        _this.model.courierOptions.serviceLevel === undefined
      );
    }

    async function loadWorkingDayBefore() {
      if (_this.stopWorkingDayBefore) {
        return;
      }

      if (_this.model.dateOfDelivery !== undefined && _this.model.dateOfDelivery !== null) {
        const date = new Date(_this.model.dateOfDelivery);
        const formattedDate = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
        const workingDayBefore = await ApiWorkingDayService.getWorkingDayBefore(1, formattedDate);
        _this.model.shipmentDate = workingDayBefore;
        $scope.$digest();
      }
    }

    function setSelfServiceData() {
      if (
        !_this.task ||
        !_this.task.taskData ||
        _this.task.taskData.type !== taskDataType.SelfServiceResponse ||
        !_this.task.taskData.data
      ) {
        return;
      }

      const missingDataPlaceholder = "Not filled in";
      let currentStock = missingDataPlaceholder;
      if (
        _this.task.taskData.data.currentStock !== null &&
        _this.task.taskData.data.currentStock !== undefined
      ) {
        currentStock = `${_this.task.taskData.data.currentStock} (${_this.task.taskData.data.currentStockUom})`;
      }

      let deliveryLocation = missingDataPlaceholder;
      if (
        _this.task.taskData.data.deliveryLocation !== null &&
        _this.task.taskData.data.deliveryLocation !== undefined
      ) {
        deliveryLocation = _this.task.taskData.data.deliveryLocation.name;
      }
      let deliveryDate = missingDataPlaceholder;

      if (
        _this.task.taskData.data.deliveryDate !== null &&
        _this.task.taskData.data.deliveryDate !== undefined
      ) {
        const deliveryService = _this.task.taskData.data.deliveryService
          ? ` (${_this.task.taskData.data.deliveryService})`
          : "";

        deliveryDate = `${_this.task.taskData.data.deliveryDate.toLocaleDateString()}${deliveryService}`;
      }

      let confirmSms = missingDataPlaceholder;
      if (
        _this.task.taskData.data.confirmSms !== null &&
        _this.task.taskData.data.confirmSms !== undefined
      ) {
        confirmSms = _this.task.taskData.data.confirmSms ? "Yes" : "No";
      }

      let responseDateTime = missingDataPlaceholder;
      if (
        _this.task.taskData.data.responseDateTime !== null &&
        _this.task.taskData.data.responseDateTime !== undefined
      ) {
        responseDateTime = `${_this.task.taskData.data.responseDateTime.toLocaleDateString()} ${_this.task.taskData.data.responseDateTime.toLocaleTimeString()}`;
      }

      _this.selfServiceData = {
        currentStock,
        ancillaries:
          _this.task.taskData.data.ancillaries !== null &&
          _this.task.taskData.data.ancillaries.length > 0
            ? _this.task.taskData.data.ancillaries.join(", ")
            : "None",
        deliveryLocation,
        deliveryDate,
        confirmSms,
        responseDateTime
      };
    }

    function setPatient(patient) {
      let patientAddress = patient.address;
      const patientAddressEmpty = isAddressEmpty(patientAddress);
      let addresses = [];

      if (!patientAddressEmpty) {
        addresses.push({ id: undefined, address: patientAddress });
        _this.model.courierOptions.deliveryAddress = patientAddress;
      }

      addresses.push(...patient.deliveryAddresses.addresses);

      _this.hasAddresses = addresses.length !== 0;
      _this.patientAddresses = PatientAddressService.addressToValueText(
        addresses,
        !patientAddressEmpty
      );

      if (_this.model.courierOptions.deliveryAddressType === 1) {
        _this.availableAddresses = _this.clinicLocations;
      } else {
        _this.availableAddresses = _this.patientAddresses;
      }

      if (_this.availableAddresses.length == 1) {
        _this.selectedAddress = _this.availableAddresses[0].value;
        handleUpdateAddress(_this.selectedAddress);
      }

      const preferredContactDisplay = name => ` - ${$filter("view")(name, "name")}`;

      const nomConToPhone = nomCon =>
        nomCon.contactInfo.phones.map(nomConPhone => {
          return {
            canSendSms: nomConPhone.canSendSms,
            value: nomConPhone.value,
            name: nomCon.name
          };
        });

      _this.deliverySMSList = patient.contactInfo.phones
        .concat(patient.nominatedContacts.contacts.flatMap(nomConToPhone))
        .filter(number => number.canSendSms && number.value !== "")
        .map(number => {
          return {
            value: number.value,
            display: `${number.value}${number.name ? preferredContactDisplay(number.name) : ""}`
          };
        });

      if (patient.communicationRequirements.preferredSms) {
        let indexNumber = _this.deliverySMSList.findIndex(
          x => x.value === patient.communicationRequirements.preferredSms.value
        );
        if (indexNumber !== -1) {
          _this.preferredSms = _this.deliverySMSList[indexNumber];
        }
      }

      _this.deliveryContacts = [...patient.nominatedContacts.contacts];
      _this.deliveryContacts.push({
        name: _this.patientName,
        id: _this.patientId
      });

      _this.model.contactName = $filter("view")(_this.patientName, "name");
    }

    function setCustomerService(service) {
      _this.availableVisitTypes = [];
      _this.isCompounded = service.customerData.isCompounded;

      if (service.customerData.isCompounded) {
        _this.model.currentStockAtHome = null;
      } else {
        _this.model.plannedDateOfFirstUse = null;
      }

      const numberOfBoxes = service.customerData.numberOfBoxes;

      if (!numberOfBoxes) {
        const defaultNumberOfBoxes = 2;
        _this.model.numberOfBoxes = defaultNumberOfBoxes;
        _this.usingDefaultNumberOfBoxes = true;
      } else {
        _this.model.numberOfBoxes = service.customerData.numberOfBoxes;
      }

      _this.availableStorageRequirements = service.generalData.availableStorageRequirements;

      if (service.customerData.storageRequirements) {
        _this.selectedStorageRequirement = service.customerData.storageRequirements;
        _this.model.serviceStorageRequirements = _this.selectedStorageRequirement.id;
      }

      var ancilGroups = service.customerData.general.ancillariesToBeProvided;

      if (ancilGroups) {
        Object.keys(ancilGroups).forEach(k => {
          const ancilsWithType = ancilGroups[k].map(a => {
            return {
              billable: a.billable,
              id: a.id,
              term: a.term,
              type: k
            };
          });
          _this.freeAncils = _this.freeAncils.concat(
            ancilsWithType.filter(a => a.billable === false)
          );
          _this.billableAncils = _this.billableAncils.concat(
            ancilsWithType.filter(a => a.billable === true)
          );
        });
      }

      var trainingRequired = service.customerData.nursingRequirements.trainingRequired;
      var ongoingAdminRequired =
        service.customerData.nursingRequirements.ongoingAdministrationRequired;

      if (trainingRequired && ongoingAdminRequired) {
        _this.availableVisitTypes.push(visitTypeEnum[1], visitTypeEnum[2]);
      } else if (trainingRequired || ongoingAdminRequired) {
        _this.availableVisitTypes.push(trainingRequired ? visitTypeEnum[1] : visitTypeEnum[2]);
        _this.model.nursingRequired = trainingRequired
          ? visitTypeEnum[1].value
          : visitTypeEnum[2].value;
      }
    }

    function setEnrollment(service, workflowType, enrollment) {
      const nursingRequirements = service.customerData.nursingRequirements;
      const lsdDependency = enrollment.patientData.general.lsdDependency;

      if (
        !nursingRequirements.trainingRequired &&
        !nursingRequirements.ongoingAdministrationRequired
      ) {
        _this.showNursingRequired = false;
      } else {
        _this.showNursingRequired = lsdDependency != null && lsdDependency != 1;
      }

      _this.nursingRequiredSelected = _this.showNursingRequired;

      if (!_this.showNursingRequired) {
        _this.model.nursingRequired = visitTypeEnum[0].value;
      }

      if (ctWorkflowTypeEnum.find(t => t === workflowType)) {
        _this.canGenerate = true;
        handleGenerateWorkflow(_this.taskDataType.ClinicalTrialWorkflow);
      } else {
        $scope.$emit("childMaximiseWorkflow", undefined);
      }
    }

    function populateModel(patient, service, workflowType, enrollment) {
      setSelfServiceData();
      setPatient(patient);
      setCustomerService(service);
      setEnrollment(service, workflowType, enrollment);
    }

    async function loadData() {
      _this.loadingMessage = "Loading Patient and Customer data please wait...";
      try {
        await loadWorkingDayBefore();

        const patient = await ApiPatientsService.get(_this.patientId);
        const service = await ApiCustomersService.getService(
          patient.customerID,
          _this.task.linkedEnrollment.serviceKey
        );
        const serviceData = await ApiServicesService.getServiceData(
          _this.task.linkedEnrollment.key.type
        );
        const workflowType = serviceData.serviceDetails.workflowType;
        const enrollment = await ApiPatientsService.getEnrollment(
          _this.patientId,
          _this.task.linkedEnrollment.serviceKey,
          _this.task.linkedEnrollment.id
        );

        const clinicLocations = await ApiClinicsService.getAllClinicLocations();
        clinicLocations.forEach(l => (l.address.description = l.descriptor));
        _this.clinicLocations = PatientAddressService.addressToValueText(clinicLocations);

        populateModel(patient, service, workflowType, enrollment);
        populateModelForRegeneration();

        await courierLookup(true);
      } catch (error) {
        let errorMessage = error.message;
        if (errorMessage === undefined || errorMessage === "") {
          errorMessage =
            "An error occurred while reloading patient and customer data. Please contact your system administrator.";
        }

        NotificationsService.error(
          angular.element(document.getElementById("workflow-api")),
          "Failed loading data",
          errorMessage,
          false,
          15000
        );

        _this.onCancelGeneration();
      } finally {
        _this.loadingMessage = null;
        $scope.$apply();
      }
    }

    async function courierLookup(isFirstLoad) {
      if (
        !_this.selectedStorageRequirement ||
        _this.selectedStorageRequirement === undefined ||
        _this.model.courierOptions.deliveryAddressId === null ||
        _this.selectedAddress === null
      ) {
        return;
      }

      if (
        _this.model.courierOptions.courier !== null &&
        _this.model.courierOptions.courier !== undefined
      ) {
        _this.model.courierOptions.courier = undefined;
        _this.model.courierOptions.courierDetail = "";
        _this.model.courierOptions.serviceLevel = undefined;
        _this.model.courierOptions.otherService = "";
        _this.detailOnlySelected = false;
      }

      const selectedAddress = _this.availableAddresses[_this.selectedAddress];

      if (!selectedAddress.raw) {
        return;
      }

      _this.loadingMessage = "Loading service level data please wait...";

      const postcode = selectedAddress.raw.postCode;

      try {
        const deliveryServices = await ApiCourierDeliveryService.getAvailableCourierDeliveryServices(
          postcode,
          _this.selectedStorageRequirement.id
        );

        _this.availableCourierServices = deliveryServices;
        _this.workflowCourierList = _this.availableCourierServices
          .filter(c => c.availableServices.length > 0)
          .map(c => ({
            id: c.courierId,
            courierName: c.courierName
          }));

        _this.workflowCourierList.push({
          id: _this.workflowCourierEnum.StaffDelivery,
          courierName: "Staff Delivery"
        });
        _this.workflowCourierList.push({
          id: _this.workflowCourierEnum.Other,
          courierName: "Other"
        });

        setSelectedCourierForRegeneration(isFirstLoad);
      } catch (error) {
        let errorMessage = error.message;
        if (errorMessage === undefined || errorMessage === "") {
          errorMessage =
            "An error occurred while retrieving available courier services. Please contact your system administrator.";
        }

        NotificationsService.error(
          angular.element(document.getElementById("workflow-api")),
          "Failed loading courier data",
          errorMessage,
          () => {
            _this.loadingMessage = null;
          },
          false,
          15000
        );

        _this.workflowCourierList = [];
        _this.workflowCourierList.push({
          id: _this.workflowCourierEnum.StaffDelivery,
          courierName: "Staff Delivery"
        });
        _this.workflowCourierList.push({
          id: _this.workflowCourierEnum.Other,
          courierName: "Other"
        });
      } finally {
        _this.loadingMessage = null;
        $scope.$apply();
      }
    }

    function getServiceLevelsForCourier(courierId) {
      const courier = _this.availableCourierServices.find(c => c.courierId === courierId);

      if (courier) {
        return courier.availableServices.map(s => ({
          code: s.code,
          name: s.name
        }));
      } else {
        return [
          {
            code: "Other",
            name: "Other"
          }
        ];
      }
    }

    function handleDeliveryAddressTypeChange(deliveryAddressType) {
      if (deliveryAddressType === 0) {
        _this.availableAddresses = _this.patientAddresses;
      } else {
        _this.availableAddresses = _this.clinicLocations;
      }

      _this.selectedAddress = 0;
      _this.handleUpdateAddress(_this.selectedAddress);
      _this.hasAddresses = _this.availableAddresses.length !== 0;
    }

    function populateModelForRegeneration() {
      if (
        !_this.isRegeneration ||
        _this.task.taskData === null ||
        _this.patientUpdatedEventOccurred
      ) {
        return;
      }

      const taskData = _this.task.taskData.data;

      // Clone without reference and update model
      const reloadedModel = JSON.parse(JSON.stringify(taskData));
      reloadedModel.dateOfDelivery = new Date(_this.task.scheduledDate);
      reloadedModel.shipmentDate = new Date(reloadedModel.shipmentDate);

      if (reloadedModel.plannedDateOfFirstUse) {
        reloadedModel.plannedDateOfFirstUse = new Date(reloadedModel.plannedDateOfFirstUse);
      }

      _this.model = reloadedModel;

      // Clear any (non)compounded specific data if the service data has changed
      if (_this.isCompounded) {
        _this.model.currentStockAtHome = null;
      } else {
        _this.model.plannedDateOfFirstUse = null;
      }

      _this.markAsCompleted = false;

      if (taskData.courierOptions.deliveryAddressType === 1) {
        _this.availableAddresses = _this.clinicLocations;
      }

      const selectedAddressIndex = _this.availableAddresses
        .map(address => address.raw)
        .findIndex(
          e =>
            e.line1 === taskData.courierOptions.deliveryAddress.line1 &&
            e.line2 === taskData.courierOptions.deliveryAddress.line2 &&
            e.line3 === taskData.courierOptions.deliveryAddress.line3 &&
            e.county === taskData.courierOptions.deliveryAddress.county &&
            e.country === taskData.courierOptions.deliveryAddress.country &&
            e.postCode === taskData.courierOptions.deliveryAddress.postCode &&
            e.description === taskData.courierOptions.deliveryAddress.description
        );

      if (selectedAddressIndex > -1) {
        _this.selectedAddress = _this.availableAddresses[selectedAddressIndex].value;
        _this.model.courierOptions.deliveryAddressId =
          _this.availableAddresses[selectedAddressIndex].id;
      } else {
        if (_this.availableAddresses.length == 1) {
          _this.selectedAddress = _this.availableAddresses[0].value;
          _this.model.courierOptions.deliveryAddressId = _this.availableAddresses[0].id;
        } else {
          _this.selectedAddress = null;
          _this.model.courierOptions.deliveryAddressId = null;
        }
      }

      // Set or clear the previously set confirmation number
      if (
        _this.model.confirmationRequired === 2 &&
        !_this.deliverySMSList.map(p => p.value).includes(_this.model.confirmationNumber)
      ) {
        _this.model.confirmationNumber = null;
      }

      // Set the Storage Requirements
      const selectedRequirementIndex = _this.availableStorageRequirements
        .map(storage => storage.id)
        .indexOf(taskData.serviceStorageRequirements);

      if (selectedRequirementIndex > -1) {
        _this.selectedStorageRequirement =
          _this.availableStorageRequirements[selectedRequirementIndex];
        _this.model.serviceStorageRequirements = _this.selectedStorageRequirement.id;
      }

      _this.otherCollectionSelected = !!taskData.courierOptions.otherCollection;
      _this.deliveryChargesSelected = taskData.deliveryCharges.length;

      removeUnavailableAncillariesForRegeneration();
    }

    function removeUnavailableAncillariesForRegeneration() {
      const removeUnavailable = ancillaries => {
        for (var i = ancillaries.length - 1; i >= 0; i--) {
          const billableIsAvailable = !!_this.billableAncils.find(a => a.id === ancillaries[i].id);
          const freeIsAvailable = !!_this.freeAncils.find(a => a.id === ancillaries[i].id);

          if (!billableIsAvailable && !freeIsAvailable) {
            ancillaries.splice(i, 1);
          }
        }
      };

      const ancillaries = _this.model.selectedAncillaries;

      removeUnavailable(ancillaries.consumables);
      removeUnavailable(ancillaries.equipment);
      removeUnavailable(ancillaries.coMeds);
      removeUnavailable(ancillaries.assessments);
    }

    function setSelectedCourierForRegeneration(isFirstLoad) {
      if (
        (!_this.isRegeneration || !isFirstLoad) &&
        !_this.workflowCourierList.find(c => c.courierName === LFE)
      ) {
        return;
      } else if (!_this.isRegeneration || !isFirstLoad) {
        _this.model.courierOptions.courier = _this.workflowCourierList.find(
          c => c.courierName === LFE
        ).id;
        handleUpdateCourier(_this.model.courierOptions.courier, isFirstLoad);
        return;
      }

      const courierOptions = _this.task.taskData.data.courierOptions;
      const selectedCourierIndex = _this.workflowCourierList
        .map(c => c.id)
        .indexOf(courierOptions.courier);

      if (selectedCourierIndex > -1) {
        _this.model.courierOptions.courier = _this.workflowCourierList[selectedCourierIndex].id;

        handleUpdateCourier(_this.model.courierOptions.courier, isFirstLoad);
      }
    }

    function initialiseCourierDataForRegeneration(isFirstLoad) {
      if (!_this.isRegeneration || !isFirstLoad) {
        return;
      }

      const courierOptions = _this.task.taskData.data.courierOptions;

      _this.model.courierOptions.courierDetail = courierOptions.courierDetail;
      _this.model.courierOptions.otherService = courierOptions.otherService;
      _this.model.courierOptions.noConsignmentNeeded = courierOptions.noConsignmentNeeded;

      const selectedLevelIndex = _this.availableServiceLevels
        .map(level => level.code)
        .indexOf(courierOptions.serviceLevel.code);

      if (selectedLevelIndex > -1) {
        _this.model.courierOptions.serviceLevel = _this.availableServiceLevels[selectedLevelIndex];
      }
    }

    function updateDateOfDelivery(dateOfDelivery) {
      _this.stopWorkingDayBefore = false;
      _this.model.dateOfDelivery = dateOfDelivery;
      loadWorkingDayBefore();
      _this.stopWorkingDayBefore = _this.isRegeneration;
    }

    $scope.$watch(
      () => _this.model.confirmationRequired,
      newValue => {
        if (newValue === 2 && _this.preferredSms && !_this.model.confirmationNumber) {
          _this.model.confirmationNumber = _this.preferredSms.value;
        } else if (newValue !== 2) {
          _this.model.confirmationNumber = "";
        }
      }
    );

    $scope.$watch(
      () => _this.model.shipmentDate,
      (newValue, oldValue) => {
        if (newValue !== oldValue) {
          handleCutOffTime();
        }
      }
    );
  },
  template
});
