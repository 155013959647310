import module from "./module";

module.directive("btnDisabled" /* @ngInject */, function() {
  return {
    restrict: "A",
    priority: 100,
    link: function(scope, _, attrs) {
      function updateDisabled(value) {
        attrs.$set("disabled", !!value);

        if (value) {
          attrs.$addClass("btn-disabled");
        } else {
          attrs.$removeClass("btn-disabled");
        }
      }

      scope.$watch(attrs.btnDisabled, updateDisabled);
    }
  };
});
