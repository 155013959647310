import angular from "angular";
import module from "./module";
import { toArray } from "#/utils/enum";
import notificationSeverityEnum from "#/constants/notification-severity";

module.factory("NotificationsService", function($growlNotification) {
  "ngInject";
  var _timeout = 2500,
    _sticky = false;

  var factory = {
    success: function(insertBefore, title, text, onClose, sticky, timeout) {
      return show(
        notificationSeverityEnum.success.cssClass,
        insertBefore,
        title,
        text,
        onClose,
        sticky,
        timeout
      );
    },
    error: function(insertBefore, title, text, onClose, sticky, timeout) {
      return show(
        notificationSeverityEnum.error.cssClass,
        insertBefore,
        title,
        text,
        onClose,
        angular.isDefined(sticky) ? sticky : true,
        timeout
      );
    },
    warning: function(insertBefore, title, text, onClose, sticky, timeout) {
      return show(
        notificationSeverityEnum.warning.cssClass,
        insertBefore,
        title,
        text,
        onClose,
        angular.isDefined(sticky) ? sticky : true,
        timeout
      );
    },
    primary: function(insertBefore, title, text, onClose, sticky, timeout) {
      return show(
        notificationSeverityEnum.primary.cssClass,
        insertBefore,
        title,
        text,
        onClose,
        sticky,
        timeout
      );
    },
    info: function(insertBefore, title, text, onClose, sticky, timeout) {
      return show(
        notificationSeverityEnum.info.cssClass,
        insertBefore,
        title,
        text,
        onClose,
        sticky,
        timeout
      );
    },
    show: function(severity, insertBefore, title, text, onClose, sticky, timeout) {
      var obj = toArray(notificationSeverityEnum).find(_ => _.value === severity);
      if (obj !== null) {
        return factory[obj.text.toLowerCase()](insertBefore, title, text, onClose, sticky, timeout);
      } else {
        return factory.error(
          insertBefore,
          title,
          "Can't find severity=" + severity,
          onClose,
          sticky,
          timeout
        );
      }
    }
  };
  return factory;

  function show(сssClass, insertBefore, title, text, onClose, sticky, timeout) {
    var box = $growlNotification.box(insertBefore, title, text, {
      class: сssClass,
      sticky: angular.isDefined(sticky) ? sticky : _sticky,
      timeout: timeout || _timeout
    });
    if (onClose) {
      box.open().then(function() {
        onClose();
      });
    } else {
      box.open();
    }
    return box;
  }
});
