import module from "../module";
import site from "#/constants/site";

module.factory("TimeoutInterceptorService", TimeoutInterceptorService);
/* @ngInject */
function TimeoutInterceptorService($q) {
  var interceptor = {};

  interceptor.request = function(config) {
    config.timeout = config.timeout || site.timeout || 5000;
    return config || $q.when(config);
  };

  return interceptor;
}
