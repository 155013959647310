import { strictAssign } from '#/utils/object';
import module from '../module';
import template from './password-request.tmpl.html';

module.component('ppmPasswordRequest', {
    bindings: {
        onCancel: '&',
        onSave: '&',
        disclaimerText: '<',
        saving: '<',
    },
    template,
    controller
});

interface IController{
    password: string | null;
    saving: boolean;
    onCancel(): void;
}

function controller(
    this: IController
) {
    'ngInject';
    const vm = this;

    strictAssign(vm, {
        password: null,
        saving: false,
    });
}
