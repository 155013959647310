const DOCUMENT_STATUS = {
  Generated: { text: 'Generated', value: 0 },
  Unidentified: { text: 'Unidentified', value: 1 },

  Unapproved: { text: 'Unapproved', value: 2 },
  Reviewed: { text: 'Requires Review', value: 3 },
  Rejected: { text: 'Rejected', value: 4 },

  Approved: { text: 'Approved - Awaiting Original', value: 5 },

  Closed: { text: 'Processed', value: 6 },

  Original: { text: 'Original', value: 7 } // tech status for status filter compatibility
};

type DOCUMENT_STATUS = typeof DOCUMENT_STATUS;

export default DOCUMENT_STATUS;
