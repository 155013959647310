import module from "../module";
import template from "./language-picker.tmpl.html";

module.directive("languagePicker" /* @ngInject */, function(ApiReferenceDataService) {
  return {
    restrict: "A",
    require: "ngModel",
    replace: true,
    scope: {
      model: "=ngModel"
    },
    template,
    priority: 100,
    link: function($scope) {
      $scope.languagesEnum = [];
      ApiReferenceDataService.getLanguages().then(function(data) {
        $scope.languagesEnum = data;
      });
    }
  };
});
