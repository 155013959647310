const WORKFLOW_COURIER_SERVICE = [
    { value: 0, text: 'All Day' },
    { value: 1, text: 'Pre 1030' },
    { value: 2, text: 'Pre 1200' },
    { value: 3, text: 'AM' },
    { value: 4, text: 'PM' },
    { value: 5, text: 'Saturday' },
    { value: 6, text: 'Two Day' },
    { value: 7, text: 'Ship/Shop' },
    { value: 8, text: 'Other' }
];

type WORKFLOW_COURIER_SERVICE = typeof WORKFLOW_COURIER_SERVICE;

export default WORKFLOW_COURIER_SERVICE;