const BIOMETRICS = [
  {
    value: 0,
    text: 'Height',
    label: 'Height (metres)',
    postfix: 'm',

    snomed_id: '14456009',
    snomed_term: 'Measuring height of patient',
    synonyms: []
  },
  {
    value: 1,
    text: 'Weight',
    label: 'Weight (kilograms)',
    postfix: 'Kg',

    snomed_id: '39857003',
    snomed_term: 'Weighing patient',
    synonyms: ['Body weight measurement', 'Weight measurement', 'Weighing']
  },
  {
    value: 2,
    text: 'DAS 28',
    label: 'Score',
    postfix: '',
    snomed_id: '443728000',
    snomed_term: 'Disease activity score 28',
    synonyms: []
  }
  // { value: 2, text: 'Blood pressure' },
  // { value: 3, text: 'Blood glucose' },
  // { value: 4, text: 'Caloric intake' },
  // { value: 5, text: 'Peak expiratory flow' },
  // { value: 6, text: 'Forced expiratory volume' },
  // { value: 7, text: 'Physical_exercise' },
];

type BIOMETRICS = typeof BIOMETRICS;

export default BIOMETRICS;
