import module from "../module";
import template from "./enum-picker.tmpl.html";

import { RX_MANAGEMENT as rxManagementEnum } from "#/constants/index";

module.directive("rxManagementPicker" /* @ngInject */, function() {
  return {
    restrict: "A",
    require: "ngModel",
    replace: true,
    scope: {
      model: "=ngModel"
    },
    template,
    priority: 100,
    controller: /*@ngInject */ function($scope) {
      var foundEnum;

      $scope.enums = rxManagementEnum;

      foundEnum = rxManagementEnum.find(_ => _.value === $scope.model);
      if (!foundEnum) {
        $scope.model = rxManagementEnum[0].value; //default
      }
    }
  };
});
