const YES_VALUE = 0,
  NO_VALUE = 1,
  NONE = 2;

const YES_NO = [
  { value: YES_VALUE, text: 'Yes' },
  { value: NO_VALUE, text: 'No' }
];

type YES_NO = typeof YES_NO;

export { YES_NO as default, YES_VALUE, NO_VALUE, NONE };
