export default {
  USER_AUDIT: 'User-Audit-',
  CUSTOMER_AUDIT: 'Customer-Audit-',
  DOCUMENT_AUDIT: 'Document-Audit-',
  PATIENT_AUDIT: 'Patient-Audit-',
  PATIENT_ENROLLMENTS_AUDIT: 'Patient-Enrollments-Audit-',
  LAST_UNAUTHORIZED_URL: 'Last-Unauthorized-Url',
  OUTSTANDING_TASKS_FILTER: 'Outstanding-Tasks-Filter',
  SCROLL_TO_ENROLLMENT_ID: 'Scroll-To-Enrollment-Id',
  ACTIVE_PATIENT_TAB: 'Active-Patient-Tab'
};
