import module from "./module";

module.directive("fieldsetUncollapse", function() {
  return {
    restrict: "A",
    scope: false,
    priority: 20,
    template:
      '<div class="container-nested"><div class="twelve columns"><button type="button" ng-click="uncollapse()" class="btn expand-section">Expand This Section</button></div></div>',
    link: function(scope) {
      scope.collapse = scope.collapse || true;
      scope.uncollapse = function() {
        scope.collapse = false;
      };
    }
  };
});
