import angular from "angular";
import module from "../module";
import template from "./date-picker.tmpl.html";

module.directive("partialDatepicker" /* @ngInject */, function($timeout, DateService) {
  var isValid = function(value) {
    return DateService.isValidPartialDate(value);
  };
  var isValidRequired = function(value) {
    return DateService.isFilledPartialDate(value);
  };

  return {
    restrict: "A",
    require: "ngModel",
    replace: true,
    scope: {
      model: "=ngModel",
      minYear: "@minYear",
      maxYear: "@maxYear",
      dpname: "@name",
      onChange: "&",
      maxDate: "@maxDate"
    },
    template,
    priority: 90,
    link: function($scope, _, attrs, ngModelCtrl) {
      var oldMonth, oldYear;
      $scope.model = $scope.model || updateModel();

      $scope.years = DateService.getYears($scope.minYear, $scope.maxYear || $scope.maxDate);
      $scope.months = DateService.getMonths();
      $scope.allowClear = angular.isDefined(attrs.allowClear);

      $scope.updateDate = function() {
        if ($scope.day && $scope.month && $scope.year) {
          var validDate = DateService.getUTCValidDate(
            $scope.day,
            $scope.month,
            $scope.year,
            $scope.minYear,
            $scope.maxYear
          );
          $scope.model = {
            year: validDate.getUTCFullYear(),
            month: validDate.getUTCMonth() + 1,
            day: validDate.getUTCDate()
          };
          updateView();
        } else {
          updateModel();
        }

        // wait for binding changes are propagated
        $timeout().then(_ => $scope.onChange());
      };

      function updateModel() {
        $scope.model = {
          year: $scope.year ? $scope.year : null,
          month: $scope.month ? $scope.month : null,
          day: $scope.day ? $scope.day : null
        };
        return $scope.model;
      }

      function updateView() {
        const isCurrentYear = $scope.year === new Date().getFullYear();

        if (
          angular.isDefined($scope.model) &&
          angular.isObject($scope.model) &&
          Object.keys($scope.model).length !== 0
        ) {
          $scope.year = $scope.model.year || null;
          $scope.month = $scope.model.month || null;
          $scope.day = $scope.model.day || null;
        } else {
          $scope.model = {
            year: null,
            month: null,
            day: null
          };
          $scope.year = null;
          $scope.month = null;
          $scope.day = null;
        }
        if (!angular.equals(oldMonth, $scope.month) || !angular.equals(oldYear, $scope.year)) {
          $scope.days = DateService.getDays(
            $scope.month,
            $scope.year,
            isCurrentYear ? $scope.maxDate : null
          );
          oldMonth = $scope.month;
          oldYear = $scope.year;
        }

        $scope.months = DateService.getMonths(isCurrentYear ? $scope.maxDate : null);
      }

      if (angular.isDefined(attrs.required) || angular.isDefined(attrs.ngRequired)) {
        ngModelCtrl.$parsers.unshift(function(viewValue) {
          ngModelCtrl.$setValidity("required", isValidRequired(viewValue));
          return viewValue;
        });

        ngModelCtrl.$formatters.unshift(function(modelValue) {
          ngModelCtrl.$setValidity("required", isValidRequired(modelValue));
          return modelValue;
        });
      }

      $scope.$watch("model", function(value, oldValue) {
        //eslint-disable-next-line
        if ($scope.model && isValid(value)) {
        } else {
          if (!angular.equals(value, oldValue)) {
            $scope.year = "";
            $scope.month = "";
            $scope.day = "";
            $scope.model = null;
            updateModel();
          }
        }
        updateView();
      });
      $scope.$on("$destroy", function() {
        $scope.model = {};
        $scope.days = undefined;
        $scope.months = undefined;
        $scope.years = undefined;
        oldMonth = undefined;
        oldYear = undefined;
      });

      $scope.onClick_clear = function() {
        $scope.model = {};
      };
    }
  };
});
