const PERSCRIPTION_TYPE = {
  ImageOfOriginalWetSigned: { value: 0, text: 'Image of Original Wet Signed'},
  AdvancedElectronicSignature: { value: 1, text: 'Advanced Electronic Signature' },
  EPMAWetSigned: { value: 2, text: 'EPMA - Wet Signed'},
  EPMAPharmaxoApproved: { value: 3, text: 'EPMA - Pharmaxo Approved'},
  EPMAUnsigned: { value: 4, text: 'EPMA - Unsigned' },
  OriginalWetSigned: { value: 5, text: 'Original Wet Signed' }
};

type PERSCRIPTION_TYPE = typeof PERSCRIPTION_TYPE;

export default PERSCRIPTION_TYPE;
