const PERMISSION = {
  None: 0 << 0,
  Upload: 1 << 0,
  Read: 1 << 1,
  Edit: 1 << 2,
  Admin: 1 << 3,
  SystemRead: 1 << 4,
  SystemEdit: 1 << 5,
  SystemAdmin: 1 << 6,
  Pretend: 1 << 7,
  /* NOTE: SystemOriginalUpload is not used anymore
   * though it is still have some value set at database
   * so this 9th bit is not reusable
   */
  UnusedSystemOriginalUpload: 1 << 8,
  ApproveRx: 1 << 9,
  SystemSuspendedRecordReview: 1 << 10,
  /* As above, not used anymore but may still have some value set in database */
  UnusedCanProcessDeliveries: 1 << 11,
  /* Removed as part of https://trello.com/c/0fpKvcM2/889-remove-ability-to-merge-a-document-when-linking but may still have a value set as above.*/
  UnusedMergeDocuments: 1 << 12
};

type PERMISSION = typeof PERMISSION;

export default PERMISSION;
