import IModuleLazyLoadService from '#/interface/core/service/module-lazy-load-service';
import PERMISSION from '#/constants/permission';

import module from '../../core/index';

module.config(function($stateProvider: ng.ui.IStateProvider) {
  'ngInject';
  $stateProvider
    .state('app.tasks-load', {
      url: '',
      abstract: true,
      resolve: {
        loadTasksDependency(ModuleLazyLoadService: IModuleLazyLoadService) {
          'ngInject';
          return ModuleLazyLoadService.loadTasks();
        }
      }
    })
    .state('tasks', {
      parent: 'app.tasks-load',
      url: '/tasks',
      abstract: true
    })
    .state('tasks.list', {
      url: '',
      views: {
        '@': {
          component: 'ppmTasks'
        }
      },
      data: {
        pageTitle: 'Tasks',
        roles: [PERMISSION.SystemRead, PERMISSION.SystemEdit, PERMISSION.SystemAdmin]
      }
    });
});
