import module from "./module";

module.factory("PageTitleService", function() {
  var title = "Default title",
    separator = " &lsaquo; ",
    suffix = "Pharmaxo",
    fullTitle = "";

  return {
    fullTitle: function() {
      return fullTitle;
    },
    title: function() {
      return title;
    },
    setTitle: function(newTitle) {
      if (newTitle) {
        fullTitle = newTitle + separator + suffix;
        title = newTitle;
      } else {
        fullTitle = suffix;
        title = suffix;
      }
    }
  };
});
