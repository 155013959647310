import module from "../module";
import createPdfjsWrapper from "./pdfjs-angular-wrapper";

module.factory("PdfService", function($q) {
  "ngInject";

  const wrapper = createPdfjsWrapper($q);

  return {
    getDocument: wrapper.getDocument
  };
});
