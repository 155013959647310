const DOCUMENT_KIND = {
  None: createKind(0, 'None'),
  Prescription: createKind(
    1,
    'Prescription',
    'Is this prescription valid?',
    'Check the following features are present before approving this prescription document:'
  ),
  ConsentForm: createKind(
    // not available, exist for support old documents
    2,
    'Consent Form',
    'Is this consent form valid?',
    'Check the following features are present before approving this consent form:'
  ),
  Assessment: createKind(3, 'Assessment'),
  PatientVisitForm: createKind(4, 'Patient Visit Form'),
  HomeRiskAssessment: createKind(5, 'Home Risk Assessment'),
  CompetencyAssessment: createKind(6, 'Competency Assessment'),
  ProofOfDelivery: createKind(7, 'Proof Of Delivery'),
  CombinedPVFAndCompetencyAssessment: createKind(8, 'Combined PVF and Competency Assessment'),
  AncillariesForm: createKind(9, 'Ancillaries Form'),
  RegistrationForm: createKind(
    10,
    'Registration Form',
    'Is this registration form valid?',
    'Check the following features are present before approving this registration form:'
  ),
  ChangeOfDependencyForm: createKind(
    11,
    'Change of Dependency Form',
    'Is this change of dependency form valid?',
    'Check the following features are present before approving this consent form:'
  ),
  MissedInfusionForm: createKind(
    12,
    'Missed Infusion Form',
    'Is this missed infusion form valid?',
    'Check the following features are present before approving this consent form:'
  ),
  PatientInformationForm: createKind(13, 'Patient Information Form'),
  NursingConsentForm: createKind(
    14,
    'Nursing Consent Form',
    'Is this nursing consent form valid?',
    'Check the following features are present before approving this nursing consent form:'
  )
};

type DOCUMENT_KIND = typeof DOCUMENT_KIND;

function isPrescription(kind: number): boolean {
  return kind === DOCUMENT_KIND.Prescription.value;
}

function isPatientVisitForm(kind: number): boolean {
  return kind === DOCUMENT_KIND.PatientVisitForm.value || kind === DOCUMENT_KIND.CombinedPVFAndCompetencyAssessment.value;
}

function isConsentForm(kind: number): boolean {
  return kind === DOCUMENT_KIND.ConsentForm.value;
}

function isRegistrationForm(kind: number): boolean {
  return kind === DOCUMENT_KIND.RegistrationForm.value;
}

function isChangeOfDependencyForm(kind: number): boolean {
  return kind === DOCUMENT_KIND.ChangeOfDependencyForm.value;
}

function isMissedInfusionForm(kind: number): boolean {
  return kind === DOCUMENT_KIND.MissedInfusionForm.value;
}

function isPatientInformationForm(kind: number): boolean {
  return kind === DOCUMENT_KIND.PatientInformationForm.value;
}

function isNursingConsentForm(kind: number): boolean {
  return kind === DOCUMENT_KIND.NursingConsentForm.value;
}

export {
  DOCUMENT_KIND as default,
  isPatientVisitForm,
  isConsentForm,
  isPrescription,
  isRegistrationForm,
  isChangeOfDependencyForm,
  isMissedInfusionForm,
  isPatientInformationForm,
  isNursingConsentForm
};

function createKind(value: number, text: string, title = '', subtitle = '') {
  return {
    value,
    labels: { text, title, subtitle }
  };
}
