export type Func<R> = () => R;
export type Func1<T1, R> = (_1: T1) => R;
export type Func2<T1, T2, R> = (_1: T1, _2: T2) => R;
export type Func3<T1, T2, T3, R> = (_1: T1, _2: T2, _3: T3) => R;
export type Func4<T1, T2, T3, T4, R> = (_1: T1, _2: T2, _3: T3, _4: T4) => R;

export function equals<T>(value: T): Func1<T, boolean> {
  return value2 => value === value2;
}

export function id<T>(value: T): T {
  return value;
}

// tslint:disable-next-line
export function noop() {}

function memoize<P, R>(func: Func1<P, R>): Func1<P, R>;
function memoize<P1, P2, R>(func: Func2<P1, P2, R>): Func2<P1, P2, R>;
function memoize<P1, P2, P3, R>(func: Func3<P1, P2, P3, R>): Func3<P1, P2, P3, R>;
function memoize<P1, P2, P3, P4, R>(func: Func4<P1, P2, P3, P4, R>): Func4<P1, P2, P3, P4, R>;
function memoize(func: (...params: any[]) => any): (..._: any[]) => any {
  let lastParams: any[] = [],
    lastResult: any;

  return (...params: any[]) => {
    const argumentsAreEqual =
      params.length === lastParams.length &&
      params.every((value, index) => value === lastParams[index]);

    if (!argumentsAreEqual) {
      lastParams = params;
      lastResult = func(...params);
    }

    return lastResult;
  };
}

function calcOnReferenceChange<P, R>(getReference: Func<P>, func: Func1<P, R>): Func<R> {
  const memo = memoize(func);
  return () => memo(getReference());
}

export { memoize, calcOnReferenceChange };
