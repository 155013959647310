import angular from "angular";
import module from "./module";

module.factory("ListOperationsService", function() {
  "ngInject";

  return {
    deleteSelectedFromList,
    saveObjectToList,
    editSelectedObject
  };

  function deleteSelectedFromList(
    array,
    valueObject,
    emptyValueObject,
    form,
    selectedForEdit,
    editingState,
    selectedElements
  ) {
    //supports multiple selecting
    var selected = angular.copy(selectedElements);
    var selectedLength = angular.copy(selected.length);
    for (var i = 0; i < selectedLength; i++) {
      selected[i] = Number(selected[i]); //"0" -> 0
      // if we deleting, which editing now => reset form
      if (angular.equals(selectedForEdit, array[selected[i]])) {
        form.$setPristine();
        valueObject = angular.copy(emptyValueObject);
        editingState = false;
        selectedForEdit = null;
      }
    }
    for (var j = selectedLength - 1; j >= 0; j--) {
      // going by desc for evade multi-splicing and addtional controling 'j'
      array.splice(selected[j], 1);
    }
    selectedElements = [];
    return {
      valueObject: valueObject,
      selectedForEdit: selectedForEdit,
      editingState: editingState,
      selectedElements: selectedElements
    };
  }

  function saveObjectToList(
    array,
    valueObject,
    emptyValueObject,
    form,
    selectedForEdit,
    editingState
  ) {
    var obj = angular.copy(valueObject);
    if (editingState) {
      var index = array.findIndex(_ => angular.equals(_, selectedForEdit));
      array[index] = obj;
    } else {
      array.push(obj);
    }
    form.$setPristine();
    var value = angular.copy(emptyValueObject);
    valueObject = value;
    return {
      valueObject: valueObject,
      selectedForEdit: null,
      editingState: false
    };
  }

  function editSelectedObject(array, selectedArray, form) {
    if (selectedArray.length === 0) {
      return false;
    }
    if (form.$dirty && !confirm("You have unsaved changes. Continue?")) {
      return false;
    }

    var selectedIndex = Number(selectedArray[0]);
    var selectedForEdit = angular.copy(array[selectedIndex]);
    var valueObject = angular.copy(selectedForEdit);
    form.$setPristine();
    return {
      valueObject: valueObject,
      selectedForEdit: selectedForEdit
    };
  }
});
