import module from "./module";
import { getStatePermissions } from "#/utils/state-permissions";

module.factory("AuthorizationService", AuthorizationService);
/* @ngInject */
function AuthorizationService($rootScope, $state, $q, PrincipalService) {
  var service = {};
  service.authorize = function() {
    return PrincipalService.identity().then(function() {
      const toState = $rootScope.toState,
        roles = getStatePermissions(toState.name) || (toState.data && toState.data.roles);

      if (!roles || roles.length === 0) {
        return $q.reject("State roles are required");
      }

      if (!PrincipalService.isInAnyRole(roles)) {
        $state.go("account.login");
        return $q.reject("Not Authenticated");
      } else {
        return $q.when(true);
      }
    });
  };
  return service;
}
