const NAME_TITLE = [
  { value: 'Miss', text: 'Miss' },
  { value: 'Mr', text: 'Mr' },
  { value: 'Mrs', text: 'Mrs' },
  { value: 'Ms', text: 'Ms' },
  { value: 'Dr', text: 'Dr' },
  { value: 'Other', text: 'Other…' }
];

type NAME_TITLE = typeof NAME_TITLE;

export default NAME_TITLE;
