import module from "./module";
import { addressToString, isAddressEmpty } from "#/utils/address-helpers";

/* @ngInject */
module.factory("PatientAddressService", [
  "ApiPatientsService",
  function(ApiPatientsService) {
    return {
      getPatientAddresses,
      addressToValueText
    };

    function getPatientAddresses(patientId) {
      return ApiPatientsService.get(patientId).then(res => {
        const patientAddress = res.address;
        const patientAddressEmpty = isAddressEmpty(patientAddress);
        let addresses = [];
        if (!patientAddressEmpty) {
          addresses.push({ id: null, address: patientAddress });
        }
        addresses.push(...res.deliveryAddresses.addresses);

        const convertedAddresses = addressToValueText(addresses, !patientAddressEmpty);
        return { convertedAddresses, hasAddresses: addresses.length !== 0 };
      });
    }

    function addressToValueText(passedInAddresses, containsPatientAddress) {
      if (!passedInAddresses || passedInAddresses.length === 0) {
        return [{ value: 0, text: "No addresses available" }];
      }
      const addresses = [];
      addresses.push(
        ...passedInAddresses.map((a, i) => {
          return {
            value: i,
            id: a.id,
            text: addressToString(a.address, i === 0 && containsPatientAddress),
            raw: a.address,
            specialInstructions: a.address.specialInstructions
          };
        })
      );

      return addresses;
    }
  }
]);
