import module from "./module";
import permission from "#/constants/permission";

module.service(
  "defaultPageService",
  /* @ngInject */
  function(PrincipalService) {
    this.get = function() {
      if (PrincipalService.isInRole(permission.Read)) {
        return "dashboard.main";
      } else if (PrincipalService.isInRole(permission.Upload)) {
        return "documents.upload";
      } else {
        return "account.login";
      }
    };
  }
);
