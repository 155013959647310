const RECEIVED_METHOD = {
  CustomerUpload: { text: 'Customer upload', value: 0 },
  PharmaxoUpload: { text: 'Pharmaxo upload', value: 1 },
  Fax: { text: 'Fax', value: 2 },
  PharmaxoOriginalUpload: { text: 'Original upload', value: 3 },
  SystemGenerated: { text: 'Automatically Generated', value: 4 },
  SplitResult: { text: 'Split Result', value: 5 }
};

type RECEIVED_METHOD = typeof RECEIVED_METHOD;

export default RECEIVED_METHOD;
