const CONTACT_TYPE = [
  { value: 101, label: 'Home', group: 'Phones', text: 'Home phone' },
  { value: 102, label: 'Work', group: 'Phones', text: 'Work phone' },
  { value: 103, label: 'Mobile', group: 'Phones', text: 'Mobile phone' },
  { value: 100, label: 'Other', group: 'Phones', text: 'Other phone' },

  { value: 201, label: 'Twitter', group: 'Socials', text: 'Twitter' },
  { value: 202, label: 'Facebook', group: 'Socials', text: 'Facebook' },
  { value: 203, label: 'LinkedIn', group: 'Socials', text: 'LinkedIn' },
  { value: 204, label: 'GooglePlus', group: 'Socials', text: 'GooglePlus' },
  { value: 200, label: 'Other', group: 'Socials', text: 'Other social' },

  { value: 300, label: 'Email', group: 'Emails', text: 'Email' },
  { value: 400, label: 'Other', group: 'Others', text: 'Other contact' }
];

type CONTACT_TYPE = typeof CONTACT_TYPE;

export default CONTACT_TYPE;
