import module from "../module";
import template from "./image-preview.tmpl.html";

module.component("ppmImagePreview", {
  bindings: {
    imageLink: "<",
    onClickApprove: "&",
    onClickReject: "&",
    canApprove: "&"
  },
  template
});
