import module from "./module";

module.directive("required" /* @ngInject */, function(PageStateService) {
  return {
    restrict: "A",
    scope: false,
    priority: 1,
    require: ["ngModel"],
    link: function(scope, elem, attr, ctrls) {
      var viewState = PageStateService.getCurrentPageState().view,
        parent = elem,
        inputgroup;

      do {
        parent = parent.parent();
      } while (
        parent &&
        !parent.hasClass("input-group") &&
        parent[0] &&
        parent[0].tagName !== "FORM"
      );

      inputgroup = parent.hasClass("input-group") ? parent : false;

      if (viewState || !inputgroup) {
        return;
      }

      scope.$watch(
        function() {
          return ctrls[0].$valid;
        },
        function(value) {
          if (attr.disabled) {
            return;
          }
          if (value || attr.disabled) {
            inputgroup.removeClass("required");
          } else {
            inputgroup.addClass("required");
          }
        }
      );
    }
  };
});
