import angular from "angular";
import { getStatePermissions } from "#/utils/state-permissions";

import { PERMISSION as permission, SITE as site } from "#/constants/index";

import module from "../module";

module.factory("PrincipalService", PrincipalService);

/* @ngInject */
function PrincipalService($q, $sessionStorage, $injector) {
  var _identity,
    _authenticated = false,
    _isPretender = $sessionStorage.pretenderIdentity,
    service = {};

  function isInRole(role, userPermissions) {
    var roles = angular.isNumber(userPermissions)
      ? userPermissions
      : _identity && _identity.roles
      ? _identity.roles
      : false;
    return (roles & role) > 0;
  }

  service.isIdentityResolved = function() {
    return angular.isDefined(_identity);
  };

  service.isAuthenticated = function() {
    return _authenticated;
  };

  service.isPharmaxoUser = function() {
    if (service.isIdentityResolved()) {
      return _identity.customerID === site.pharmaxoCustomerId;
    }
    return false;
  };

  service.isInRole = function(role, userPermissions) {
    // No need in auth
    if (role === true || role === 0) {
      return true;
    }

    if (angular.isString(role)) {
      // checking state
      var state = $injector.get("$state").get(role);
      // if (state.name.endsWith('.**'))
      //     state = undefined

      var permissions = getStatePermissions(role);

      if (permissions) {
        return service.isInAnyRole(permissions, userPermissions);
      } else if (state && state.data && state.data.roles) {
        return service.isInAnyRole(state.data.roles, userPermissions);
      } else if (role in permission && permission.hasOwnProperty(role)) {
        return isInRole(permission[role], userPermissions);
      } else if (state) {
        return true; // Looks like no auth state
      } else {
        throw new TypeError("wrong params isInRole=" + role);
      }
    } else {
      if (!userPermissions && (!_authenticated || !_identity.roles)) {
        return false;
      }
      if (angular.isNumber(role)) {
        return isInRole(role, userPermissions);
      } else {
        throw new TypeError("wrong params isInRole=" + role);
      }
    }
  };

  service.isInAnyRole = function(roles, userPermissions) {
    if (!angular.isArray(roles)) {
      roles = [roles];
    }
    for (var i = 0; i < roles.length; i++) {
      if (service.isInRole(roles[i], userPermissions)) {
        return true;
      }
    }

    return false;
  };

  service.isSystemRole = function() {
    return service.isInAnyRole([
      permission.SystemRead,
      permission.SystemEdit,
      permission.SystemAdmin
    ]);
  };

  // Empty params if Logout
  service.authenticate = function(identity, extend) {
    if (extend) {
      Object.assign(_identity, identity);
    } else {
      _identity = identity;
    }
    _authenticated = _identity !== undefined && _identity.token !== undefined;
    _isPretender = false;

    if (identity) {
      $sessionStorage.identity = _identity;
      if ($sessionStorage.pretenderIdentity) {
        _isPretender = true;
      }
    } else {
      // Clear all. Or uncomment below
      //$sessionStorage.$reset();
      delete $sessionStorage.identity;
      delete $sessionStorage.pretenderIdentity;
      delete $sessionStorage.nextDocumentFilter;
      delete $sessionStorage.nextDeliveryFilter;
    }

    if (service.isPretender()) {
      angular.element(document.body).addClass("pretend");
    } else {
      angular.element(document.body).removeClass("pretend");
    }

    $injector.get("$rootScope").$broadcast("auth-update-navbar");
  };

  service.identity = function(force) {
    var storageIdentity = $sessionStorage.identity;
    if (force === true) {
      _identity = undefined;
    }

    // check and see if we have retrieved the identity data from the server. if we have, reuse it by immediately resolving
    if (angular.isDefined(_identity)) {
      return $q.when(_identity);
    } else if (storageIdentity !== null && typeof storageIdentity !== "undefined") {
      _identity = storageIdentity;
      //return $q.when(_identity);
      if (storageIdentity.token && storageIdentity.id) {
        _authenticated = true;
        return $injector
          .get("ApiUsersService")
          .get(storageIdentity.id)
          .then(function(currentUser) {
            currentUser.roles = currentUser.permissions;
            currentUser.token = storageIdentity.token;
            delete currentUser.permissions;
            service.authenticate(currentUser, false);
            $injector.get("ApiCustomersService").setMyCustomerID(currentUser.customerID);
            return $q.when(_identity);
          });
      }
    }

    _identity = undefined;
    _authenticated = false;
    return $q.when(_identity);
  };

  // Login As User
  service.pretendAsUser = function(userId) {
    if (!service.isAuthenticated()) {
      return $q.reject("You dont have permissions for this operation");
    }
    if (!service.isInRole(permission.SystemAdmin)) {
      return $q.reject("You dont have permissions for this operation");
    }

    $sessionStorage.pretenderIdentity = angular.copy($sessionStorage.identity);

    return $injector
      .get("ApiUsersService")
      .getTokenAs(userId)
      .then(function(data) {
        service.authenticate(
          {
            token: data.token,
            id: data.id
          },
          false
        );
        return service.identity(true);
      });
  };

  // Exit from pretender view
  service.unpretend = function() {
    var pretender = angular.copy($sessionStorage.pretenderIdentity);
    if (pretender == null) {
      return $q.reject("You dont have permissions for this operation");
    }
    delete $sessionStorage.pretenderIdentity;
    service.authenticate(pretender);
    return service.identity(true);
  };

  service.isPretender = function() {
    return _isPretender;
  };

  service.getIdentity = function() {
    if (service.isIdentityResolved()) {
      return _identity;
    }
    return false;
  };

  service.getRoles = function(userPermissions) {
    if (!service.isAuthenticated()) {
      throw new Error("Not authenticated for getting roles");
    }
    var roles = {};
    for (var role in permission) {
      if (permission.hasOwnProperty(role)) {
        roles[role] = service.isInRole(permission[role], userPermissions);
      }
    }
    return roles;
  };

  service.getRolesValue = function(roles) {
    var value = permission.None || 0,
      role;
    for (role in roles) {
      if (roles.hasOwnProperty(role) && roles[role] === true && permission.hasOwnProperty(role)) {
        value |= permission[role];
      }
    }
    return value;
  };

  service.userHasJobTitle = function() {
    return _identity.jobTitle !== null && _identity.jobTitle !== undefined;
  };

  service.userHasSignatureImage = function() {
    return _identity.signatureImage !== null && _identity.signatureImage !== undefined;
  };

  return service;
}
