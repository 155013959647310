import * as angular from 'angular';

angular.module('ppm.dependency', [
  'ui.router',
  'ngStorage',
  'ngSanitize',
  'ngResource',
  'oc.lazyLoad',
  'angularFileUpload',
  'ui.indeterminate',
  'ui.validate'
]);

export default angular.module('ppm.core', ['ppm.dependency']);

angular.element(document).ready(function() {
  angular.bootstrap(document, ['ppm.core', 'ppm.ui.app'], {
    strictDi: true
  });
});
