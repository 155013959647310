import module from "../module";

import { YES_VALUE, NO_VALUE } from "#/constants/list/yes-no";

module.controller("DialogPromptCtrl", function($uibModalInstance, message) {
  "ngInject";
  "use strict";

  this.message = message;

  this.yes = () => $uibModalInstance.close(YES_VALUE);

  this.no = () => $uibModalInstance.close(NO_VALUE);
});
