import module from "../../module";
import template from "./pdf-page-print-header.tmpl.html";

module.component("ppmPdfPagePrintHeader", {
  bindings: {
    document: "<",
    patient: "<"
  },
  controller(PrincipalService) {
    "ngInject";

    const vm = this;

    vm.patientId = function() {
      if (!vm.patient) return;

      const pharmaxoPatientId = "dbc8ab9f-6471-42ef-bcf6-7fea17515378",
        id = vm.patient.patientIDs.iDs.find(_ => _.typeID === pharmaxoPatientId);

      return id ? id.value : "";
    };

    vm.user = PrincipalService.getIdentity();
    vm.time = new Date();
  },
  template
});
