import angular from "angular";
import module from "./module";

import { searchQuery, diffObject } from "#/utils/api";

module.factory("ApiCustomersService", ApiCustomersService);

/*@ngInject*/
function ApiCustomersService(
  $q,
  $cacheFactory,
  ApiResourcesService,
  AncillariesService,
  SortService
) {
  var api = {},
    customer = ApiResourcesService.Customers,
    myCustomerID = "",
    cache = $cacheFactory("myCustomer");

  api.clearEmptyPatientIDs = clearEmptyPatientIDs;

  api.setMyCustomerID = function(customerId) {
    var isAnotherCustomer = cache.get("data") && cache.get("data").customerID !== customerId;
    if (customerId === "" || isAnotherCustomer) {
      cache.remove("data");
    }

    myCustomerID = customerId;
  };

  api.get = function(id) {
    var customerData = cache.get("data");
    if (id === myCustomerID) {
      if (customerData) {
        return $q.when(customerData);
      } else {
        return customer.get({ id: id }, null).$promise.then(function(data) {
          cache.remove("data");
          cache.put("data", data);
          return data;
        });
      }
    }
    return customer.get({ id: id }, null).$promise;
  };

  api.getAllCompactView = function() {
    // TODO: can be improved by load compact view into `select`-s
    return customer
      .query(searchQuery("", 0, 500), null)
      .$promise.then(data =>
        SortService.naturalSorter(customer => customer.accountName)(data.items)
      );
  };

  api.search = function(term, start, count, sortArray, requestId = undefined) {
    var obj = searchQuery(term, start, count, sortArray);
    return customer.query(obj, null).$promise.then(data => {
      data.requestId = requestId;
      return data;
    });
  };

  api.create = function(customerDetails) {
    clearEmptyPatientIDs(customerDetails.patientIdentifierTypes);

    return customer.create(null, customerDetails).$promise;
  };

  api.change = function(customerId, info, oldInfo) {
    const customerDetails = angular.copy(info);
    clearEmptyPatientIDs(customerDetails.patientIdentifierTypes);

    const diff = diffObject(oldInfo, customerDetails, true);
    if (!diff) {
      return $q.resolve(true);
    }

    const result = customer.change({ id: customerId }, diff).$promise;

    if (customerId === myCustomerID) {
      result.then(_ => {
        cache.remove("data");
        cache.put("data", customerDetails);
      });
    }

    return result;
  };

  api.getService = function(customerId, serviceKey) {
    return customer
      .getService({ id: customerId, serviceKey })
      .$promise.then(migrateBillingId)
      .then(trySetNursingRequirements)
      .then(trySetCompoundedProduct);
  };

  api.getServices = function(customerId, services = []) {
    const promises = services.map(_ => api.getService(customerId, _.serviceKey));
    return $q.all(promises);
  };

  api.updateService = function(customerId, serviceData, oldServiceData) {
    var response;
    if (serviceData.isNew) {
      response = addService(customerId, serviceData);
    } else {
      response = updateService(customerId, serviceData, oldServiceData);
    }

    return response.then(function(value) {
      if (customerId === myCustomerID) {
        cache.remove("data");
      }

      return value;
    });
  };

  api.getUnusedPatientIdentifierTypes = function(customerId) {
    var params = {
      customerId: customerId,
      state: "unused"
    };

    return customer.getPatientIdentifierTypes(params).$promise;
  };

  api.convertPlaceholderService = function(data) {
    return customer.convertPlaceholderService(null, data).$promise;
  };

  api.getPlaceholderConvertionStatus = function(params) {
    var ids = {
      customerId: params.customerId,
      placeholderServiceId: params.placeholderServiceId
    };
    return customer.placeholderConvertionStatus(ids).$promise;
  };

  return api;

  //////////////////

  function clearEmptyPatientIDs(patientIdentifierTypes) {
    var i = 0,
      len = patientIdentifierTypes ? patientIdentifierTypes.length : 0;
    for (i; i < len; i++) {
      if (patientIdentifierTypes[i].name === "") {
        patientIdentifierTypes.splice(i, 1);
        i--;
        len--;
      }
    }
  }

  function addService(customerId, serviceData) {
    var customerData = angular.copy(serviceData.customerData);
    if (angular.isObject(customerData.general.ancillariesToBeProvided)) {
      AncillariesService.removeNotProvided(customerData.general.ancillariesToBeProvided);
    }
    return customer
      .addService(
        { id: customerId },
        {
          type: serviceData.key.type,
          name: serviceData.key.name,
          generalData: serviceData.generalData,
          customerData: customerData
        }
      )
      .$promise.then(_ => {
        serviceData.customerData = customerData;
        return serviceData;
      });
  }

  function updateService(customerId, serviceData, oldServiceData) {
    serviceData = trySetCompoundedProduct(serviceData);
    var customerData = angular.copy(serviceData.customerData),
      diff;

    if (angular.isObject(customerData.general.ancillariesToBeProvided)) {
      AncillariesService.removeNotProvided(customerData.general.ancillariesToBeProvided);
    }

    // field may be migrated but not yet stored at server
    // so delete migrated prop to make a diff
    delete customerData.general.billingIdMigrated;
    delete serviceData.customerData.general.billingIdMigrated;
    diff = diffObject(oldServiceData.customerData, customerData, true);
    //compare names
    diff = diff || serviceData.key.name !== oldServiceData.key.name;

    if (!diff) {
      serviceData.customerData = customerData;
      return $q.resolve(serviceData);
    }
    return customer
      .updateService(
        { id: customerId, serviceKey: serviceData.serviceKey },
        // change to `diff` if API can track changes
        {
          name: serviceData.key.name,
          generalData: serviceData.generalData,
          customerData: customerData
        }
      )
      .$promise.then(_ => {
        serviceData.customerData = customerData;
        return serviceData;
      });
  }

  function migrateBillingId(service) {
    var pharmaxoPatientId = "dbc8ab9f-6471-42ef-bcf6-7fea17515378";
    if (!("billingId" in service.customerData.general)) {
      service.customerData.general.billingId = pharmaxoPatientId;
      service.customerData.general.billingIdMigrated = true;
    }

    return service;
  }

  function trySetNursingRequirements(service) {
    if (!("nursingRequirements" in service.customerData)) {
      let delivery = service.customerData.delivery;

      service.customerData.nursingRequirements = {
        trainingRequired: delivery ? delivery.maxNumberOfTrainingVisits !== 0 : false,
        ongoingAdministrationRequired: false,
        phlebotomyServiceRequired: false,
        maximumTrainingVisits: delivery ? delivery.maxNumberOfTrainingVisits : 0,
        nurseVisits: 0,
        visitFrequency: 0
      };
    }

    return service;
  }

  function trySetCompoundedProduct(service) {
    if (!("isCompounded" in service.customerData)) {
      service.customerData.isCompounded = false;
    }
    return service;
  }
}
