import module from "../module";

module.factory("DocumentPopoutService" /* @ngInject */, function($transitions, PopoutService) {
  var service = {},
    documentView = {
      windowParams: (function() {
        var height = "height=" + screen.height,
          width = "width=" + screen.width / 1.5,
          options = "toolbar=0, status=0, location=0, menubar=0, resizable=1, scrollbars=1";
        return [height, width, options].join(", ");
      })(),
      windowName: "documentViewWindow",
      openState: "documents.item.popout.version",
      closeState: "documents.emptypopout",
      unsubscribeForChangeState: null
    };

  service.openDocument = function(document, documentVersion) {
    if (!document.files || document.files.length === 0) {
      throw new Error("Document has no files");
    }

    var version = documentVersion;

    if (documentVersion === undefined) {
      var lastVersion = document.files[document.files.length - 1].version;
      version = lastVersion;
    }

    return service.open(document.id, version);
  };

  service.open = function(documentId, documentVersion) {
    if (PopoutService.isPopoutWindow()) {
      return;
    }
    documentView.unsubscribeForChangeState = $transitions.onSuccess({}, function(trans) {
      const toState = trans.to(),
        isDocumentItemState = [
          "documents.item.edit",
          "documents.item.view",
          "documents.item.trail"
        ].some(_ => _ === toState.name);

      if (!isDocumentItemState) {
        close();
      }
    });

    return PopoutService.open(
      documentView.openState,
      { id: documentId, version: documentVersion },
      documentView.windowName,
      documentView.windowParams,
      { state: documentView.closeState, params: null }
    );
  };

  function close() {
    var unsubscribe = documentView.unsubscribeForChangeState;
    if (unsubscribe) {
      unsubscribe();
    }
    return PopoutService.close(documentView.windowName);
  }

  return service;
});
