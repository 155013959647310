import PERMISSION from '../constants/permission';

type PermisionValue = PERMISSION[keyof PERMISSION];
type Permissions = PermisionValue[] | [true];

const statePermissionsMap = new Map<string, Permissions>(),
  READ_ROLE = [
    PERMISSION.Read,
    PERMISSION.Edit,
    PERMISSION.Admin,
    PERMISSION.SystemRead,
    PERMISSION.SystemEdit,
    PERMISSION.SystemAdmin
  ],
  SYSTEM_READ_ROLE = [PERMISSION.SystemRead, PERMISSION.SystemEdit, PERMISSION.SystemAdmin],
  EDIT_ROLE = [PERMISSION.Edit, PERMISSION.Admin, PERMISSION.SystemEdit, PERMISSION.SystemAdmin],
  SYSTEM_EDIT_ROLE = [PERMISSION.SystemEdit, PERMISSION.SystemAdmin],
  SUSPENDED_RECORD_REVIEW_ROLE = [PERMISSION.SystemSuspendedRecordReview],
  ADMIN_ROLE = [PERMISSION.Admin, PERMISSION.SystemAdmin];

const statePermissions: [string, Permissions][] = [
  ['account.login', [true]],
  ['account.change-password', [true]],
  ['account.logout', [true]],
  ['account.unpretend', [true]],

  ['clinics.management', SYSTEM_READ_ROLE],
  ['clinics.clinicLocations', SYSTEM_READ_ROLE],
  ['clinics.clinicScheduling', SYSTEM_READ_ROLE],

  ['customers.list', SYSTEM_READ_ROLE],
  ['customers.create', [PERMISSION.SystemAdmin]],
  ['customers.item.view', READ_ROLE],
  ['customers.item.edit', ADMIN_ROLE],
  ['customers.item.convert-placeholder', ADMIN_ROLE],
  ['customers.item.trail', SYSTEM_READ_ROLE],

  ['dashboard.main', READ_ROLE],

  ['documents.upload', [PERMISSION.Upload]],
  ['documents.list', READ_ROLE],
  ['documents.item.view', READ_ROLE.concat(PERMISSION.ApproveRx)],
  ['documents.item.edit', SYSTEM_EDIT_ROLE.concat(PERMISSION.ApproveRx)],
  ['documents.item.suspend', SYSTEM_EDIT_ROLE.concat(PERMISSION.ApproveRx)],
  ['documents.item.reject', SYSTEM_EDIT_ROLE.concat(PERMISSION.ApproveRx)],
  ['documents.item.cancel', SYSTEM_EDIT_ROLE.concat(PERMISSION.ApproveRx)],
  ['documents.item.uncancel', SYSTEM_EDIT_ROLE.concat(PERMISSION.ApproveRx)],
  ['documents.item.trail', SYSTEM_READ_ROLE],
  ['documents.item.popout', READ_ROLE],
  ['documents.item.popout.version', READ_ROLE],
  ['documents.item.popout.compare', READ_ROLE],
  ['documents.item.popout.compare.version', READ_ROLE],
  ['documents.item.popout.split', [PERMISSION.Upload]],
  ['documents.emptypopout', READ_ROLE],

  ['patients.list', READ_ROLE],
  ['patients.list.suspended', SUSPENDED_RECORD_REVIEW_ROLE],
  ['patients.create', EDIT_ROLE],
  ['patients.item.view', READ_ROLE],
  ['patients.item.edit', EDIT_ROLE],
  ['patients.item.suspend', EDIT_ROLE],
  ['patients.item.trail', SYSTEM_READ_ROLE],

  ['printforms.item.popout', READ_ROLE],

  ['profile', READ_ROLE],

  ['rx-renewals.list', READ_ROLE],
  ['rx-renewals.list.critical', READ_ROLE],
  ['rx-renewals.list.all', READ_ROLE],
  ['rx-renewals.list.newthisweek', READ_ROLE],

  [
    'users.list',
    [PERMISSION.SystemRead, PERMISSION.SystemEdit, PERMISSION.Admin, PERMISSION.SystemAdmin]
  ],
  ['users.create', ADMIN_ROLE],
  [
    'users.item.view',
    [PERMISSION.SystemRead, PERMISSION.SystemEdit, PERMISSION.Admin, PERMISSION.SystemAdmin]
  ],
  ['users.item.edit', [PERMISSION.SystemEdit, PERMISSION.Admin, PERMISSION.SystemAdmin]],
  ['users.trail', SYSTEM_READ_ROLE]
];

statePermissions.forEach(([state, permissions]) => register(state, permissions));

export function getStatePermissions(state: string): Permissions | undefined {
  return statePermissionsMap.get(state);
}

function register(state: string, permissions: Permissions) {
  if (statePermissionsMap.has(state))
    throw new Error(`permisions for state '${state}' has been already defined`);

  statePermissionsMap.set(state, permissions);
}
