const SEX = [
  { value: 1, text: 'Male' },
  { value: 2, text: 'Female' },
  { value: 0, text: 'Not Known' },
  { value: 3, text: 'Indeterminate', title: 'Unable to be classified as either male or female' }
];

type SEX = typeof SEX;

export default SEX;
