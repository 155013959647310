import { isConsentForm } from "#/constants/document-kind";
import { NONE } from "#/constants/list/yes-no";

import angular from "angular";
import module from "./module";

import { buildUrl } from "#/utils/api";
import { searchQuery, diffObject } from "#/utils/api";

module.factory("ApiDocumentsService", ApiDocumentsService);

/* @ngInject */
function ApiDocumentsService(
  $q,
  $window,
  $filter,
  $sessionStorage,
  FileUploader,
  ApiResourcesService,
  PrincipalService
) {
  var api = {},
    documentApi = ApiResourcesService.Documents;

  let visitTypes;

  api.get = function(id) {
    return documentApi.get({ id: id }, null).$promise.then(function(document) {
      normalizeDocument(document);
      return document;
    });
  };

  api.search = function(params) {
    // var params = {
    //     term: '',
    //     start: 0,
    //     count: 1,
    //     sortArray: [],
    //     status: false,
    //     flag: null,
    //     customerId: false,
    //     patientId: null,
    //     hideExpired: null,
    //     documentKind: null,
    // }

    var obj = angular.extend(
      {},
      searchQuery(params.term || "", params.start || 0, params.count || 1, params.sortArray || []),
      {
        status:
          typeof params.status === "string" || typeof params.status === "number"
            ? params.status
            : undefined
      }
    );

    if (params.hideExpired === true) {
      obj.hideExpired = true;
    }

    if (params.flag) {
      obj.flag = params.flag.value;
    }

    if (params.documentKind != null) {
      obj.documentKind = params.documentKind;
    }

    if (angular.isString(params.customerId)) {
      obj.customerId = params.customerId;
    } else if (angular.isString(params.patientId)) {
      obj.patientId = params.patientId;
    }

    if (params.showGeneratedUnlinked) {
      obj.showGeneratedUnlinked = params.showGeneratedUnlinked;
    }

    if (params.showOriginalOnly) obj.showOriginalOnly = params.showOriginalOnly;

    return documentApi.search(obj, null).$promise.then(result => {
      result.requestId = params.requestId;
      result.items.forEach(normalizeDocument);
      return result;
    });
  };

  api.getPatientDocuments = function(patientId) {
    var UNREALISTIC_PATIENT_DOCUMENT_COUNT = 1000000;

    return api.search({
      patientId: patientId,
      start: 0,
      count: UNREALISTIC_PATIENT_DOCUMENT_COUNT
    });
  };

  api.getFileUploader = function() {
    var availableExtensions = ["TIF", "TIFF", "BMP", "JPG", "JPEG", "PNG", "PDF"],
      maxSize = 1024 * 1024 * 50, //50 Mb
      settings = {
        url: buildUrl("documents"),
        filters: [
          {
            name: "extension",
            fn: function(item) {
              var extension = /[^.]+$/.exec(item.name);
              if (!extension) {
                return false;
              }
              return availableExtensions.indexOf(extension[0].toUpperCase()) !== -1;
            },
            error: "Wrong file type, please add image or pdf"
          },
          {
            name: "filesize",
            fn: function(item) {
              return item.size <= maxSize;
            },
            error:
              "File is too big, supported size <= " +
              $filter("number")(maxSize / 1024 / 1024, 2) +
              "Mb"
          }
        ]
      };

    settings.headers = {
      Authorization: "Token" + PrincipalService.getIdentity().token
    };

    settings.formData = [
      {
        uploader: PrincipalService.getIdentity().id
      }
    ];

    return new FileUploader(settings);
  };

  api.approve = function(documentId) {
    return documentApi.approve({ id: documentId }, null).$promise;
  };

  api.confirmRxWithoutClinicalScreen = (documentId, answer) =>
    documentApi.confirmRxWithoutClinicalScreen({ id: documentId }, { answer }).$promise;

  api.review = function(documentId) {
    return documentApi.review({ id: documentId }, null).$promise;
  };

  api.cancel = function(documentId, reason) {
    return documentApi.cancel({ id: documentId }, { reason: reason }).$promise;
  };

  api.unCancel = function(documentId, reason) {
    return documentApi.unCancel({ id: documentId }, { reason: reason }).$promise;
  };

  function link(document, oldDocument) {
    const patientId = document.linkInfo.patient,
      enrollmentId = document.linkInfo.enrollmentId,
      patientChanged = patientId !== oldDocument.linkInfo.patient,
      enrollmentChanged = enrollmentId !== oldDocument.linkInfo.enrollmentId,
      changed = patientChanged || enrollmentChanged;

    return changed
      ? documentApi.link({ id: document.id }, { patientId, enrollmentId }).$promise
      : $q.resolve();
  }

  function definelifecycle(document, oldDocument) {
    const noop = $q.resolve();

    if (angular.equals(document.lifecycle, oldDocument.lifecycle, true)) return noop;

    const dateOrNull = _ => (angular.isDate(_) ? _ : null),
      from = dateOrNull(document.lifecycle.from),
      to = dateOrNull(document.lifecycle.to),
      renewel = dateOrNull(document.lifecycle.renewel);

    const lifecycle = isConsentForm(document.documentKind) ? { from } : { from, to, renewel };

    return documentApi.definelifecycle({ id: document.id }, lifecycle).$promise;
  }

  function updateDocument(document, oldDocument) {
    document = angular.copy(document);

    delete document.lifecycle;
    delete document.linkInfo;
    delete document.patientName;
    delete document.customerID;

    if (document.documentKind !== 1) {
      delete document.prescriptionData;
      delete document.prescriptionType;
    }

    const diff = diffObject(oldDocument, document, true);

    if (!diff) return $q.resolve();

    if (diff.hasOwnProperty("clinicalScreenConfirmed") && diff.clinicalScreenConfirmed == null)
      diff.clinicalScreenConfirmed = NONE;

    // If only one of date of receipt and time of receipt has changed, we still want to send both.
    if (diff.hasOwnProperty("dateOfReceipt") && !diff.hasOwnProperty("timeOfReceipt")) {
      diff.timeOfReceipt = document.timeOfReceipt;
    }

    if (diff.hasOwnProperty("timeOfReceipt") && !diff.hasOwnProperty("dateOfReceipt")) {
      diff.dateOfReceipt = document.dateOfReceipt;
    }

    return documentApi.change({ id: document.id }, diff).$promise;
  }

  api.notifyMessagesSeen = function(documentId) {
    return documentApi.notifyMessagesSeen({ id: documentId });
  };

  api.change = function(document, oldDocument) {
    return $q
      .resolve()
      .then(() => link(document, oldDocument))
      .then(() => updateDocument(document, oldDocument))
      .then(_ => definelifecycle(document, oldDocument));
  };

  api.getDocumentSplitInfo = function(documentId) {
    return documentApi.getDocumentSplitInfo({ id: documentId }).$promise;
  };

  api.getDocumentPage = function(documentId, pageNumber) {
    return documentApi
      .getDocumentPage({ id: documentId, page: pageNumber })
      .$promise.then(getBlobUrl);

    function getBlobUrl(data) {
      var blob = new Blob([data.buffer], { type: data.type }),
        url = $window.URL.createObjectURL(blob);

      return {
        page: pageNumber,
        image: url,
        destroy: $window.URL.revokeObjectURL.bind($window.URL, url)
      };
    }

    // initial implementation was with data url but it was noticably slow
    // blob url works much faster but is only draft today so data url is preserved

    /*function getDataUrl(data) {
            var url = 'data:' + data.type + ';base64,' + bufferToBase64(data.buffer);
            return {
                page: pageNumber,
                image: url,
                destroy: angular.noop
            };
        }

        function bufferToBase64(buffer) {
            var btoaData;

            btoaData = [].map.call(new Uint8Array(buffer), function (byte) {
                return String.fromCharCode(byte);
            }).join('');

            return $window.btoa(btoaData);
        }*/
  };

  api.splitDocument = function(documentId, pages) {
    return documentApi.splitDocument(
      {
        id: documentId
      },
      {
        pages: pages
      }
    ).$promise;
  };

  api.getDocumentPages = function(documentId) {
    return api.getDocumentSplitInfo(documentId).then(function(info) {
      var i,
        images = [];

      for (i = 0; i < info.numberOfPages; i++) {
        images.push(api.getDocumentPage(documentId, i));
      }

      return $q.all(images);
    });
  };

  api.getNextDocument = function() {
    if (!$sessionStorage.nextDocumentFilter) {
      return $q.resolve(false);
    }

    const searchParams = $sessionStorage.nextDocumentFilter,
      promise = api.search(searchParams);

    promise.then(_ => api.updateNextDocumentFilter(searchParams.start, searchParams));

    return promise.then(_ => _.items[0] || false);
  };

  api.updateNextDocumentFilter = function(index, params) {
    params.start = index + 1;
    params.count = 1;

    $sessionStorage.nextDocumentFilter = params;
  };

  api.getValidationConfig = function() {
    return documentApi.documentValidationConfig().$promise;
  };

  api.suspend = function(documentId, reason) {
    return documentApi.suspend({ id: documentId }, { reason: reason }).$promise;
  };
  api.reject = function(documentId, reason, deadLine) {
    return documentApi.reject({ id: documentId }, { text: reason, deadLine: deadLine }).$promise;
  };

  api.unsuspend = function(documentId) {
    return documentApi.unsuspend({ id: documentId }).$promise;
  };

  api.getPatientVisitFormVisitTypes = function() {
    if (!visitTypes) visitTypes = documentApi.documentVisitTypesConfig().$promise;
    return visitTypes;
  };

  return api;
}

function normalizeDocument(document) {
  if (!document.lifecycle) {
    document.lifecycle = {
      from: null,
      to: null,
      renewel: null,
      linkingMethod: 0
    };
  }

  delete document.status;
}
