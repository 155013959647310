import * as angular from 'angular';

import { strictAssign } from '#/utils/object';

import module from '../module';

module.component('ppmModal', {
  bindings: {
    onClosed: '&'
  },
  transclude: true,
  controller,
  template: ''
});

interface IController extends ng.IOnDestroy {
  setModalElement(element: JQLite): void;
  onClosed(): void;
}

let nextPlaceholderId = 0;

function controller(this: IController, $transclude: ng.ITranscludeFunction, $uibModal: any) {
  'ngInject';

  const vm = this,
    mutationObserver = new MutationObserver(handleMutations),
    placeholderClass = `ppm-modal-placeholder-${nextPlaceholderId++}`;

  strictAssign(vm, {
    $onDestroy
  });

  let disposed = false,
    transcludedContent: JQLite | undefined,
    transclusionScope: ng.IScope | undefined;

  $transclude((clone, scope) => {
    transcludedContent = clone;
    transclusionScope = scope;
  });

  mutationObserver.observe(document.body, {
    childList: true,
    subtree: true
  });

  const uiModal = $uibModal.open({
    template: `<div class="${placeholderClass}"></div>`,
    size: 'custom'
  });

  uiModal.result.then(dispose, dispose);

  function handleMutations(mutations: MutationRecord[]) {
    const addedNodes = ([] as any[])
      .concat(...mutations.map(r => [...r.addedNodes]))
      .filter(_ => _.querySelector != null) as Element[];

    const placeholders = addedNodes
        .map(_ => _.querySelector('.' + placeholderClass))
        .filter(_ => _ != null)
        .reverse() as Element[],
      placeholder = placeholders[0];

    if (placeholder == null || transcludedContent == null) return;

    angular.element(placeholder).empty();
    angular.element(placeholder).append(transcludedContent);
  }

  function dispose() {
    if (disposed) return;

    mutationObserver.disconnect();

    if (transclusionScope) transclusionScope.$destroy();

    vm.onClosed();
    disposed = true;
  }

  function $onDestroy() {
    uiModal.dismiss('destroy');
  }
}
