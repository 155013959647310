import angular from "angular";
import module from "../module";
import template from "./ethnicity-picker.tmpl.html";

module.directive("ethnicityPicker" /* @ngInject */, function(ApiReferenceDataService) {
  return {
    restrict: "A",
    require: "ngModel",
    replace: true,
    scope: {
      model: "=ngModel"
    },
    template,
    priority: 100,
    link: function($scope) {
      $scope.ethnicityEnum = [];
      ApiReferenceDataService.getEthnicities().then(function(data) {
        $scope.ethnicityEnum = data;
      });
      if ($scope.model) {
        $scope.selectedEth = JSON.stringify($scope.model);
      }
      $scope.$watch("model", function() {
        if ($scope.model) {
          $scope.selectedEth = JSON.stringify($scope.model);
        }
      });
      $scope.$watch("selectedEth", function(value) {
        if (angular.isUndefined(value) || angular.isObject(value)) {
          return;
        }
        // from string {"id":"315236000","term":"British","nhsDataDictCode":"A"}
        // to   object {"id":"315236000","term":"British","nhsDataDictCode":"A"}
        // avoid ? object:id ?
        if (angular.isString(value) && value.charAt(0) !== "?") {
          var data = JSON.parse(value);
          if (!angular.equals(data, $scope.model)) {
            $scope.model = data;
          }
        }
      });
    }
  };
});
