const PREFERRED_CONTACT_TIME = [
    { value: 0, text: 'Anytime' },
    { value: 1, text: 'Morning' },
    { value: 2, text: 'Lunchtime' },
    { value: 3, text: 'Afternoon' },
    { value: 4, text: 'Evening' },
    { value: 5, text: 'Weekend' },
    { value: 6, text: 'Other' },
];

type PREFERRED_CONTACT_TIME = typeof PREFERRED_CONTACT_TIME;

export default PREFERRED_CONTACT_TIME;
