const WORKFLOW_COURIER_ENUM = {
    LFAndE: 0,
    Dpd: 1,
    StaffDelivery: 100,
    Other: 101
};

type WORKFLOW_COURIER_ENUM = typeof WORKFLOW_COURIER_ENUM;

export default WORKFLOW_COURIER_ENUM;
