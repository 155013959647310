import module from "../module";

module.factory("AngucompleteService", AngucompleteService);

function AngucompleteService(viewFilter) {
  "ngInject";

  return {
    getAutocompleteNames
  };

  function getAutocompleteNames(names, viewFunction) {
    return names.map(item => {
      return {
        id: item.id,
        nameView: (viewFunction && viewFunction(item)) || viewFilter(item.name, "name"),
        title: item.name.title,
        givenNames: item.name.givenNames.join(" "),
        knownAs: item.name.knownAs,
        surname: item.name.surname,
        suffixes: item.name.suffixes.join(" "),
        name: item.name
      };
    });
  }
}
