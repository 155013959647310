import angular from "angular";
import module from "./module";

import { buildUrl } from "#/utils/api";

module.factory("AuthorizationInterceptorService", AuthorizationInterceptorService);
/* @ngInject */
function AuthorizationInterceptorService($rootScope, $q, $injector, PrincipalService) {
  var interceptor = {};

  interceptor.request = function(config) {
    config.headers = config.headers || {};
    var token = PrincipalService.getIdentity().token;
    if (angular.isUndefined(config.headers.Authorization) && config.method !== "OPTIONS" && token) {
      config.headers.Authorization = "Token" + token;
    }
    return config || $q.when(config);
  };

  interceptor.requestError = function(rejection) {
    // do something on error
    return $q.reject(rejection);
  };

  interceptor.response = function(response) {
    if (response.status === 401) {
      $rootScope.$broadcast("user-logout");
    }
    return response || $q.when(response);
  };

  interceptor.responseError = function(rejection) {
    var url = (rejection.config && rejection.config.url) || "";
    if (url.match(buildUrl("status"))) {
      //startup status has its own error handling
      return;
    }

    var elem = angular.$(document).find("fieldset");
    if (elem.length === 0) {
      elem = angular.$(document).find("table");
    }
    if (elem.length === 0) {
      elem = angular.$(document).find(".subnav-container + *");
    }
    if (elem.length === 0) {
      elem = angular.$(document).find("#mainview");
    }
    switch (rejection.status) {
      case 0:
        //No connection to API
        if (rejection.config.url.indexOf("notification") !== -1) {
          break;
        }
        $rootScope.error = rejection.statusText = "No connection to API or Connection is refused";
        $rootScope.$broadcast("user-logout");
        break;

      case 401:
        //Unauthorized
        $rootScope.$broadcast("user-logout");
        break;

      case 403:
        //Forbidden
        $rootScope.$broadcast("user-logout");
        break;

      case 503:
        //Unavailable
        if (rejection.config.url.match(buildUrl(""))) {
          $rootScope.error = "No connection to API or Connection is refused";
          $rootScope.$broadcast("user-logout");
        } else {
          if (elem.length !== 0) {
            if (!rejection.config.url.match(buildUrl("address", "reference")))
              $injector
                .get("NotificationsService")
                .error(elem, "", rejection.statusText, false, false, 10000);
          } else {
            alert(rejection.statusText);
          }
        }
        break;

      default:
        if (elem.length !== 0) {
          if (url.match("importablePatients")) {
            //patient import has its own error handling
            break;
          }

          const ignoreError =
            url.match(buildUrl("login")) ||
            url.match(buildUrl("account-info")) ||
            (url.match("clinics/.*/schedule/.*") && rejection.status === 404);

          if (!ignoreError) {
            $injector
              .get("NotificationsService")
              .error(elem, "An error occurred", rejection.statusText, false, false, 10000);
          }
        } else {
          alert(rejection.statusText);
        }
        break;
    }

    return $q.reject(rejection);
  };
  return interceptor;
}
