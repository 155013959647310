import angular from "angular";

import {
  CONTACT_TYPE as contactTypeEnum,
  DOCUMENT_STATUS,
  BIOMETRICS,
  HEALTHCARE_PROFESSIONAL,
  PATIENT_NOTE_COMMUNICATION_SOURCE,
  PATIENT_ENROLLMENT_STATES,
  PATIENT_ENROLLMENT_SUSPENSION_STATUS,
  PATIENT_NOTE_TYPE,
  RECEIVED_METHOD,
  DOCUMENT_KIND,
  CARE_SERVICE_TYPE,
  OBJECT_VIEW as objectView,
  RISK
} from "#/constants/index";
import { toArray } from "#/utils/enum";
import { getDocumentStatus, getDocumentKindCapabilities } from "#/utils/ppm-document";

import module from "./module";

module
  .filter("joinBy", function() {
    return function(input, delimiter) {
      return (input || [])
        .filter(function(item) {
          return angular.isDefined(item) && item !== "" && item !== null;
        })
        .join(delimiter || ",");
    };
  })
  .filter("map", function() {
    return function(input, propName) {
      return input.map(function(item) {
        return item[propName];
      });
    };
  })
  .filter("confirmationEmailMap", function() {
    return function(input, email, isConfirmationEmail) {
      return input.map(function(item) {
        let address = item[email];
        if (item[isConfirmationEmail] === true) {
          address += " (Confirmation)";
        }
        return address;
      });
    };
  })
  .filter(
    "filterByUserStatus",
    /* @ngInject */ function(PrincipalService) {
      return function(input) {
        var x = input
          ? input.filter(function(item) {
              switch (item.status) {
                case 0: //enable
                case 1: //disable
                  return true;
                case 2:
                case 3: //deleted
                  return PrincipalService.isInRole("SystemAdmin");
                default:
                  break;
              }
              return false;
            })
          : [];
        return x;
      };
    }
  )
  .filter(
    "firstOrDefault",
    /* @ngInject */ function($injector) {
      return function(input, values, key, deff) {
        key = key || "value";
        if (angular.isString(values)) {
          values = $injector.get(values);
        }
        var result = [],
          index,
          obj;
        if (angular.isArray(input)) {
          input.forEach(function(item) {
            index = indexFindByKey(values, key, item[key]);
            if (index === -1) {
              result.push(deff);
            } else {
              result.push(values[index]);
            }
          });
          return result;
        } else {
          obj = objectFindByKey(values, key, input);
          if (obj === null) {
            return deff;
          }
          return obj;
        }
      };
    }
  )
  .filter("prop", function() {
    return function(input, propName) {
      if (!angular.isDefined(input) || !input) {
        return "";
      }
      return input[propName];
    };
  })
  .filter(
    "view",
    /* @ngInject */ function($interpolate) {
      return function(input, viewProp) {
        if (!objectView[viewProp]) {
          return "ViewNotFound";
        }
        if (input === null || angular.isUndefined(input)) {
          return "";
        }
        var exp = $interpolate(objectView[viewProp]);
        var str = exp(input);
        return str;
      };
    }
  )
  .filter("filterCategoryWithItems", function() {
    return function(categories, present, arrayField) {
      arrayField = arrayField || "items";
      return categories.filter(function(category) {
        if (present) {
          return category[arrayField] !== undefined;
        } else {
          return !category[arrayField]; // === undefined;
        }
      });
    };
  })
  .filter(
    "findContactInfo",
    /* @ngInject */ function() {
      return function(input, group) {
        var obj;
        switch (group.toLowerCase()) {
          case "phones":
            obj = findContactType(100);
            break;
          case "socials":
            obj = findContactType(200);
            break;
          case "emails":
            obj = findContactType(300);
            break;
          case "others":
            obj = findContactType(400);
            break;
          default:
            obj = null;
            break;
        }
        if (obj === null) alert("findContactInfo can't find {" + input.type + "," + group + "}");
        return obj;

        function findContactType(offset) {
          return contactTypeEnum.find(_ => _.value === Number(input.type) + offset);
        }
      };
    }
  )
  .filter("contactInfoLabel", function() {
    // var filter = $filter('findContactInfo');
    return function(input) {
      var arr = [];
      for (var i in input) {
        for (var j in input[i]) {
          if (input.hasOwnProperty(i) && input[i].hasOwnProperty(j)) {
            arr.push(input[i][j].value);
          }
        }
      }
      return arr.join(", ");
    };
  })
  .filter(
    "age",
    /* @ngInject */ function(DateService) {
      var today = new Date();
      return function(birthDate) {
        return DateService.getAge(birthDate, today);
      };
    }
  )
  .filter("customerPatientIDs", function() {
    return function(inputArrayIds) {
      var arr = [];
      for (var i in inputArrayIds) {
        if (!inputArrayIds.hasOwnProperty(i)) continue;

        if (inputArrayIds[i].primary === true) {
          arr.unshift("<b>" + inputArrayIds[i].name + "</b>");
        } else {
          arr.push(inputArrayIds[i].name);
        }
      }
      return arr.join(", ");
    };
  })
  .filter(
    "partialdate",
    /* @ngInject */ function(DateService) {
      return function(d) {
        if (!d) {
          return "";
        }

        if (angular.isDate(d)) {
          d = DateService.getUTCBackwardCompatibleDate(d);

          d = {
            year: d.getUTCFullYear(),
            month: d.getUTCMonth() + 1,
            day: d.getUTCDate()
          };
        }

        if (!d.day && !d.month && !d.year) {
          return "";
        }
        var separator = "/",
          empty = "--",
          arr = [];

        angular.forEach([d.day, d.month, d.year], function(item) {
          if (item >= 1 && item <= 9) {
            arr.push("0" + item);
          } else {
            arr.push(item || empty);
          }
        });
        return arr.join(separator);
      };
    }
  )
  .filter(
    "daterange",
    /* @ngInject */ function($filter) {
      return function(c, startProp, endProp) {
        if (!c) return "";
        var start = $filter("partialdate")(c[startProp || "start"]);
        var end = $filter("partialdate")(c[endProp || "end"]);
        var string = [];

        if (start) {
          string.push(start);
        }

        if (end) {
          string.push(" — " + end);
        }
        return string.join("");
      };
    }
  )
  .filter(
    "condition",
    /* @ngInject */ function($filter) {
      return function(c) {
        if (!c) return "";
        var range = $filter("daterange")(c);
        var result = c.snomedTerm.term;

        if (range) {
          result += ", " + range;
        }

        return result;
      };
    }
  )
  .filter(
    "medication",
    /* @ngInject */ function($filter) {
      return function(m) {
        if (!m) return "";
        return $filter("condition")({ snomedTerm: m.drug, start: m.start, end: m.end });
      };
    }
  )
  .filter(
    "redirectLink",
    /* @ngInject */ function() {
      return function(data, state, text, prefix, suffix) {
        if (!data || !text || !state) return "";
        prefix = prefix || "";
        suffix = suffix || "";

        if (angular.isString(data)) {
          data = data.replace(/(^"*)|("*$)/g, "");
          data = { id: data };
        }

        return (
          prefix +
          "<a ui-sref='" +
          state +
          "(" +
          JSON.stringify(data) +
          ")'>" +
          text +
          "</a>" +
          suffix
        );
      };
    }
  )
  .filter("gpDetails", function() {
    var fake = "Not applied";
    return function(data) {
      var arr = [];
      if (data && data.gpPractice && data.gpPractice.code && data.gpPractice.name) {
        arr.push("GP Practice: ");
        arr.push(data.gpPractice.name);
        arr.push(", GP Practitioner: ");
        if (data.registeredGp && data.registeredGp.code && data.registeredGp.name) {
          arr.push(data.registeredGp.name);
        } else {
          arr.push(fake);
        }
      } else {
        arr.push(fake);
      }
      return arr.join("");
    };
  })
  .filter(
    "enumSafe",
    /* @ngInject */ function() {
      return function(input, enumeration) {
        var obj, valueField;

        valueField = "value";
        obj = objectFindByKey(enumeration, valueField, input);
        if (obj === null) {
          valueField = "type";
          obj = objectFindByKey(enumeration, valueField, input);
        }

        if (obj === null) {
          obj = {};
          obj.text = "";
          obj[valueField] = -1;
        }
        return obj;
      };
    }
  )
  .filter(
    "enumTextSafe",
    /* @ngInject */ function(enumSafeFilter) {
      return function(input, enumeration) {
        return enumSafeFilter(input, enumeration).text;
      };
    }
  )
  .filter(
    "documentStatus",
    /* @ngInject */ function(enumTextSafeFilter) {
      return function(document) {
        const status = getDocumentStatus(document);
        return enumTextSafeFilter(status, DOCUMENT_STATUS);
      };
    }
  )
  .filter(
    "document",
    /* @ngInject */ function(documentKindFilter, partialdateFilter) {
      return function(document) {
        return `[${documentKindFilter(document)}] Created: ${partialdateFilter(
          document.createTime
        )}`;
      };
    }
  )
  .filter(
    "measurement",
    /* @ngInject */ function(partialdateFilter) {
      return function(measurement) {
        const measurementUnit = BIOMETRICS.find(_ => _.snomed_id === measurement.type.id),
          measurementText = measurementUnit ? measurementUnit.text : "",
          measurementPostfix = measurementUnit ? measurementUnit.postfix : "";
        return `${measurementText}: ${measurement.value} ${measurementPostfix} (${partialdateFilter(
          measurement.measured
        )})`;
      };
    }
  )
  .filter(
    "healthcareProfessional",
    /* @ngInject */ function(viewFilter) {
      return function(item) {
        const healthcareProfessional = HEALTHCARE_PROFESSIONAL.find(_ => _.value === item.type);
        return `${viewFilter(item.name, "name")}, ${
          healthcareProfessional ? healthcareProfessional.text : ""
        }`;
      };
    }
  )
  .filter(
    "patientNoteCommunicationSource",
    /* @ngInject */ function() {
      return function(communicationSource) {
        const noteCommunicationSource = PATIENT_NOTE_COMMUNICATION_SOURCE.find(
          _ => _.value === communicationSource
        );
        return `${noteCommunicationSource ? noteCommunicationSource.text : "UNKNOWN"}`;
      };
    }
  )
  .filter(
    "sensitivity",
    /* @ngInject */ function(partialdateFilter) {
      return function(sensitivity) {
        const risk = RISK.find(_ => _.value === sensitivity.risk);
        return `${sensitivity.snomedTerm.term}, ${risk ? risk.text : ""}, (${partialdateFilter(
          sensitivity.discovered
        )})`;
      };
    }
  )
  .filter(
    "patientNoteType",
    /* @ngInject */ function() {
      return function(noteType) {
        const type = PATIENT_NOTE_TYPE.find(_ => _.value === noteType);
        return `${type ? type.text : "UNKNOWN"}`;
      };
    }
  )
  .filter(
    "receivedMethod",
    /* @ngInject */ function() {
      return receivedMethod =>
        toArray(RECEIVED_METHOD)
          .filter(_ => _.value === receivedMethod)
          .map(_ => _.text)[0] || "";
    }
  )
  .filter(
    "documentKind",
    /* @ngInject */ function() {
      return function(doc) {
        const anaphylaxis =
            doc.isAnaphylaxis && getDocumentKindCapabilities(doc.documentKind).hasAnaphylaxisFlag
              ? " (Anaphylaxis)"
              : "",
          documentKind = toArray(DOCUMENT_KIND)
            .filter(_ => _.value === doc.documentKind)
            .map(_ => _.labels.text)[0];

        return documentKind + anaphylaxis || "";
      };
    }
  )
  .filter(
    "enrollment",
    /* @ngInject */ function() {
      return enroll => {
        if (!enroll) return;

        const type = CARE_SERVICE_TYPE.find(_ => _.type === enroll.key.type).directive,
          name = enroll.key.name ? " - " + enroll.key.name : "",
          onHold = enroll.isOnHold && !enroll.isStopped ? "On Hold" : "",
          stopped = enroll.isStopped ? "Stopped" : "",
          suspensionStatus = PATIENT_ENROLLMENT_SUSPENSION_STATUS.find(
            _ => _.suspensionStatus === enroll.suspensionStatus
          ),
          status =
            suspensionStatus && enroll.suspensionStatus !== 0
              ? suspensionStatus.title + " - " || "Unknown Status"
              : "",
          state =
            enroll.state != null
              ? PATIENT_ENROLLMENT_STATES.find(_ => _.state === enroll.state).title ||
                "Unknown State"
              : "",
          dash = (enroll.isStopped || enroll.isOnHold) && enroll.state != null ? " - " : "";
        return `${type} ${name} (${onHold}${stopped}${status}${dash}${state})`;
      };
    }
  )
  .filter(
    "serviceName",
    /* @ngInject */ function() {
      return service => {
        const careServiceType = CARE_SERVICE_TYPE.find(_ => _.type === service.key.type),
          serviceName = careServiceType ? careServiceType.directive : "",
          keyName = service.key.name ? " - " + service.key.name : "";
        return `${serviceName}${keyName}`;
      };
    }
  )
  .filter(
    "addressLines",
    /* @ngInject */ function() {
      return function(input) {
        if (typeof input !== "object") {
          return [];
        }

        return [
          input.description,
          input.line1,
          input.line2,
          input.line3,
          input.city,
          input.county,
          input.postCode,
          input.country
        ].filter(angular.identity);
      };
    }
  )
  .filter(
    "fulldatetime",
    /* @ngInject */ function() {
      return function(d) {
        if (!d) {
          return "";
        }

        if (!angular.isDate(d)) {
          return "";
        }

        return `${d.toLocaleDateString()} ${d.toLocaleTimeString()}`;
      };
    }
  );

function indexFindByKey(array, key, value) {
  return array.findIndex(_ => angular.equals(_[key], value));
}

function objectFindByKey(input, key, value) {
  if (angular.isArray(input)) {
    var index = indexFindByKey(input, key, value);
    if (index !== -1) {
      return angular.copy(input[index]);
    }
  } else {
    if (angular.isObject(input)) {
      const fields = toArray(input),
        index = indexFindByKey(fields, key, value);

      if (index >= 0) return fields[index];
    }
  }
  return null;
}
