import module from "./module";

module.service("LifecycleService", function($q) {
  "ngInject";

  this.directiveDestroyed = function(scope) {
    var deferred = $q.defer();
    scope.$on("$destroy", function() {
      deferred.resolve("scope destroyed");
    });

    return deferred.promise;
  };
});
