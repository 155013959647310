import module from "../../module";
import template from "./pdf-viewer-print.tmpl.html";

import "./pdf-page-print";
import "./pdf-page-print-header";

module.component("ppmPdfViewerPrint", {
  bindings: {
    document: "<",
    patient: "<",
    version: "<",
    onRender: "&"
  },
  controller,
  template
});

function controller($q, PdfService, ApiPdfService, ApiDocumentsService, ApiPatientsService) {
  "ngInject";

  const vm = this;

  let unloadPdf = _ => _,
    renderedPageCount = 0;

  vm.pages = [];

  vm.$onChanges = function() {
    if (vm.version == null) {
      return;
    }

    unloadPdf();

    vm.pages = [];

    const pdfPromise = ApiPdfService.getPdfArraybuffer(pdfId()).then(data =>
        PdfService.getDocument(data)
      ),
      annotationsPromise = ApiPdfService.getAnnotations(pdfId());

    function updateDocumentAndPatient() {
      const documentPromise = ApiDocumentsService.get(vm.document.id);

      return documentPromise.then(document => {
        vm.document = document;

        const patientId = document.linkInfo.patient;
        if (!patientId) {
          vm.patient = undefined;
          return;
        }

        return ApiPatientsService.get(patientId).then(patient => {
          vm.patient = patient;
        });
      });
    }

    $q.all([pdfPromise, annotationsPromise, updateDocumentAndPatient()]).then(
      ([pdf, annotations]) => {
        handlePdfLoaded(pdf, annotations);
      }
    );
  };

  vm.$onDestroy = function() {
    unloadPdf();
  };

  vm.handlePageRender = function() {
    ++renderedPageCount;
    if (renderedPageCount === vm.pages.length) {
      vm.onRender();
    }
  };

  function handlePdfLoaded(pdf, allAnnotations) {
    sortAnnotations(allAnnotations);
    unloadPdf = _ => pdf.destroy();

    const pageCount = pdf.numPages,
      pagePromises = "."
        .repeat(pageCount)
        .split("")
        .map((_, index) => index + 1)
        .map(i => pdf.getPage(i));

    $q.all(pagePromises).then(
      pages =>
        (vm.pages = pages.map(page => {
          const annotations = allAnnotations.filter(a => a.page === page.pageIndex);
          return { page, annotations };
        }))
    );
  }

  function sortAnnotations(annotations) {
    annotations.sort((a1, a2) => a1.y - a2.y);
  }

  function pdfId() {
    return {
      documentId: vm.document.id,
      version: vm.version
    };
  }
}
