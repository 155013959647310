import module from "../module";

module.controller("DialogInformationCtrl", function($uibModalInstance, message) {
  "ngInject";
  "use strict";

  this.message = message;

  this.close = function() {
    $uibModalInstance.close();
  };
});
