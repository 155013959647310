import module from "./module";

/*
There are controls with forms that may be repeated in one window (e.g. patient enrollment).
So there is problem with field naming - when user hit label in one control appropriate field
    is activated in other one due to name collision.
RecyclingIdService is supposed to help to resolve this problem.
Also to facilitate testing with protractor ids are recycled to produce same ids every time same view is navigated
*/

module.factory("RecyclingIdService" /* @ngInject*/, function() {
  var ids = [];

  return {
    scopedId: scopedId
  };
  //-----------------

  function acquire() {
    var cachedId;

    cachedId = ids.filter(function(v) {
      return v.released === true;
    })[0];

    if (!cachedId) {
      cachedId = {
        id: ids.length
      };

      ids.push(cachedId);
    }

    cachedId.released = false;
    return cachedId.id;
  }

  function release(id) {
    var cachedId = ids[id];
    if (!cachedId) {
      throw new Error("bad id supplied: id = " + id);
    }

    cachedId.released = true;
  }

  function scopedId(scope) {
    var id;

    id = acquire();
    scope.$on("$destroy", release.bind(null, id));
    return id;
  }
});
