import module from "../module";
import template from "./table-loading.tmpl.html";

module.directive("tableLoading" /* @ngInject */, function($timeout) {
  return {
    restrict: "A",
    replace: true,
    scope: {
      loading: "=tableLoading",
      debounce: "=?tableDebounce"
    },
    template,
    link: function(scope) {
      var debounce;
      scope.show = false;
      scope.$watch("loading", function(newValue) {
        if (newValue === undefined) {
          return;
        }
        $timeout.cancel(debounce);
        if (newValue === true) {
          debounce = $timeout(function() {
            scope.show = true;
          }, scope.debounce || 200);
        } else {
          scope.show = false;
        }
      });
    }
  };
});
