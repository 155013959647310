import module from "../module";
import template from "./test-version-warning.tmpl.html";

module.directive("testVersionWarning" /* @ngInject */, function() {
  return {
    restrict: "A",
    replace: true,
    scope: {},
    template,
    priority: 100,
    controller: /* @ngInject */ function($scope, $window) {
      var location = $window.location,
        origin =
          location.protocol + "//" + location.hostname + (location.port ? ":" + location.port : "");

      $scope.isTestVersion = origin.toLowerCase() !== "https://secure.pharmaxo.com";
    }
  };
});
