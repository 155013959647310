import module from "./module";

/* @ngInject */
module.factory("PhoneCallService", [
  "viewFilter",
  "ApiPatientsService",
  function(viewFilter, ApiPatientsService) {
    return {
      handlePhoneCall,
      formatPhone
    };

    function handlePhoneCall(contactInfo, id, calleeName) {
      ApiPatientsService.notifyCallAttempt({
        id: id,
        phone: formatPhone(contactInfo.value),
        calleeName: viewFilter(calleeName, "name")
      });
    }

    function formatPhone(phone) {
      return phone.replace(/[^+\d]/g, "");
    }
  }
]);
