/**
 * It is common case to not show errors until user ends input or field is not changed
 * so this utility facilitates implementation of such behavior
 */
export function fieldError(ctrl: ng.INgModelController): any {
  const $touched = ctrl.$dirty && ctrl.$touched,
    $valid = !$touched || ctrl.$valid,
    result = {
      $valid,
      $invalid: !$valid,
      $touched,
      $untouched: !$touched
    };

  return $valid ? result : Object.assign(result, ctrl.$error);
}
