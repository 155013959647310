const TASK_DATA_TYPE = {
    DispenseAndDeliverWorkflow: 0,
    ClinicalTrialWorkflow: 1,
    PatientVisitFormFpn37: 2,
    DidNotAttendDataRecorded: 3,
    SelfServiceResponse: 4,
    PatientVisitFormFpn77: 5,
    PatientVisitFormFpn96: 6,
};

type TASK_DATA_TYPE = typeof TASK_DATA_TYPE;

export default TASK_DATA_TYPE;
