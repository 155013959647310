import angular from "angular";
import module from "./module";

module.factory("ApiReferenceDataService", ApiReferenceDataService);

/* @ngInject */
function ApiReferenceDataService($q, $cacheFactory, ApiResourcesService) {
  var cache = $cacheFactory("referenceDataCache"),
    api = {};

  function fixSnomedConceptId(snomedIDs) {
    if (angular.isArray(snomedIDs)) {
      for (var i = 0, len = snomedIDs.length; i < len; i++) {
        snomedIDs[i].id = snomedIDs[i].snomed_concept_id;
        delete snomedIDs[i].snomed_concept_id;
      }
    }
    return snomedIDs;
  }

  api.getLanguages = function() {
    var languages = cache.get("languages");
    if (angular.isArray(languages)) {
      return $q.when(languages);
    } else {
      return ApiResourcesService.Languages.query().$promise.then(function(data) {
        cache.put("languages", data);
        return data;
      });
    }
  };

  api.getEthnicities = function() {
    var ethnicities = cache.get("ethnicities");
    if (angular.isArray(ethnicities)) {
      return $q.when(ethnicities);
    } else {
      return ApiResourcesService.Ethnicities.query().$promise.then(function(data) {
        cache.put("ethnicities", data);
        return data;
      });
    }
  };

  api.getPractices = function(prefix) {
    return ApiResourcesService.Practices.query({ prefix: prefix }, null).$promise;
  };

  api.getPractitioners = function(practice, prefix) {
    return ApiResourcesService.Practitioners.query({ prefix: prefix, practice: practice }, null)
      .$promise;
  };

  api.getAddressByTerm = function(term) {
    return ApiResourcesService.Addresses.query({ term: term }, null).$promise;
  };

  api.getAddressById = function(id) {
    return ApiResourcesService.Addresses.query({ id: id }, null).$promise;
  };

  api.getAllergies = function(prefix) {
    return ApiResourcesService.Allergies.query({ prefix: prefix }, null).$promise.then(function(
      data
    ) {
      return fixSnomedConceptId(data);
    });
  };

  api.getConditions = function(prefix) {
    return ApiResourcesService.Conditions.query({ prefix: prefix }, null).$promise.then(function(
      data
    ) {
      return fixSnomedConceptId(data);
    });
  };

  api.getDrugs = function(prefix) {
    return ApiResourcesService.Drugs.query({ prefix: prefix }, null).$promise.then(function(data) {
      return fixSnomedConceptId(data);
    });
  };

  api.getDiagnoses = function() {
    const diagnoses = cache.get("diagnoses");
    if (angular.isArray(diagnoses)) {
      return $q.when(diagnoses);
    } else {
      return ApiResourcesService.Diagnoses.query().$promise.then(function(data) {
        const restructuredDiagnoses = fixSnomedConceptId(data);
        cache.put("diagnoses", restructuredDiagnoses);
        return restructuredDiagnoses;
      });
    }
  };

  return api;
}
