import module from "./module";
import { buildUrl } from "#/utils/api";

module.factory("ApiWorkingDayService", ApiWorkingDayService);

/*@ngInject*/
function ApiWorkingDayService($http) {
  var api = {};

  api.getWorkingDayBefore = function(daysBefore, target) {
    return $http
      .get(buildUrl("workingDaysBefore", "reference"), {
        params: { daysBefore, target }
      })
      .then(_ => new Date(_.data + "Z"));
  };

  return api;
}
