import angular from "angular";
import module from "../module";
import template from "./paginate.tmpl.html";

module.directive("paginate", function() {
  return {
    restrict: "A",
    replace: true,
    scope: {
      totalItems: "=",
      itemsPerPage: "=",
      currentPage: "=",
      changeCallback: "&?",
      canShowAll: "@?"
    },
    template,
    /*@ngInject */
    controller: function($scope) {
      var halfDisplayed = 1.5,
        displayedPages = 3,
        edges = 1;

      $scope.getInterval = function() {
        return {
          start: Math.ceil(
            $scope.currentPage > halfDisplayed
              ? Math.max(
                  Math.min(
                    $scope.currentPage - halfDisplayed,
                    $scope.paginatePages - displayedPages
                  ),
                  0
                )
              : 0
          ),
          end: Math.ceil(
            $scope.currentPage > halfDisplayed
              ? Math.min($scope.currentPage + halfDisplayed, $scope.paginatePages)
              : Math.min(displayedPages, $scope.paginatePages)
          )
        };
      };

      $scope.calculateTotalPages = function() {
        var totalPages =
          $scope.itemsPerPage < 1 ? 1 : Math.ceil($scope.totalItems / $scope.itemsPerPage);
        return Math.max(totalPages || 0, 1);
      };

      $scope.havePrev = function() {
        return $scope.currentPage > 0;
      };

      $scope.haveNext = function() {
        return $scope.currentPage < $scope.paginatePages - 1;
      };

      $scope.selectPage = function(pageIndex) {
        $scope.currentPage = pageIndex;
      };

      $scope.generatePagination = function() {
        var interval = $scope.getInterval(),
          i,
          range,
          leftHellip = false,
          rightHellip = false,
          leftRange = [],
          middleRange = [],
          rightRange = [];

        // Generate start edges
        if (interval.start > 0 && edges > 0) {
          var end = Math.min(edges, interval.start);
          for (i = 0; i < end; i++) {
            leftRange.push(i);
          }

          if (edges < interval.start) {
            leftHellip = true;
          }
        }
        // Generate interval links
        for (i = interval.start; i < interval.end; i++) {
          middleRange.push(i);
        }
        // Generate end edges
        if (interval.end < $scope.paginatePages && edges > 0) {
          if ($scope.paginatePages - edges > interval.end) {
            rightHellip = true;
          }
          var begin = Math.max($scope.paginatePages - edges, interval.end);
          for (i = begin; i < $scope.paginatePages; i++) {
            rightRange.push(i);
          }
        }
        range = {
          leftHellip: leftHellip,
          rightHellip: rightHellip,

          leftRange: leftRange,
          middleRange: middleRange,
          rightRange: rightRange
        };
        $scope.range = range;
      };
    },
    link: function(scope) {
      scope.$watch("totalItems", function(value) {
        if (angular.isUndefined(value) || !angular.isNumber(value)) {
          return;
        }
        scope.paginatePages = scope.calculateTotalPages();
        if (angular.isNumber(scope.currentPage) && scope.paginatePages <= scope.currentPage) {
          scope.currentPage = scope.paginatePages - 1;
          return;
        } else {
          scope.generatePagination();
        }
      });
      scope.$watch("currentPage", function(value, oldValue) {
        if (angular.isUndefined(oldValue) || angular.isUndefined(value) || value === oldValue) {
          return;
        }
        if (angular.isNumber(value) && value >= scope.paginatePages) {
          scope.currentPage = scope.paginatePages - 1;
          return;
        } else {
          scope.generatePagination();
          if (scope.changeCallback()) {
            scope.changeCallback()();
          }
        }
      });
    }
  };
});
