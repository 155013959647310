import { moduleLoader } from "#/utils/lazy-load";
import module from "../../core/index";

module.config(AppStateConfig);
/* @ngInject */
function AppStateConfig($stateProvider) {
  $stateProvider
    .state("app", {
      url: "",
      abstract: true,
      resolve: {
        /* @ngInject */
        authorize: function(AuthorizationService) {
          return AuthorizationService.authorize().catch(function(error) {
            if (process.env.NODE_ENV === "development") {
              //eslint-disable-next-line
              console.log(error);
            }
          });
        }
      }
    })
    .state("app.close", {
      url: "/close",
      resolve: {
        close(PopoutService) {
          "ngInject";
          return PopoutService.close();
        }
      }
    })
    .state({
      parent: "app",
      name: "profile",
      url: "/profile",
      views: {
        "@": {
          controller($state, PrincipalService) {
            "ngInject";
            $state.go("users.item.edit", { id: PrincipalService.getIdentity().id });
          }
        }
      }
    })
    .state({
      name: "account.**",
      url: "/",
      lazyLoad: moduleLoader(_ => _.loadAccount())
    })
    .state({
      name: "customers.**",
      url: "/customers",
      lazyLoad: moduleLoader(_ => _.loadCustomers())
    })
    .state({
      name: "dashboard.**",
      url: "/dashboard",
      lazyLoad: moduleLoader(_ => _.loadDashboard())
    })
    .state({
      name: "documents.**",
      url: "/documents",
      lazyLoad: moduleLoader(_ => _.loadTasks().then(() => _.loadDocuments()))
    })
    .state({
      name: "patients.**",
      url: "/patients",
      lazyLoad: moduleLoader(_ => _.loadTasks().then(() => _.loadPatients()))
    })
    .state({
      name: "printforms.**",
      url: "/forms",
      lazyLoad: moduleLoader(_ => _.loadPrintforms())
    })
    .state({
      name: "users.**",
      url: "/users",
      lazyLoad: moduleLoader(_ => _.loadUsers())
    })
    .state({
      name: "clinics.**",
      url: "/clinics",
      lazyLoad: moduleLoader(_ => _.loadTasks().then(() => _.loadClinics()))
    });
}
