import module from "../module";
import template from "./single-clinic-session-view.tmpl.html";

module.directive("singleClinicSessionView" /*@ngInject */, function() {
  return {
    replace: true,
    scope: {
      locationId: "<",
      clinicStartTime: "<",
      clinicEndTime: "<",
      listOfNurseNames: "<",
      listOfLocations: "<"
    },
    template,

    link: function(scope, element, __) {
      var unwatchListOfNurses = scope.$watchCollection("listOfNurseNames", function(newVal) {
        scope.nurseNameDisplay = "";
        if (newVal) {
          scope.nurseNameDisplay = createNurseDisplay(scope.listOfNurseNames);
        }
      });

      var unwatchListOfLocations = scope.$watchCollection("listOfLocations", function(newVal) {
        scope.selectedLocation = null;
        if (newVal) {
          scope.selectedLocation = getSelectedLocation(scope.listOfLocations, scope.locationId);
        }
      });

      var unwatchLocationId = scope.$watch("locationId", function(newVal) {
        scope.selectedLocation = null;
        if (newVal && scope.listOfLocations) {
          scope.selectedLocation = getSelectedLocation(scope.listOfLocations, scope.locationId);
        }
      });

      element.on("$destroy", function() {
        unwatchListOfNurses();
        unwatchListOfLocations();
        unwatchLocationId();
      });
    }
  };

  function createNurseDisplay(listOfNurseNames) {
    let nurseNameDisplay = "";
    Object.values(listOfNurseNames).forEach(name => {
      nurseNameDisplay += name + ", ";
    });

    if (nurseNameDisplay === "") {
      nurseNameDisplay = "No nurse assigned";
    } else {
      nurseNameDisplay = nurseNameDisplay.substring(0, nurseNameDisplay.length - 2);
    }

    return nurseNameDisplay;
  }

  function getSelectedLocation(listOfLocations, locationId) {
    return listOfLocations.find(l => l.id === locationId);
  }
});
