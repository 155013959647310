import angular from "angular";
import module from "../module";
import template from "./angucomplete.tmpl.html";

/**
 * Angucomplete
 * Autocomplete directive for AngularJS
 * By Daryl Rowland
 */

module.directive("angucomplete" /* @ngInject */, function($http, $sce, $timeout) {
  return {
    restrict: "EA",
    scope: {
      id: "@id",
      placeholder: "@placeholder",
      selectedObject: "=selectedobject",
      url: "@url",
      dataField: "@datafield",
      titleField: "@titlefield",
      descriptionField: "@descriptionfield",
      imageField: "@imagefield",
      inputClass: "@inputclass",
      userPause: "@pause",
      localData: "=?localdata",
      externalData: "=?externaldata",
      searchFields: "@searchfields",
      minLengthUser: "@minlength",
      matchClass: "@matchclass",
      model: "=?ngModel",
      ngDisabled: "=?",
      newOption: "@newoption",
      newOptionTransform: "&?transform"
    },
    template,
    priority: 101,
    link: function($scope, elem) {
      $scope.lastSearchTerm = null;
      $scope.currentIndex = null;
      $scope.justChanged = false;
      $scope.searchTimer = null;
      $scope.hideTimer = null;
      $scope.searching = false;
      $scope.pause = 500;
      $scope.minLength = 3;
      $scope.searchStr = null;
      $scope.newOptionTransform = $scope.newOptionTransform && $scope.newOptionTransform();

      if ($scope.minLengthUser && $scope.minLengthUser !== "") {
        $scope.minLength = $scope.minLengthUser;
      }

      if ($scope.userPause) {
        $scope.pause = $scope.userPause;
      }

      var isNewSearchNeeded = function(newTerm, oldTerm) {
        return newTerm.length >= $scope.minLength && newTerm != oldTerm;
      };

      var lastKeyPressed;
      $scope.processResults = function(responseData, str) {
        if (responseData && responseData.length > 0) {
          $scope.results = [];

          var titleFields = [];
          if ($scope.titleField && $scope.titleField !== "") {
            titleFields = $scope.titleField.split(",");
          }

          var descriptionField = $scope.descriptionField;

          for (var i = 0; i < responseData.length; i++) {
            // Get title variables
            var titleCode = [];

            for (var t = 0; t < titleFields.length; t++) {
              titleCode.push(getPropValue(responseData[i], titleFields[t]));
            }
            var text = titleCode.join(" ");
            if ($scope.matchClass) {
              var re = new RegExp(str, "i");
              var strPart = text.match(re)[0];
              text = $sce.trustAsHtml(
                text.replace(re, '<span class="' + $scope.matchClass + '">' + strPart + "</span>")
              );
            }
            var resultRow = {
              title: text,
              originalObject: responseData[i],
              description: responseData[i][descriptionField]
            };
            $scope.results.push(resultRow);
          }
        } else {
          $scope.results = [];
        }

        if ($scope.newOption) {
          var exist;
          for (var j = 0, ii = $scope.results.length; j < ii; j++) {
            if ($scope.results[j].title == $scope.searchStr) {
              exist = true;
              break;
            }
          }
          if (!exist) {
            var newobj = { title: $scope.searchStr, isNew: true };
            if ($scope.newOptionTransform) {
              newobj.originalObject = $scope.newOptionTransform($scope.searchStr);
            }
            $scope.results.unshift(newobj);
          }
        }

        if ($scope.results.length > 0) {
          $scope.currentIndex = 0;
        }
        if ($scope.results.length === 1 && lastKeyPressed !== 8 && !$scope.newOption) {
          $scope.selectResult($scope.results[0]);
        }
      };

      $scope.searchTimerComplete = function(str) {
        // Begin the search
        if (!angular.isDefined(str)) {
          return;
        }
        if (str.length >= $scope.minLength) {
          if ($scope.externalData) {
            $scope.localData = $scope.externalData(str);
          }

          if ($scope.localData) {
            if (angular.isDefined($scope.localData.then)) {
              $scope.localData.then(function(data) {
                $scope.localData = $scope.dataField ? data[$scope.dataField] : data;
                $scope.processResults($scope.localData, str);
                $scope.searching = false;
              });
            } else {
              searchInLocalData();
            }
          } else {
            $http.get($scope.url + str, {}).success(function(responseData) {
              $scope.searching = false;
              $scope.processResults(
                $scope.dataField ? responseData[$scope.dataField] : responseData,
                str
              );
            });
          }
        }

        function searchInLocalData() {
          var searchFields = $scope.searchFields.split(",");

          var matches = [];

          for (var i = 0; i < $scope.localData.length; i++) {
            var match = false;

            for (var s = 0; s < searchFields.length; s++) {
              var val = getPropValue($scope.localData[i], searchFields[s]);
              match =
                match ||
                (typeof val === "string" &&
                  typeof str === "string" &&
                  val.toLowerCase().indexOf(str.toLowerCase()) >= 0);
            }

            if (match) {
              matches[matches.length] = $scope.localData[i];
            }
          }

          $scope.searching = false;
          $scope.processResults(matches, str);
          //$scope.$apply();
        }
      };

      $scope.hideResults = function() {
        $scope.hideTimer = $timeout(function() {
          close();
          setSearchStr($scope.model);
        }, $scope.pause);
      };

      $scope.resetHideResults = function() {
        if ($scope.hideTimer) {
          $timeout.cancel($scope.hideTimer);
        }
      };

      $scope.hoverRow = function(index) {
        $scope.currentIndex = index;
      };
      $scope.keyPressed = function(event) {
        lastKeyPressed = event.which;
        if (!(event.which == 38 || event.which == 40 || event.which == 13)) {
          if (!$scope.searchStr || $scope.searchStr === "") {
            close();
            $scope.lastSearchTerm = null;
          } else if (isNewSearchNeeded($scope.searchStr, $scope.lastSearchTerm)) {
            $scope.lastSearchTerm = $scope.searchStr;
            $scope.showDropdown = true;
            $scope.currentIndex = -1;
            $scope.results = [];

            if ($scope.searchTimer) {
              $timeout.cancel($scope.searchTimer);
            }

            $scope.searching = true;

            $scope.searchTimer = $timeout(function() {
              $scope.searchTimerComplete($scope.searchStr);
            }, $scope.pause);
          }
        } else {
          if ((event.which == 13 || event.which == 9) && $scope.results.length > 0) {
            var result = $scope.results[0];
            $scope.searchStr = result.title;
            $scope.selectedObject = result;
            close();
          }
          if (
            !$scope.showDropdown &&
            (event.which == 40 || event.which == 38) &&
            (angular.isUndefined($scope.selectedObject) || $scope.selectedObject === null)
          ) {
            $scope.lastSearchTerm = $scope.searchStr;
            $scope.showDropdown = true;
            $scope.currentIndex = -1;
            $scope.results = [];

            if ($scope.searchTimer) {
              $timeout.cancel($scope.searchTimer);
            }

            $scope.searching = true;

            $scope.searchTimer = $timeout(function() {
              $scope.searchTimerComplete($scope.searchStr);
            }, $scope.pause);
            return;
          }

          event.preventDefault();
        }
      };
      $scope.selectResult = function(result) {
        if (angular.isDefined(result)) {
          if ($scope.matchClass) {
            result.title = result.title.toString().replace(/(<([^>]+)>)/gi, "");
          }
          $scope.searchStr = result.title;
          $scope.model = result.title;
          $scope.selectedObject = result;
          close();
          $scope.results = [];
        }
        //$scope.$apply();
      };

      var inputField = elem.find("input");

      inputField.on("keyup", $scope.keyPressed);

      elem.on("keydown", function(event) {
        if (event.which == 9) {
          if (
            $scope.results &&
            $scope.currentIndex >= 0 &&
            $scope.currentIndex < $scope.results.length
          ) {
            $scope.selectResult($scope.results[$scope.currentIndex]);
            $scope.$apply();
            event.preventDefault();
            event.stopPropagation();
          }
        }
      });
      elem.on("keyup", function(event) {
        if (event.which === 40) {
          if ($scope.results && $scope.currentIndex + 1 < $scope.results.length) {
            $scope.currentIndex++;
            $scope.$apply();
            event.preventDefault();
            event.stopPropagation();
          }

          $scope.$apply();
        } else if (event.which == 38) {
          if ($scope.currentIndex >= 1) {
            $scope.currentIndex--;
            $scope.$apply();
            event.preventDefault();
            event.stopPropagation();
          }
        } else if (event.which == 13 || event.which == 9) {
          if (
            $scope.results &&
            $scope.currentIndex >= 0 &&
            $scope.currentIndex < $scope.results.length
          ) {
            $scope.selectResult($scope.results[$scope.currentIndex]);
            $scope.$apply();
            event.preventDefault();
            event.stopPropagation();
          } else {
            $scope.results = [];
            $scope.$apply();
            event.preventDefault();
            event.stopPropagation();
          }
        } else if (event.which == 27) {
          $scope.results = [];
          close();
          $scope.$apply();
        } else if (event.which == 8) {
          $scope.selectedObject = null;
          $scope.$apply();
        }
      });

      $scope.$watch("model", setSearchStr);

      function setSearchStr(value) {
        if (angular.isUndefined(value) || value === null) {
          $scope.searchStr = "";
        } else {
          //if (!angular.equals($scope.searchStr, value)) {
          // $scope.searchTimerComplete(value);
          $scope.searchStr = value;
          //}
        }
      }

      function getPropValue(obj, propKey) {
        var keys = propKey.split("."),
          clone = angular.copy(obj);

        for (var i = 0, ii = keys.length; i < ii; i++) {
          if (angular.isUndefined(clone)) {
            throw new Error("Can't find object " + propKey);
          }

          if (!clone.hasOwnProperty(keys[i])) {
            throw new Error("Object doesn't have own prop " + propKey);
          }

          clone = clone[keys[i]];
        }

        return clone;
      }

      function close() {
        if ($scope.showDropdown) {
          $scope.showDropdown = false;
          elem.find("input")[0].focus();
        }
      }
    }
  };
});
