import module from "./module";
import { buildUrl, searchQuery } from "#/utils/api";

module.factory("ApiRxRenewalsService", ApiRxRenewalsService);

/*@ngInject*/
function ApiRxRenewalsService(ApiResourcesService, PrincipalService) {
  var rxRenewal = ApiResourcesService.RxRenewals;

  return {
    search,
    generateRxForms,
    getRxFormsUrl
  };

  // implementation

  function search(term, start, count, sortArray, tab, serviceType, status, customer) {
    var obj = searchQuery(term, start, count, sortArray);

    if (count === "all") {
      obj.count = "";
    }

    obj.serviceType = serviceType;
    obj.status = status;
    obj.tab = tab;
    obj.customer = customer;
    return rxRenewal.query(obj, null).$promise;
  }

  function generateRxForms(renewalIds) {
    return rxRenewal.generateRxForms({ renewalIds: renewalIds }).$promise;
  }

  function getRxFormsUrl(reportId) {
    var token, result;

    token = PrincipalService.getIdentity().token;
    result = buildUrl(`rxrenewals/bulk_reports/${reportId}?token=${encodeURIComponent(token)}`);

    return result;
  }
}
