import module from "./module";

module.factory("ApiDocumentNoteService", ApiDocumentNoteService);

function ApiDocumentNoteService($rootScope, ApiResourcesService) {
  "ngInject";

  const api = {
      getNotes,
      addDocumentNote,
      editDocumentNote,
      editDocumentComment,
      addChangeListener,
      addComment
    },
    documentApi = ApiResourcesService.Documents;

  return api;

  function getNotes(documentId) {
    return documentApi.query({ id: documentId }).$promise;
  }

  function addComment(noteId, commentText) {
    const result = documentApi.addComment({ noteId: noteId }, { text: commentText }).$promise;

    result.then(handleNotesChange);

    return result;
  }

  function addDocumentNote(documentId, noteText, noteDeadLine) {
    const result = documentApi.addNote(
      { id: documentId },
      { text: noteText, deadLine: noteDeadLine }
    ).$promise;

    result.then(handleNotesChange);

    return result;
  }

  function editDocumentNote(note) {
    const result = documentApi.editNote(
      { noteId: note.id },
      { text: note.text, deadLine: note.deadline }
    ).$promise;

    result.then(handleNotesChange);

    return result;
  }

  function editDocumentComment(noteId, comment) {
    const result = documentApi.editComment(
      { noteId: noteId, commentId: comment.id },
      { text: comment.text }
    ).$promise;

    result.then(handleNotesChange);

    return result;
  }

  function addChangeListener(listener) {
    return $rootScope.$on("NOTES_CHANGED", listener);
  }

  function handleNotesChange() {
    $rootScope.$emit("NOTES_CHANGED");
  }
}
