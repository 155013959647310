import module from "../module";

module.factory("ReplaceDateInterceptorService", ReplaceDateInterceptorService);
/* @ngInject */
function ReplaceDateInterceptorService($q, DateService) {
  var interceptor = {};

  interceptor.response = function(response) {
    if (response && response.data) {
      DateService.replaceStringWithDate(response.data);
    }
    return response || $q.when(response);
  };

  return interceptor;
}
