const WORKFLOW_DELIVERY_CHARGE = [
    { code: 'D5', description: 'Dispense and Delivery Standard' },
    { code: 'D1', description: 'Dispense and Delivery (Lower Fee) < 3 Months' },
    { code: 'D3', description: 'Dispense and Delivery Enhanced' },
    { code: 'D2', description: 'Dispense and Delivery Emergency Courier' },
    { code: 'D4', description: 'Dispense and Deliver FOC' },
    { code: 'S1', description: 'Sharps Supply/Collection/Disposal' }
];

type WORKFLOW_DELIVERY_CHARGE = typeof WORKFLOW_DELIVERY_CHARGE;

export default WORKFLOW_DELIVERY_CHARGE;