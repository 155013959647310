// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Object/entries
function entries<T>(obj: { [s: string]: T } | { [n: number]: T }): [string, T][];
function entries(obj: any): [string, any][] {
  const ownProps = Object.keys(obj);
  let i = ownProps.length;
  const resArray = new Array(i);

  while (i--) resArray[i] = [ownProps[i], obj[ownProps[i]]];

  return resArray;
}

export { entries };
