import * as angular from 'angular';

import SITE from '#/constants/site';
import ISortField from '#/interface/core/entity/sort-field';

export interface IQuery {
  term: string;
  start: number;
  count: number;
  sort: string | undefined;
}

function forceSuffix(value: string, suffix: string) {
  return value.endsWith(suffix) ? value : value + suffix;
}

type Api = 'pharmaxo' | 'reference';

const domains = {
  pharmaxo: SITE.pharmaxoApi,
  reference: SITE.referenceApi
};

export function buildUrl(resourceUrl: string, api: Api = 'pharmaxo') {
  const SLASH = '/',
    domain = domains[api];

  return forceSuffix(domain, SLASH) + resourceUrl;
}

export function getSortQuery(sortArray: ISortField[] | undefined) {
  if (!sortArray || sortArray.length === 0) return undefined;

  const array: string[] = [];
  sortArray.forEach(sort =>
    array.push(sort.field + ':' + (sort.order ? 'ascending' : 'descending'))
  );
  return array.join(',');
}

export function searchQuery(
  term: string | undefined,
  start: number | undefined,
  count: number | undefined,
  sortArray: ISortField[] | undefined
): IQuery {
  return {
    term: term || '',
    start: start || 0,
    count: count || 10,
    sort: getSortQuery(sortArray)
  };
}

/**
 * Many services use ng-resource that adds own fields prefixed with $ to response.
 * They are not used anywhere in the application so this utility helps to cleanup
 * supplied object from them.
 */
export function trimDollarFields<T>(value: T): T {
  Object.keys(value).forEach(key => {
    if (key.startsWith('$')) delete (value as any)[key];
  });

  return value;
}

/**
 * Moved from object service
 */
export function diffObject<T>(
  oldObj: any,
  newObj: T,
  includeIfNotExist: boolean
): Partial<T> | false {
  const diff: Partial<T> = {};
  let haveDiff = false;
  // tslint:disable-next-line
  for (const i in newObj) {
    const skip =
      !newObj.hasOwnProperty(i) ||
      typeof newObj[i] === 'function' ||
      (!includeIfNotExist && oldObj[i] === undefined);

    if (skip) continue;

    if (!angular.equals(newObj[i], oldObj[i])) {
      diff[i] = newObj[i];
      haveDiff = true;
    }
  }
  return haveDiff && diff;
}
