const MESSAGE_TEMPLATE = [
  {
    name: 'REGULAR',
    template:
      '{{data.patientId | redirectLink:"patients.item.view":"Patient"}}{{text}}{{data === data + ""? ": " + data : "" }}{{data.influentDocumentId|redirectLink:"documents.item.view":"Document":". By influent "}}'
  },
  {
    name: 'DOCUMENT',
    template: '{{text}}'
  },
  {
    name: 'FILE',
    template:
      '{{text?text+": ":""}} <a ng-click="$event.preventDefault(); vm.handleOpenDocumentFile(\'{{data.documentId}}\',{{data.version-1}});" href="#">View Document</a>&nbsp;'
  },
  {
    name: 'USER ADDED',
    template: '{{text?text+": ":""}} {{data|redirectLink:"users.item.view":"User"}}'
  },
  {
    name: 'User name changed::Suffixes',
    template: '{{text}}: {{data | joinBy:", "}}'
  },
  {
    name: 'User name changed::GivenNames',
    template: '{{text}}: {{data | joinBy:", "}}'
  },
  {
    name: 'User::SignatureImageAdded',
    template: '{{text}}: {{data.signatureImageForDisplay?"<img class=\'signature-display\' ng-src=\'data:image/png;base64,"+data.signatureImageForDisplay+"\' />" : "<br />Signature image cannot be retrieved"}}'
  },
  {
    name: 'User::SignatureImageChanged',
    template: '{{text}}: {{data.signatureImageForDisplay?"<img class=\'signature-display\' ng-src=\'data:image/png;base64,"+data.signatureImageForDisplay+"\' />" : "<br />Signature image cannot be retrieved"}}'
  },
  {
    name: 'LINK INFO::PATIENT::ADDED',
    template: 'Linked to {{data|redirectLink:"patients.item.view":"Patient"}}'
  },
  {
    name: 'LINK INFO::PATIENT::CHANGED',
    template: 'Linked to {{data|redirectLink:"patients.item.view":"Patient"}}'
  },
  {
    name: 'LINK INFO::LINKER::ADDED',
    template: 'Linked by {{data|redirectLink:"users.item.view":"User"}}'
  },
  {
    name: 'LINK INFO::LINKER::CHANGED',
    template: 'Linked by {{data|redirectLink:"users.item.view":"User"}}'
  },
  {
    name: 'LINK INFO::PATIENT CUSTOMER ID::ADDED',
    template: 'Linked to {{data|redirectLink:"customers.item.view":"Customer"}}'
  },
  {
    name: 'LINK INFO::PATIENT CUSTOMER ID::CHANGED',
    template: 'Linked to {{data|redirectLink:"customers.item.view":"Customer"}}'
  },
  {
    name: 'LINK INFO::ENROLLMENT ID::ADDED',
    template:
      'Linked to <a ui-sref=\'patients.item.edit({scrollToEnrollmentId: "{{data.enrollmentId}}", id: "{{data.patient}}"})\'>Enrollment</a>'
  },
  {
    name: 'PATIENT DOCUMENT::LINK INFO::PATIENT::ADDED',
    template: 'Linked to {{data|redirectLink:"patients.item.view":"Patient"}}'
  },
  {
    name: 'PATIENT DOCUMENT::LINK INFO::PATIENT::CHANGED',
    template: 'Linked to {{data|redirectLink:"patients.item.view":"Patient"}}'
  },
  {
    name: 'PATIENT DOCUMENT::LINK INFO::LINKER::ADDED',
    template: 'Linked by {{data|redirectLink:"users.item.view":"User"}}'
  },
  {
    name: 'PATIENT DOCUMENT::LINK INFO::LINKER::CHANGED',
    template: 'Linked by {{data|redirectLink:"users.item.view":"User"}}'
  },
  {
    name: 'PATIENT DOCUMENT::LINK INFO::PATIENT CUSTOMER ID::ADDED',
    template: 'Linked to {{data|redirectLink:"customers.item.view":"Customer"}}'
  },
  {
    name: 'PATIENT DOCUMENT::LINK INFO::PATIENT CUSTOMER ID::CHANGED',
    template: 'Linked to {{data|redirectLink:"customers.item.view":"Customer"}}'
  },
  {
    name: 'PATIENT DOCUMENT::LINK INFO::ENROLLMENT ID::ADDED',
    template:
      'Linked to <a ui-sref=\'patients.item.edit({scrollToEnrollmentId: "{{data.enrollmentId}}", id: "{{data.patient}}"})\'>Enrollment</a>'
  },
  {
    name: 'PATIENT DOCUMENT::DOCUMENT',
    template:
      '{{text?text+": ":""}} {{ {id:data.documentId, version:data.version}|redirectLink:"documents.item.view":"Document View"}}'
  },
  {
    name: 'PATIENT DOCUMENT::FILE',
    template:
      '{{text?text+": ":""}} <a ng-click="$event.preventDefault(); vm.handleOpenDocumentFile(\'{{data.documentId}}\',{{data.version-1}});" href="#">View Document</a>&nbsp;'
  },
  {
    name: 'COMMENT',
    template: 'Comment added: {{data.text}}'
  },
  {
    name: 'PATIENT ID',
    template:
      '{{text}}{{data.value?": " + data.value:""}}{{ data.influentDocumentId|redirectLink:"documents.item.view":"Document":". By influent "}}'
  },
  {
    name: 'NAME',
    template:
      '{{text}} to {{data.value|view:"name"}}{{data.influentDocumentId|redirectLink:"documents.item.view":"Document":". By influent "}}'
  },
  {
    name: 'DEMOGRAPHIC DATA::DATE OF BIRTH::CHANGED',
    template:
      'Date of Birth changed to {{data.value|partialdate}}{{data.influentDocumentId|redirectLink:"documents.item.view":"Document":". By influent "}}'
  },
  {
    name: 'Address::Special Instructions::Added',
    template: 'Address special instructions for courier added: {{data.value}}'
  },
  {
    name: 'Address::Special Instructions::Changed',
    template: 'Address special instructions for courier changed to: {{data.value}}'
  },
  {
    name: 'Address::Special Instructions::Removed',
    template: 'Address special instructions for courier removed.'
  },
  {
    name: 'Delivery Address',
    template:
      '{{text}}: {{data.address|view:"address"}}{{data.influentDocumentId|redirectLink:"documents.item.view":"Document":". By influent "}}'
  },
  {
    name: 'Nominated Contact',
    template:
      '{{text}}: {{data.value|view:"nominatedcontactaudittrail"}}{{data.influentDocumentId|redirectLink:"documents.item.view":"Document":". By influent "}}'
  },
  {
    name: 'Contact Info::Emails::Changed',
    template: 'Contact info emails changed: <span ng-if="{{data.value.length > 0}}"> {{data.value | confirmationEmailMap:"value":"isConfirmationEmail" | joinBy: ", "}}</span>'
  },
  {
    name: 'Contact Info',
    template:
      '{{text}}: {{data.value|map:"value"|joinBy:", "|json}}{{data.influentDocumentId|redirectLink:"documents.item.view":"Document":". By influent "}}'
  },
  {
    name: 'Gp Details',
    template:
      '{{text}}: {{data.value|gpDetails}}{{data.influentDocumentId|redirectLink:"documents.item.view":"Document":". By influent "}}'
  },
  {
    name: 'Communication Requirements::Privacy',
    template:
      '{{text}} with anonymity: {{data.value.anonymity|json}}, cover story: {{data.value.coverStory|json}}{{data.influentDocumentId|redirectLink:"documents.item.view":"Document":". By influent "}}'
  },
  {
    name: 'Communication Requirements::Preferred Phone::Changed',
    template: '{{text}}: {{data.value.value}} <span ng-if="{{data.nominatedContactName !== null}}"> ({{ data.nominatedContactName.title + " " + data.nominatedContactName.givenNames[0] + " " + data.nominatedContactName.surname}})</span>'
  },
  {
    name: 'Communication Requirements::Preferred Sms::Changed',
    template: '{{text}}: {{data.value.value}} <span ng-if="{{data.nominatedContactName !== null}}"> ({{ data.nominatedContactName.title + " " + data.nominatedContactName.givenNames[0] + " " + data.nominatedContactName.surname}})</span>'
  },
  {
    name: 'Communication Requirements::Preferred Email::Changed',
    template: '{{text}}: {{data.value.value}} <span ng-if="{{data.nominatedContactName !== null}}"> ({{ data.nominatedContactName.title + " " + data.nominatedContactName.givenNames[0] + " " + data.nominatedContactName.surname}})</span>'
  },
  {
    name: 'Communication Requirements::Preferred Phone::Added',
    template: '{{text}}: {{data.value.value}} <span ng-if="{{data.nominatedContactName !== null}}"> ({{ data.nominatedContactName.title + " " + data.nominatedContactName.givenNames[0] + " " + data.nominatedContactName.surname}})</span>'
  },
  {
    name: 'Communication Requirements::Preferred Sms::Added',
    template: '{{text}}: {{data.value.value}} <span ng-if="{{data.nominatedContactName !== null}}"> ({{ data.nominatedContactName.title + " " + data.nominatedContactName.givenNames[0] + " " + data.nominatedContactName.surname}})</span>'
  },
  {
    name: 'Communication Requirements::Preferred Email::Added',
    template: '{{text}}: {{data.value.value}} <span ng-if="{{data.nominatedContactName !== null}}"> ({{ data.nominatedContactName.title + " " + data.nominatedContactName.givenNames[0] + " " + data.nominatedContactName.surname}})</span>'
  },
  {
    name: 'Communication Requirements::Preferred Phone::Removed',
    template: '{{text}}: {{data.value.value}} <span ng-if="{{data.nominatedContactName !== null}}"> ({{ data.nominatedContactName.title + " " + data.nominatedContactName.givenNames[0] + " " + data.nominatedContactName.surname}})</span>'
  },
  {
    name: 'Communication Requirements::Preferred Sms::Removed',
    template: '{{text}}: {{data.value.value}} <span ng-if="{{data.nominatedContactName !== null}}"> ({{ data.nominatedContactName.title + " " + data.nominatedContactName.givenNames[0] + " " + data.nominatedContactName.surname}})</span>'
  },
  {
    name: 'Communication Requirements::Preferred Email::Removed',
    template: '{{text}}: {{data.value.value}} <span ng-if="{{data.nominatedContactName !== null}}"> ({{ data.nominatedContactName.title + " " + data.nominatedContactName.givenNames[0] + " " + data.nominatedContactName.surname}})</span>'
  },
  {
    name: 'Communication Requirements::Preferred Contact Time Other::Added',
    template: '({{data.value}})'
  },
  {
    name: 'Communication Requirements::Preferred Contact Time Other::Changed',
    template: '({{data.value}})'
  },
  {
    name: 'Communication Requirements::Self Service Opt In::Added',
    template: '{{text}}: {{data.value === true ? "Opted-In" : "Opted Out" }}'
  },
  {
    name: 'Communication Requirements::Self Service Opt In::Changed',
    template: '{{text}}: {{data.value === true ? "Opted-In" : "Opted Out" }}'
  },
  {
    name: 'Communication Requirements',
    template:
      '{{text}}: {{data.value}}{{data.influentDocumentId|redirectLink:"documents.item.view":"Document":". By influent "}}'
  },
  {
    name: 'Sensitivity',
    template:
      '{{text}}: {{data.value | sensitivity}}{{data.influentDocumentId|redirectLink:"documents.item.view":"Document":". By influent "}}'
  },
  {
    name: 'Condition',
    template:
      '{{text}}: {{data.value | condition}}{{data.influentDocumentId|redirectLink:"documents.item.view":"Document":". By influent "}}'
  },
  {
    name: 'Measurement',
    template:
      '{{text}}: {{data.value | measurement}}{{data.influentDocumentId|redirectLink:"documents.item.view":"Document":". By influent "}}'
  },
  {
    name: 'Medication',
    template:
      '{{text}}: {{data.value | medication}}{{data.influentDocumentId|redirectLink:"documents.item.view":"Document":". By influent "}}'
  },
  {
    name: 'Patient Deleted',
    template:
      '{{text}} {{data.influentDocumentId|redirectLink:"documents.item.view":"Document":". By influent "}}'
  },
  {
    name: 'Enrolled In Service',
    template:
      '{{text}} {{data.influentDocumentId|redirectLink:"documents.item.view":"Document":". By influent "}}'
  },
  {
    name: 'Confirmation Email Sent',
    template:
      '<span style="white-space:pre-line;">{{text}}</span>'
  },
  {
    name: 'Patient changes::Name',
    template:
      '{{data.patientId | redirectLink:"patients.item.view":"Patient"}} {{text}} to {{data.value|view:"name"}}'
  },
  {
    name: 'Patient changes::Nominated Contact',
    template:
      '{{data.patientId | redirectLink:"patients.item.view":"Patient"}} {{text}}: {{data.value}}'
  },
  {
    name: 'Patient changes::Contact Info',
    template:
      '{{data.patientId | redirectLink:"patients.item.view":"Patient"}}{{text}}: {{data.value|map:"value"|joinBy:", "|json}}'
  },
  {
    name: 'Patient changes::Gp Details',
    template:
      '{{data.patientId | redirectLink:"patients.item.view":"Patient"}} {{text}}: {{data.value}}'
  },
  {
    name: 'Patient changes::Communication Requirements::Privacy',
    template:
      '{{data.patientId | redirectLink:"patients.item.view":"Patient"}} {{text}}: anonymity: {{data.value.anonymity|json}}, cover story: {{data.value.coverStory|json}}'
  },
  {
    name: 'Patient changes::Communication Requirements',
    template:
      '{{data.patientId | redirectLink:"patients.item.view":"Patient"}} {{text}}: {{data.value}}'
  },
  {
    name: 'Patient changes::Sensitivity',
    template:
      '{{data.patientId | redirectLink:"patients.item.view":"Patient"}} {{text}}: {{data.value | sensitivity}}'
  },
  {
    name: 'Patient changes::Condition',
    template:
      '{{data.patientId | redirectLink:"patients.item.view":"Patient"}} {{text}}: {{data.value | condition}}'
  },
  {
    name: 'Patient changes::Measurement',
    template:
      '{{data.patientId | redirectLink:"patients.item.view":"Patient"}} {{text}}: {{data.value | measurement}}'
  },
  {
    name: 'Patient changes::Medication',
    template:
      '{{data.patientId | redirectLink:"patients.item.view":"Patient"}} {{text}}: {{data.value | medication}}'
  },
  {
    name: 'Patient changes::Patient Deleted',
    template: '{{data.patientId | redirectLink:"patients.item.view":"Patient"}} {{text}}'
  },
  {
    name: 'Patient changes::Enrolled In Service',
    template: '{{data.patientId | redirectLink:"patients.item.view":"Patient"}} {{text}}'
  },
  {
    name: 'Note::Created',
    template:
      '<b>Note Added:</b><br><div class="trail-note">{{(data.type | patientNoteType) + " by " + (data.communicationSource | patientNoteCommunicationSource)}} <br>{{text}}</div>'
  },
  {
    name: 'Note::Pinned',
    template:
      '<b>{{text}}:</b><br />{{data?"<div class=\'trail-note\'>" + (data.type | patientNoteType) + " by " + (data.communicationSource | patientNoteCommunicationSource) + " on " + (data.issued|date:\'dd/MM/yyyy HH:mm:ss\')' +
      ' + "<br />" + data.text + "</div>" : ""}}'
  },
  {
    name: 'Note::Unpinned',
    template:
      '<b>{{text}}:</b><br />{{data?"<div class=\'trail-note\'>" + (data.type | patientNoteType) + " by " + (data.communicationSource | patientNoteCommunicationSource) + " on " + (data.issued|date:\'dd/MM/yyyy HH:mm:ss\')' +
      ' + "<br />" + data.text + "</div>" : ""}}'
  },
  {
    name: 'Task::Linked',
    template:
      'Linked to <a ui-sref=\'patients.item.edit({id: "{{data.patient.id}}"})\'>{{data.patient.name | view:"name"}}</a>' +
      '<br>' +
      'Linked to <a ui-sref=\'patients.item.edit({scrollToEnrollmentId: "{{data.enrollment.id}}", id: "{{data.patient.id}}"})\'>{{data.enrollment.description}}</a>'
    //   +
    //   '{{data.document.id ? "<br>Linked to" : ""}} <a ui-sref=\'documents.item.edit({id: "{{data.document.id}}"})\'>{{data.document.description}}</a>'
  },
  {
    name: 'Task::WorkflowDataRecorded',
    template:
      'Type: Dispense and Deliver<br/>' +
      'Stock at Home: {{ data.data.currentStockAtHome }} <br/>' +
      'Date of Delivery: {{ data.data.dateOfDelivery|partialdate }} <br/>' +
      '<div ng-show="{{ !!data.data.consignmentInfo.consignmentId }}"> Consignment ID: {{ data.data.consignmentInfo.consignmentId }}</div>' +
      '<div ng-show="{{ data.data.consignmentInfo.failedToBook }}"> Failed to automatically book consignment.</div>' +
      'Confirmation Type: {{ data.data.confirmationRequired }} <br/>' +
      'Courier: {{ data.data.courierOptions.courier }} <span ng-show="{{ data.data.courierOptions.courierDetail !== \'\' }}">({{ data.data.courierOptions.courierDetail }})</span> <br />' +
      'Service: {{ data.data.courierOptions.serviceLevel? data.data.courierOptions.serviceLevel.name : data.data.courierOptions.service }} <span ng-show="{{ data.data.courierOptions.courierDetail !== \'\' }}">({{ data.data.courierOptions.otherService }})</span> <br />' +
      '<div ng-show="{{ data.data.courierOptions.serviceLevel !== null }}" >Service Code: {{ data.data.courierOptions.serviceLevel.code }} <br /></div>' +
      '<div ng-if="{{ data.data.courierOptions.noConsignmentNeeded != null }}" >Multiple Services - Do Not Book Consignment: {{ data.data.courierOptions.noConsignmentNeeded }} <br /></div>' +
      'Sharps Collection: {{ data.data.courierOptions.sharpsCollectionRequired }} <br />' +
      '<div ng-show="{{ data.data.courierOptions.otherCollection !== \'\' }}" >Other Collection Details: {{ data.data.courierOptions.otherCollection }} <br /></div>' +
      'Delivery Address: {{ data.data.courierOptions.deliveryAddress|view:"address" }} <br />' +
      'New Patient? {{ data.data.isNewPatient }} <br />' +
      'Free of Charge Ancillaries: ' +
      '{{ (data.freeAncils.length > 0)? "<span><br />&nbsp;&nbsp;&nbsp;&nbsp;" + (data.freeAncils|map:"term"|joinBy:"<br />&nbsp;&nbsp;&nbsp;&nbsp;") + "</span>" : "<span>None</span>" }}' +
      '<br />' +
      'Billable Ancillaries: ' +
      '{{ (data.billableAncils.length > 0)? "<span><br />&nbsp;&nbsp;&nbsp;&nbsp;" + (data.billableAncils|map:"term"|joinBy:"<br />&nbsp;&nbsp;&nbsp;&nbsp;") + "</span>" : "<span>None</span>" }}' +
      '<br />' +
      '<div ng-show="{{ data.data.anyAdditionalItems !== null }}">Any Additional Items? {{ data.data.anyAdditionalItems }} <br /></div>' +
      'Delivery Charges: {{data.data.deliveryCharges|map:"value"|joinBy:", "|json}} <br />' +
      '<div ng-show="{{data.data.consignmentLabelNotes !== \'\' }}"> Consignment Label Notes: <div ng-non-bindable>{{ data.data.consignmentLabelNotes }}<br /></div>' +
      '<div ng-show="{{data.data.notes !== \'\' }}"> Internal Notes: <div ng-non-bindable>{{ data.data.notes }}<br /></div>'
  },
  {
    name: 'Task::PvfDataRecorded',
    template:
      '<div>' +
      'PVF Data Changed: <a href="javascript:void(0)" ng-click="vm.openPatientVisitForm(vm.message.data.data)">View</a>.' +
      '</div>'
  },
  {
    name: 'Task::DidNotAttendDataRecorded',
    template: 'Did not attend reason changed to {{data.data.reasonText}}'
  },
  {
    name: 'Task::DataConfirmed',
    template:
      '<div>' +
      'Patient Visit Data Confirmed: <a href="javascript:void(0)" ng-click="vm.openPatientVisitForm(vm.message.data.taskData.data, vm.message.data.signingUser, vm.message.data.signatureMeaning, vm.message.data.signatureDate, vm.message.data.jobTitle, vm.message.data.signatureImage)">View</a>.' +
      '</div>'
  },
  {
    name: 'Task::ScheduleChanged::LocationTypeChanged',
    template: '{{text}} {{data}}</span>'
  },
  {
    name: 'Task::ScheduleChanged::LocationDetailsChanged',
    template: '{{text}} {{data.clinicDescription ? (data|view:"clinicLocationDetails") : (data|view:"address")}}'
  },
  {
    name: 'Task::ScheduleChanged',
    template: '{{text}}<span ng-show="{{data !== null}}"> {{data}}</span>'
  },
  {
    name: 'Task::AssignedUserUpdated',
    template: '{{text}}'
  },
  {
    name: 'Task::SelfServiceDataRecorded',
    template:
      'Type: Self Service Response Received<br/>' +
      'Response Time: {{ data.data.responseDateTime|fulldatetime }} <br />' +
      'Stock at Home: {{ data.data.currentStock }} {{ data.data.currentStockUom }} <br/>' +
      'Date of Delivery: {{ data.data.deliveryDate|partialdate }} <br/>' +
      'Delivery Location: {{ data.data.deliveryLocation.name }} <br/>' +
      'Delivery Service: {{ data.data.deliveryService }} <br />' +
      'Callback Requested: {{ data.data.callbackRequested }} <br />' +
      'Confirm SMS: {{ data.data.confirmSms }} <br />' +
      'Ancillaries: {{ (data.data.ancillaries) ? "<span><br />&nbsp;&nbsp;&nbsp;&nbsp;" + (data.data.ancillaries|joinBy:"<br />&nbsp;&nbsp;&nbsp;&nbsp;") : "<span>None</span>" }}'
  },
];
export default MESSAGE_TEMPLATE;
