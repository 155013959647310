import module from '../module';

import IMessage from '#/interface/core/entity/message';
import IApiAuditTrailService from '#/interface/core/service/api-audit-trail-service';
import IApiUsersService from '#/interface/core/service/api-users-service';
import IDocumentPopoutService from '#/interface/core/service/api-document-popout-service';

import { strictAssign } from '#/utils/object';
import MESSAGE_TEMPLATE from './audit-trail-message-templates';

import WORKFLOW_CONFIRMATION_METHOD from '#/constants/list/workflow-confirmation-method';
import WORKFLOW_COURIER from '#/constants/list/workflow-courier';
import WORKFLOW_COURIER_SERVICE from '#/constants/list/workflow-courier-service';
import LOCATION_TYPE from '#/constants/list/location-types';
import TASK_DATA_TYPE from '#/constants/task-data-type';

module.component('auditTrailMessage', {
  bindings: {
    messages: '<',
    openPatientVisitForm: '<?'
  },
  controller
});

interface ISignatureImage {
  fileId: string;
  fileHash: string;
  base64Img: string | null;
}

interface IController extends ng.IOnChanges {
  readonly messages: IMessage[] | undefined;
  signatureImages: ISignatureImage[];
  openPatientVisitForm(data: any): void;
  getSignatureImage(signatureImage: ISignatureImage): void;
}

function controller(
  this: IController,
  $interpolate: ng.IInterpolateService,
  $element: ng.IRootElementService,
  $compile: ng.ICompileService,
  $rootScope: ng.IRootScopeService,
  ApiAuditTrailService: IApiAuditTrailService,
  ApiUsersService: IApiUsersService,
  DocumentPopoutService: IDocumentPopoutService,
) {
  'ngInject';

  const vm = this;

  vm.signatureImages = [];

  strictAssign(vm, { $onChanges });

  function $onChanges() {
    $element.empty();

    if (vm.messages == null) return;

    vm.messages.forEach(message => {
      if (message.data && message.data.fileId && message.data.fileHash) {
        const signatureImage: ISignatureImage = {
          fileId: message.data.fileId,
          fileHash: message.data.fileHash,
          base64Img: null,
        }

        if (vm.signatureImages.length > 0 && vm.signatureImages.find(s => s.fileId === signatureImage.fileId)) {
          const alreadyRetrievedSignatureImage = vm.signatureImages.find(s => s.fileId === signatureImage.fileId);
          if (alreadyRetrievedSignatureImage) {
            message.data.signatureImageForDisplay = alreadyRetrievedSignatureImage.base64Img;
          }
        } else {
          ApiUsersService.getSignatureImage(signatureImage.fileId).then(data => {
            const signatureImageBase64String = data.content;
            signatureImage.base64Img = signatureImageBase64String;
            vm.signatureImages.push(signatureImage);
          }).then(() => {
            const retrievedSignatureImage = vm.signatureImages.find(s => s.fileId === signatureImage.fileId);
            if (retrievedSignatureImage) {
              message.data.signatureImageForDisplay = retrievedSignatureImage.base64Img;
            }

            const template = `<div>${getMessageDescription(message)}</div>`,
              messageScope = $rootScope.$new(true),
              component = $compile(template)(messageScope);
            Object.assign(messageScope, { vm: { handleOpenDocumentFile, openPatientVisitForm: vm.openPatientVisitForm, message } });

            $element.append(component);
          }).catch(() => {
            message.data.signatureImageForDisplay = null;
            const template = `<div>${getMessageDescription(message)}</div>`,
              messageScope = $rootScope.$new(true),
              component = $compile(template)(messageScope);
            Object.assign(messageScope, { vm: { handleOpenDocumentFile, openPatientVisitForm: vm.openPatientVisitForm, message } });

            $element.append(component);
          });
        }
      } else if (message.data && message.data.data && message.data.data.selectedAncillaries) {
        const selectedAncilsByType = message.data.data.selectedAncillaries;
        if (selectedAncilsByType) {
          message.data.freeAncils = [];
          message.data.billableAncils = [];
          Object.keys(selectedAncilsByType).forEach(k => {
            const ancilsWithType = selectedAncilsByType[k].map((a: { billable: boolean; id: string; term: string; }) => {
              return {
                billable: a.billable,
                id: a.id,
                term: a.term,
              };
            });
            message.data.freeAncils = message.data.freeAncils.concat(
              ancilsWithType.filter((a: { billable: boolean; id: string; term: string; })  => a.billable === false)
            );
            message.data.billableAncils = message.data.billableAncils.concat(
              ancilsWithType.filter((a: { billable: boolean; id: string; term: string; }) => a.billable === true)
            );
          });
        }

        const template = `<div>${getMessageDescription(message)}</div>`,
        messageScope = $rootScope.$new(true),
        component = $compile(template)(messageScope);
        Object.assign(messageScope, { vm: { handleOpenDocumentFile, openPatientVisitForm: vm.openPatientVisitForm, message } });

        $element.append(component);
      } else {
        const template = `<div>${getMessageDescription(message)}</div>`,
          messageScope = $rootScope.$new(true),
          component = $compile(template)(messageScope);
        Object.assign(messageScope, { vm: { handleOpenDocumentFile, openPatientVisitForm: vm.openPatientVisitForm, message } });

        $element.append(component);
      }
    });
  }

  function handleOpenDocumentFile(documentId: string, documentVersion: number) {
    DocumentPopoutService.open(documentId, documentVersion);
  }

  function getMessageDescription(message: IMessage) {
    let templateNameOverride;
    if (message.event === 'Task::DataRecorded') {
      switch (message.data.type) {
        case TASK_DATA_TYPE.DispenseAndDeliverWorkflow:
          templateNameOverride = 'Task::WorkflowDataRecorded';
          break;
        case TASK_DATA_TYPE.PatientVisitFormFpn37:
          templateNameOverride = 'Task::PvfDataRecorded';
          break;
        case TASK_DATA_TYPE.PatientVisitFormFpn77:
          templateNameOverride = 'Task::PvfDataRecorded';
          break;
        case TASK_DATA_TYPE.PatientVisitFormFpn96:
          templateNameOverride = 'Task::PvfDataRecorded';
          break;
        case TASK_DATA_TYPE.DidNotAttendDataRecorded:
          templateNameOverride = 'Task::DidNotAttendDataRecorded';
          break;
        case TASK_DATA_TYPE.SelfServiceResponse:
          templateNameOverride = 'Task::SelfServiceDataRecorded';
          break;
        default:
          templateNameOverride = null;
          break;
      }
    }

    const key = ApiAuditTrailService.messageKey(message),
      keyFull = templateNameOverride || message.event || message.text,
      expression = getTemplate(keyFull) || getTemplate(key) || getTemplate('REGULAR');

    message = replaceEnums(message);
    escapeMessage(message);

    return $interpolate(expression)(message);
  }

  function replaceEnums(message: IMessage) {
    if (message.event === 'Task::DataRecorded' && message.data.type === TASK_DATA_TYPE.DispenseAndDeliverWorkflow) {
      const data = message.data.data;
      data.confirmationRequired = WORKFLOW_CONFIRMATION_METHOD[data.confirmationRequired].text;
      const extractedCourier = WORKFLOW_COURIER.find(c => c.value === data.courierOptions.courier);
      if (extractedCourier) {
        data.courierOptions.courier = extractedCourier.text;
      }
      if (data.courierOptions.service !== null && data.courierOptions.Service !== undefined) {
        data.courierOptions.service = WORKFLOW_COURIER_SERVICE[data.courierOptions.service].text;
      } else {
        data.courierOptions.service = null;
      }
      data.deliveryCharges = data.deliveryCharges.map(function(v: any) {
        return {
          code: v.code,
          description: v.description,
          value: `${v.code} - ${v.description}`
        };
      });
    }
    else if (message.event === 'Task::ScheduleChanged::LocationTypeChanged') {
      message.data = LOCATION_TYPE[message.data];
    }

    return message;
  }

  function escapeMessage(message: any) {
    if (message) {
      const keys: Array<string> = Object.keys(message);
      keys.forEach(k => {
        if (typeof(message[k]) === 'string') {
          message[k] = escapeString(message[k]);
        }
        else if (typeof(message[k]) === 'object') {
          escapeMessage(message[k])
        }
      });
    }
  }

  function escapeString(stringToEscape: string) {
    return stringToEscape
    .replace(/&/g, '&amp;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
    .replace(/"/g, '&quot;')
    .replace(/'/g, '&#39;')
  }

  function getTemplate(name: string) {
    const template = MESSAGE_TEMPLATE.find(_ =>
      name.toUpperCase().startsWith(_.name.toUpperCase())
    );

    return template ? template.template : '';
  }
}
