const CT_WORKFLOW_TYPE_ENUM = [
  'BrightStudyClinicalTrial',
  'BrillianceStudyClinicalTrial',
  'BalanceStudyClinicalTrial',
  'VX19147101ClinicalTrial'
];

type CT_WORKFLOW_TYPE_ENUM = typeof CT_WORKFLOW_TYPE_ENUM;

export default CT_WORKFLOW_TYPE_ENUM;
