import module from "./module";

//shorthand for clunky ng-model-options="{ updateOn='default blur', debounce: {default:MS, blur:MS} }"
module.directive("ngDebounce" /* @ngInject */, function($compile) {
  return {
    restrict: "A",
    terminal: true,
    priority: 1000,
    link: function(scope, elm, attr) {
      elm.removeAttr("ng-debounce");
      elm.removeAttr("data-ng-debounce");
      elm.attr(
        "ng-model-options",
        "{ updateOn:'default blur', debounce: {default:" + (attr.ngDebounce || 750) + ", blur:0} }"
      );
      $compile(elm)(scope);
    }
  };
});
