import module from "./module";

module.config(ppmConfig);
/* @ngInject */
function ppmConfig(
  $httpProvider,
  $locationProvider,
  $urlServiceProvider /*, $ocLazyLoadProvider*/
) {
  $httpProvider.defaults.useXDomain = true;
  delete $httpProvider.defaults.headers.common["X-Requested-With"];
  $httpProvider.defaults.headers.post["Content-Type"] = "application/json;charset=utf-8";
  $httpProvider.defaults.useXDomain = true;

  $httpProvider.interceptors.push("AuthorizationInterceptorService");
  $httpProvider.interceptors.push("TimeoutInterceptorService");
  $httpProvider.interceptors.push("ReplaceDateInterceptorService");
  $locationProvider.html5Mode(false);
  $locationProvider.hashPrefix("");
  $urlServiceProvider.rules.otherwise({ state: "dashboard.main" });
  // $ocLazyLoadProvider.config({
  //     debug: process.env.NODE_ENV === 'development'
  // });
}
