import module from "./module";

module.service("StartupProgressService", function(ApiResourcesService) {
  "ngInject";
  "use strict";

  this.status = function() {
    return ApiResourcesService.StartupProgress.status().$promise;
  };
});
