import module from "../module";
import template from "./text-picker.tmpl.html";

import { NAME_TITLE as nameTitleEnum } from "#/constants/index";

module.directive("nameTitlePicker" /* @ngInject */, function() {
  return {
    restrict: "A",
    require: "ngModel",
    replace: true,
    scope: {
      model: "=ngModel"
    },
    template,
    priority: 100,
    link: function(scope) {
      scope.enums = nameTitleEnum;
      scope.model = scope.model || "";

      scope.otherValue = "Other";

      scope.$watch("model", function(value) {
        if (value) {
          var nameTitle = scope.enums.find(_ => _.value === value);
          if (nameTitle) {
            scope.title = nameTitle.value;
            scope.otherTitle = null;
          } else {
            scope.otherTitle = value;
            scope.title = scope.otherValue;
          }
          return;
        }
        scope.title = null;
        scope.otherTitle = null;
      });
    }
  };
});
