import module from './module';

interface IPromiseService {
  chain(...asyncActions: Array<(...args: any[]) => any>): (v: any) => ng.IPromise<any>;
  mapError<V, R>(action: (v: V) => ng.IPromise<R>, map: (v: any) => any): (v: V) => ng.IPromise<R>;
}

module.factory('PromiseService', function($q: ng.IQService): IPromiseService {
  'ngInject';

  return {
    chain,
    mapError
  };

  function chain(...asyncActions: Array<(...args: any[]) => any>) {
    return (value: any) =>
      asyncActions.reduce((acc, action) => acc.then(action), $q.resolve(value));
  }

  function mapError<V, R>(asyncAction: (v: V) => ng.IPromise<R>, map: (v: any) => any) {
    return (value: V) => asyncAction(value).catch(reason => $q.reject(map(reason)));
  }
});
