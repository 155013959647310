const TASK_TYPE = {
    CallOne: 4,
    CallTwo: 5,
    CallThree: 6,
    Delivery: 7,
    NurseAdministration: 16,
    NurseTraining: 17,
    NursePhlebotomy: 18,
    PharmaxoDelivery: 26,
    DidNotAttendFollowUpCall: 29,
    NurseEcg: 47,
    InboundCall: 48,
    UrgentCall: 49,
    ClinicDelivery: 50,
    SelfServiceCallBackOne: 51,
    SelfServiceCallBackTwo: 52,
    SelfServiceCallBackThree: 53,
    SelfServiceAutomatedCommunication: 54,
    SelfServiceBooking: 56,
    CallOneSelfServiceFailed: 55,
    AwaitingDeliveryDetailsFromNurse: 59,
};

const NURSE_TASK_TYPE = [
    TASK_TYPE.NurseAdministration,
    TASK_TYPE.NurseTraining,
    TASK_TYPE.NursePhlebotomy,
    TASK_TYPE.NurseEcg,
];

type TASK_TYPE = typeof TASK_TYPE;

export default TASK_TYPE;
export { NURSE_TASK_TYPE };
