const TREATMENT_LOCATION = [
  'Home',
  'Hospital (non Pharmaxo treatment)',
  'Pharmaxo Static Clinic',
  'Pharmaxo Mobile Treatment Centre'
];

type TREATMENT_LOCATION = typeof TREATMENT_LOCATION;

export default TREATMENT_LOCATION;
