import module from "../module";

module.controller("DialogConfirmationCtrl", function(
  $uibModalInstance,
  message,
  confirmButtonText,
  cancelButtonText
) {
  "ngInject";
  "use strict";

  this.message = message;
  this.confirmButtonText = confirmButtonText;
  this.cancelButtonText = cancelButtonText;

  this.ok = function() {
    $uibModalInstance.close();
  };

  this.cancel = function() {
    $uibModalInstance.dismiss("dismiss");
  };
});
