import { IAddress } from '#/interface/core/entity/address';

export function addressToString(address: IAddress, isPrimary: boolean): string
{
  const addr = [address.line1, address.line2, address.line3, address.city, address.county, address.postCode, address.country];
  return `${isPrimary ? '(Primary) ' : ''} ${address.description ? address.description + ': ' : ''}${addr.join(', ')}`;
}

export function isAddressEmpty(address: IAddress): boolean{
  let isEmpty = true;

  if (address.line1 !== '') {
    isEmpty = false;
  }

  if (address.line2 !== '') {
    isEmpty = false;
  }

  if (address.line3 !== '') {
    isEmpty = false;
  }

  if (address.city !== '') {
    isEmpty = false;
  }

  if (address.county !== '') {
    isEmpty = false;
  }

  if (address.postCode !== '') {
    isEmpty = false;
  }

  if (address.country !== '') {
    isEmpty = false;
  }

  return isEmpty;
}
