const GENDER = [
  { value: 1, text: 'Male' },
  { value: 2, text: 'Female' },
  { value: 3, text: 'Other Specific' },
  { value: 0, text: 'Not Known' },
  { value: 4, text: 'Not Specified' }
];

type GENDER = typeof GENDER;

export default GENDER;
