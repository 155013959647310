import angular from "angular";
import module from "../module";

module
  .factory("PageStateService" /* @ngInject */, function($transitions, $rootScope) {
    var service = {},
      defaultPageState = {
        popout: false,
        view: false,
        edit: false,
        create: false
      },
      currentPageState;

    service.setPageState = setPageState;
    service.updatePageState = updatePageState;
    service.getCurrentPageState = getCurrentPageState;
    service.init = init;

    return service;

    function init() {
      service.setPageState({});

      $transitions.onStart({}, trans => {
        const toState = trans.to();

        if (!toState.data || !toState.data.pageState) {
          service.setPageState({});
        } else {
          service.setPageState(toState.data.pageState);
        }
      });
    }

    function getCurrentPageState() {
      return angular.copy(currentPageState);
    }

    function updatePageState(pageStateObject) {
      angular.extend(currentPageState, pageStateObject);
      updateRootScopePageState();
    }

    function setPageState(pageStateObject) {
      currentPageState = angular.copy(defaultPageState);
      angular.extend(currentPageState, pageStateObject);
      updateRootScopePageState();
    }

    function updateRootScopePageState() {
      $rootScope.pageState = service.getCurrentPageState();
    }
  })
  .run(
    /* @ngInject */
    function(PageStateService) {
      PageStateService.init();
    }
  );
