import { IApiClinicsService } from '#/interface/core/service/api-clinics-service';
import { IClinic, IClinicLocation, IClinicSession, IClinicSessionRequest, IUnavailablePeriodRequest, IUnavailablePeriod, IClinicDay, IUnavailablePeriodReasons } from '../../interface/core/entity/clinic';
import IApiService from '#/interface/core/service/api-service';

import module from './module';

module.factory('ApiClinicsService', ApiClinicsService);

function ApiClinicsService(ApiService: IApiService): IApiClinicsService {
  'ngInject';

  return {
    getAllClinicLocations,
    addClinicLocation,
    getAllClinics,
    addClinic,
    updateClinic,
    addChair,
    renameChair,
    getClinicById,
    getClinicSessionByIdAndDate,
    addSession,
    updateSession,
    removeSession,
    addUnavailablePeriod,
    cancelUnavailablePeriod,
    getUnavailablePeriodReasons
  };

  function getAllClinicLocations(): ng.IPromise<IClinicLocation[]> {
    return ApiService.get('locations');
  }

  function addClinicLocation(clinicLocation: IClinicLocation) {
    return ApiService.post('locations', clinicLocation);
  }

  function getAllClinics(): ng.IPromise<IClinic[]> {
    return ApiService.get('clinics');
  }

  function getClinicById(clinicId: string): ng.IPromise<IClinic> {
    return ApiService.get(`clinics/${clinicId}`);
  }

  function addClinic(clinic: IClinic): ng.IPromise<void> {
    const { description, clinicType } = clinic;
    return ApiService.post('clinics', { description, clinicType })
  }

  function updateClinic(clinic: IClinic): ng.IPromise<void> {
    const { id, description, clinicType } = clinic;
    return ApiService.put(`clinics/${id}`, { description, clinicType })
  }

  function addChair(clinicId: string, description: string): ng.IPromise<void> {
    return ApiService.post(`clinics/${clinicId}/chairs`, { description })
  }

  function renameChair(clinicId: string, chairId: string, description: string): ng.IPromise<void> {
    return ApiService.put(`clinics/${clinicId}/chairs/${chairId}`, { description })
  }

  function getClinicSessionByIdAndDate(clinicId: string, date: Date, appointmentId?: string | undefined): ng.IPromise<IClinicDay> {
    const appointmentIdParam = appointmentId ? `?ExcludedAppointmentId=${appointmentId}` : '';
    return ApiService.get(`clinics/${clinicId}/schedule/${interpolateDate(date)}${appointmentIdParam}`);
  }

  function addSession(clinicSession: IClinicSession): ng.IPromise<void> {
    const clinicSessionRequest: IClinicSessionRequest = {
      locationId: clinicSession.locationId,
      assignedUsers: clinicSession.assignedUsers,
      startTime: clinicSession.startTime!.toLocaleTimeString('en-GB'),
      endTime: clinicSession.endTime!.toLocaleTimeString('en-GB')
    }
    return ApiService.post(`clinics/${clinicSession.clinicId}/schedule/${interpolateDate(clinicSession.date)}`, clinicSessionRequest);
  }

  function updateSession(clinicSession: IClinicSession): ng.IPromise<void> {
    const clinicSessionRequest: IClinicSessionRequest = {
      locationId: clinicSession.locationId,
      assignedUsers: clinicSession.assignedUsers,
      startTime: clinicSession.startTime!.toLocaleTimeString('en-GB'),
      endTime: clinicSession.endTime!.toLocaleTimeString('en-GB')
    }
    return ApiService.put(`clinics/${clinicSession.clinicId}/schedule/${interpolateDate(clinicSession.date)}/${clinicSession.id}`, clinicSessionRequest)
  }

  function removeSession(clinicSession: IClinicSession): ng.IPromise<void> {
    return ApiService.apiDelete(`clinics/${clinicSession.clinicId}/schedule/${interpolateDate(clinicSession.date)}/${clinicSession.id}`);
  }

  function addUnavailablePeriod(clinic: IClinicDay, unavailablePeriod: IUnavailablePeriod): ng.IPromise<void> {
    const clinicUnavailablePeriod: IUnavailablePeriodRequest = {
      reason: unavailablePeriod.reason,
      startTime: unavailablePeriod.startTime!.toLocaleTimeString('en-GB'),
      endTime: unavailablePeriod.endTime!.toLocaleTimeString('en-GB')
    }
    return ApiService.post(`clinics/${clinic.clinicId}/schedule/${interpolateDate(clinic.date)}/unavailablePeriods`, clinicUnavailablePeriod);
  }

  function cancelUnavailablePeriod(clinic: IClinicDay, date: Date, unavailablePeriod: IUnavailablePeriod): ng.IPromise<void> {
    return ApiService.apiDelete(`clinics/${clinic.clinicId}/schedule/${interpolateDate(date)}/unavailablePeriods/${unavailablePeriod.id}`);
  }

  function getUnavailablePeriodReasons(): ng.IPromise<IUnavailablePeriodReasons> {
    return ApiService.get('clinics/get-unavailable-period-reasons');
  }
}

function interpolateDate(date: Date): string {
  const year = date.getFullYear();
  const jsMonth = date.getMonth();
  const jsDay = date.getDate();

  const month = jsMonth < 9 ? `0${jsMonth + 1}` : jsMonth + 1;
  const day = jsDay < 10 ? `0${jsDay}` : jsDay;

  return `${year}${month}${day}`;
}
