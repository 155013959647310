import angular from "angular";
import module from "../module";
import REGEXP from "#/constants/regexp";

module.factory("DateService" /* @ngInject*/, function() {
  var yearsObj = {
      minYear: 1900,
      maxYear: new Date().getFullYear()
    },
    months = [
      { value: 1, text: "January" },
      { value: 2, text: "February" },
      { value: 3, text: "March" },
      { value: 4, text: "April" },
      { value: 5, text: "May" },
      { value: 6, text: "June" },
      { value: 7, text: "July" },
      { value: 8, text: "August" },
      { value: 9, text: "September" },
      { value: 10, text: "October" },
      { value: 11, text: "November" },
      { value: 12, text: "December" }
    ],
    days28 = [],
    days29,
    days30,
    days31;
  yearsObj.years = getYears(yearsObj.minYear, yearsObj.maxYear);
  for (var i = 1; i <= 28; i++) {
    days28.push(i);
  }
  days29 = angular.copy(days28);
  days29.push(29);
  days30 = angular.copy(days29);
  days30.push(30);
  days31 = angular.copy(days30);
  days31.push(31);

  var factory = {
    getYears: getYears,
    getMonths: getMonths,
    getDays: getDays,
    getUTCValidDate: getUTCValidDate,
    getAge: getAge,
    getDateFromPartialDate: getDateFromPartialDate,
    isValidDate: isValidDate,
    isValidPartialDates: isValidPartialDates,
    isValidPartialDate: isValidPartialDate,
    isFilledPartialDate: isFilledPartialDate,
    isDateString: isDateString,
    replaceStringWithDate: replaceStringWithDate,
    getUTCBackwardCompatibleDate: getUTCBackwardCompatibleDate,
    isLaterDate: isLaterDate,
    convertTimeSpanStringToMinutes: convertTimeSpanStringToMinutes
  };

  return factory;

  function isLaterDate(currentDate, dateToCompare) {
    if (!angular.isDate(currentDate)) {
      throw new Error("Current is not a date.");
    }

    if (!angular.isDate(dateToCompare)) {
      throw new Error("Date to compare is not a date");
    }

    // Creates new dates for comparison to ensure the time portion of the date is ignored

    var currentDateUtc = new Date(
      Date.UTC(currentDate.getUTCFullYear(), currentDate.getUTCMonth(), currentDate.getUTCDate())
    );

    var dateToCompareUtc = new Date(
      Date.UTC(
        dateToCompare.getUTCFullYear(),
        dateToCompare.getUTCMonth(),
        dateToCompare.getUTCDate()
      )
    );

    return currentDateUtc.valueOf() <= dateToCompareUtc.valueOf();
  }

  function getYears(minYear, maxYear) {
    if (maxYear == "now") {
      maxYear = new Date().getFullYear();
    } else {
      maxYear = Number(maxYear);
      if (
        angular.isUndefined(maxYear) ||
        String(maxYear) === "NaN" ||
        maxYear <= 0 ||
        maxYear >= 3000
      ) {
        maxYear = 2100;
      }
    }

    if (minYear == "now") {
      minYear = new Date().getFullYear();
    } else {
      minYear = Number(minYear);

      if (
        angular.isUndefined(minYear) ||
        String(minYear) === "NaN" ||
        minYear <= 0 ||
        minYear >= 3000 ||
        minYear > maxYear
      ) {
        minYear = 1900;
        if (minYear > maxYear) {
          minYear = maxYear - 200;
        }
      }
    }

    if (angular.isDefined(minYear) && !angular.isNumber(minYear)) {
      throw new Error("minYear should be number");
    }
    if (angular.isDefined(maxYear) && !angular.isNumber(maxYear)) {
      throw new Error("maxYear should be number");
    }

    if (!angular.isNumber(minYear) && !angular.isNumber(maxYear)) {
      return yearsObj.years;
    } else {
      if (angular.isNumber(minYear) && !angular.isNumber(maxYear)) {
        maxYear = yearsObj.maxYear;
      }
    }
    if (yearsObj.years && yearsObj.minYear == minYear && yearsObj.maxYear == maxYear) {
      // return saved years
      return yearsObj.years;
    }
    var years = [];
    for (var i = minYear; i <= maxYear; i++) {
      years.push(i);
    }
    return years;
  }

  function getDays(month, year, maxDay) {
    if (maxDay === "now") {
      const currentDate = new Date().getDate(),
        days = [];

      for (var i = 1; i <= currentDate; i++) days.push(i);

      return days;
    }

    if (!month || !year) {
      return days31;
    } else {
      if (month == 2) {
        if (year % 4) {
          return days28;
        } else {
          return days29;
        }
      } else {
        if (
          month == 1 ||
          month == 3 ||
          month == 5 ||
          month == 7 ||
          month == 8 ||
          month == 10 ||
          month == 12
        ) {
          return days31;
        } else {
          return days30;
        }
      }
    }
  }

  function getMonths(maxMonth) {
    if (maxMonth === "now") {
      const currentMonth = new Date().getMonth() + 1;

      return months.filter(_ => _.value <= currentMonth);
    }

    return months;
  }

  function getUTCValidDate(day, month, year, minYear, maxYear) {
    var date = new Date(Date.UTC(year, month - 1, day));
    if (angular.isNumber(minYear)) {
      if (year < minYear) {
        date.setUTCFullYear(minYear);
        year = date.getUTCFullYear();
      }
    }

    if (angular.isNumber(maxYear)) {
      if (year > maxYear) {
        date.setUTCFullYear(maxYear);
        year = date.getUTCFullYear();
      }
    }

    if (day > 28) {
      if (month == 2) {
        if (year % 4) {
          date.setUTCDate(28);
        } else {
          date.setUTCDate(29);
        }
      } else {
        if (
          month == 1 ||
          month == 3 ||
          month == 5 ||
          month == 7 ||
          month == 8 ||
          month == 10 ||
          month == 12
        ) {
          date.setUTCDate(day);
        } else {
          if (day === 31) {
            date.setUTCDate(30);
          }
        }
      }
    } else {
      date.setUTCDate(day);
    }
    date.setUTCMonth(month - 1);
    return date;
  }

  function getAge(lowDate, highDate) {
    var age = false;
    if (isFullFillPartialDate(lowDate)) {
      lowDate = getDateFromPartialDate(lowDate);
    }

    if (angular.isString(lowDate) || angular.isDate(lowDate)) {
      if (angular.isString(lowDate)) {
        lowDate = new Date(lowDate);
      }
      age = highDate.getFullYear() - lowDate.getFullYear();
      var m = highDate.getMonth() - lowDate.getMonth();
      if (m < 0 || (m === 0 && highDate.getDate() < lowDate.getDate())) {
        age--;
      }
    } else {
      if (lowDate.year) {
        age = highDate.getFullYear() - lowDate.year;
      }
    }
    return age;
  }

  function isValidPartialDates(lowPartialDate, highPartialDate) {
    var lowDate, highDate;

    if (
      lowPartialDate == null ||
      highPartialDate == null ||
      angular.equals(lowPartialDate, {}) ||
      angular.equals(highPartialDate, {})
    ) {
      return true;
    }
    if (angular.isDate(lowPartialDate) && angular.isDate(highPartialDate)) {
      return lowPartialDate < highPartialDate;
    }

    if (isFullFillPartialDate(lowPartialDate)) {
      lowDate = getDateFromPartialDate(lowPartialDate);
    }

    if (isFullFillPartialDate(highPartialDate)) {
      highDate = getDateFromPartialDate(highPartialDate);
    }

    if (angular.isDate(lowDate) && angular.isDate(highDate)) {
      return lowDate < highDate;
    } else {
      if (lowPartialDate.year && highPartialDate.year) {
        return lowPartialDate.year < highPartialDate.year;
      }
    }
    return true;
  }

  function isValidDate(value) {
    return angular.isDate(value);
  }

  function isValidPartialDate(value) {
    return (
      angular.isDefined(value) &&
      value !== null &&
      angular.isObject(value) &&
      "year" in value &&
      "month" in value &&
      "day" in value
    );
  }

  function isFilledPartialDate(value) {
    return isValidPartialDate(value) && value.year !== null;
  }

  function isFullFillPartialDate(value) {
    return (
      isValidPartialDate(value) &&
      angular.isNumber(value.year) &&
      value.year > 0 &&
      value.year < 2500 &&
      angular.isNumber(value.month) &&
      value.month >= 1 &&
      value.month <= 12 &&
      angular.isNumber(value.day) &&
      value.day >= 1 &&
      value.day <= 31
    );
  }

  function getDateFromPartialDate(value, defaultValue) {
    if (isFullFillPartialDate(value)) {
      return new Date(value.year, value.month - 1, value.day);
    } else {
      return defaultValue ? defaultValue : value;
    }
  }

  function replaceStringWithDate(input) {
    for (var prop in input) {
      if (!input.hasOwnProperty(prop) || angular.isDate(input[prop])) {
        continue;
      }
      if (angular.isObject(input[prop])) {
        replaceStringWithDate(input[prop]);
        continue;
      }
      if (angular.isString(input[prop]) && isDateString(input[prop])) {
        input[prop] = new Date(input[prop]);
      }
    }
  }

  function isDateString(input) {
    return REGEXP.dateUTC.test(input);
  }

  function getUTCBackwardCompatibleDate(date) {
    // erlier local time was used including influence of DST
    // so to fix this one hour is added if hour isn't a midnight (for GB)
    if (date.getUTCHours() !== 0) {
      date = new Date(date.getTime() + 60 * 60 * 1000);
    }

    return date;
  }

  function convertTimeSpanStringToMinutes(timeSpanString) {
    const convertedDate = new Date("01/01/1970 " + timeSpanString);
    return convertedDate.getHours() * 60 + convertedDate.getMinutes();
  }
});
