import angular from "angular";
import module from "./module";

module.directive("viewState" /*@ngInject */, function($timeout) {
  return {
    restrict: "A",
    scope: false,
    priority: 2,
    link: function(scope, elem, attr) {
      function disableFields() {
        var array = elem.find("select"),
          iteratorElement;

        angular.forEach(array, function(select) {
          iteratorElement = angular.element(select);
          if (iteratorElement.hasClass("select-multiple")) {
            return;
          }
          iteratorElement.attr("disabled", "disabled");
        });
        elem.find("input").attr("disabled", "disabled");
        elem.find("textarea").attr("disabled", "disabled");
      }

      if (
        (scope.$eval(attr.viewState) === true || attr.viewState === "true") &&
        attr.viewState !== "false"
      ) {
        angular.element(elem).ready(function() {
          $timeout(disableFields, 0);
        });
      }
    }
  };
});
