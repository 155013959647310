import module from "../module";

import "./confirmation.ctrl";
import templateConfirmation from "./confirmation.tmpl.html";

import "./information.ctrl";
import templateInformation from "./information.tmpl.html";

import "./prompt";
import templatePrompt from "./prompt.tmpl.html";

import modalSize from "../../../constants/modal-size";

module.service("DialogService", function($uibModal) {
  "ngInject";

  this.confirm = function(
    message,
    dismissPromise,
    confirmButtonText = "Ok",
    cancelButtonText = "Cancel",
    size = modalSize.small
  ) {
    var result = $uibModal.open({
      template: templateConfirmation,
      controller: "DialogConfirmationCtrl as vm",
      resolve: {
        message: function() {
          return message;
        },
        confirmButtonText: () => confirmButtonText,
        cancelButtonText: () => cancelButtonText
      },
      size: size
    });

    if (dismissPromise) {
      dismissPromise.then(function(reason) {
        result.dismiss(reason);
      });
    }

    return result.result;
  };

  this.inform = function(message, dismissPromise) {
    var result = $uibModal.open({
      template: templateInformation,
      controller: "DialogInformationCtrl as vm",
      resolve: {
        message: function() {
          return message;
        }
      },
      size: modalSize.small
    });

    if (dismissPromise) {
      dismissPromise.then(function(reason) {
        result.dismiss(reason);
      });
    }

    return result.result;
  };

  this.prompt = function(message, dismissPromise) {
    const result = $uibModal.open({
      template: templatePrompt,
      controller: "DialogPromptCtrl as vm",
      resolve: {
        message: function() {
          return message;
        }
      },
      size: modalSize.small
    });

    if (dismissPromise) {
      dismissPromise.then(function(reason) {
        result.dismiss(reason);
      });
    }

    return result.result;
  };
});
