import angular from "angular";
import module from "./module";

module
  .controller("GrowlNotificationCtrl" /* @ngInject */, function(
    $scope,
    $timeout,
    growlNotification,
    model
  ) {
    var currentTimeout;

    $scope.title = model.title;
    $scope.text = model.text;
    $scope.options = model.options;
    $scope.interval = model.options.timeout;

    $scope.showclass = model.options["class"];

    $scope.close = function(res) {
      growlNotification.close(res);
    };

    if ($scope.options.sticky === false) {
      $scope.$watch("interval", restartTimer);
    }

    function restartTimer() {
      if (currentTimeout) {
        $timeout.cancel(currentTimeout);
      }

      function go() {
        growlNotification.close();
      }

      var interval = +$scope.interval;
      if (!isNaN(interval) && interval >= 0) {
        currentTimeout = $timeout(go, interval);
      }
    }
  })
  .provider("$growlNotification", function() {
    // The default options for all growls.
    var defaults = {
      class: "inverse",
      sticky: false,
      timeout: 2500
    };

    /*
             Returns the actual `$growlNotification` service that is injected in controllers
        */
    this.$get /* @ngInject */ = function(
      $http,
      $document,
      $compile,
      $rootScope,
      $controller,
      $templateCache,
      $q,
      $injector
    ) {
      var body = $document.find("body");

      function createElement(clazz) {
        var el = angular.element("<div>");
        el.addClass(clazz);
        return el;
      }

      function GrowlNotification(opts) {
        this.options = angular.extend({}, defaults, opts);
        this.growlNotificationEl = createElement();
      }

      // The `open(templateUrl, controller)` method opens the growlNotification.
      // Use the `templateUrl` and `controller` arguments if specifying them at dialog creation time is not desired.
      GrowlNotification.prototype.open = function(templateUrl, controller) {
        var self = this,
          options = this.options;

        if (templateUrl) {
          options.templateUrl = templateUrl;
        }
        if (controller) {
          options.controller = controller;
        }

        this._loadResolves().then(function(locals) {
          var $scope = (locals.$scope = self.$scope = $rootScope.$new());

          self.growlNotificationEl.html(locals.$template);

          if (self.options.controller) {
            /*var ctrl = */ $controller(self.options.controller, locals);
            //self.modalEl.children().data('ngControllerController', ctrl);
          }

          $compile(self.growlNotificationEl)($scope);
          self._insertBeforeContainer(locals.model.insertBefore);
        });

        this.deferred = $q.defer();
        return this.deferred.promise;
      };

      // closes the growlNotification and resolves the promise returned by the `open` method with the specified result.
      GrowlNotification.prototype.close = function(result) {
        this._removeElement();
        this._onCloseComplete(result);
      };

      GrowlNotification.prototype.remove = function() {
        this._removeElement();
      };

      GrowlNotification.prototype._onCloseComplete = function(result) {
        this.deferred.resolve(result);
      };

      GrowlNotification.prototype._insertBeforeContainer = function(insertBeforeContainer) {
        this.growlNotificationEl.insertBefore(insertBeforeContainer);
      };

      GrowlNotification.prototype._removeElement = function() {
        this._fade();
      };

      GrowlNotification.prototype._addElementsToDom = function() {
        body.append(this.containerEl);
      };

      GrowlNotification.prototype._removeElementsFromDom = function() {
        this.containerEl.empty();
      };

      GrowlNotification.prototype._fade = function(params) {
        var self = this;
        var e = this.growlNotificationEl;

        params = params || {};
        var fade = typeof params.fade != "undefined" ? params.fade : true;
        var fade_out_speed = params.speed || this.fade_out_speed;

        if (fade && e.animate) {
          e.animate(
            {
              opacity: 0
            },
            fade_out_speed,
            function() {
              e.animate(
                {
                  height: 0
                },
                300,
                function() {
                  self.growlNotificationEl.remove();
                }
              );
            }
          );
        } else {
          this.growlNotificationEl.remove();
        }
      };

      // Loads all `options.resolve` members to be used as locals for the controller associated with the growlNotification.
      GrowlNotification.prototype._loadResolves = function() {
        var values = [],
          keys = [],
          templatePromise,
          self = this;

        if (this.options.template) {
          templatePromise = $q.when(this.options.template);
        } else if (this.options.templateUrl) {
          templatePromise = $http
            .get(this.options.templateUrl, {
              cache: $templateCache
            })
            .then(function(response) {
              return response.data;
            });
        }

        angular.forEach(this.options.resolve || [], function(value, key) {
          keys.push(key);
          values.push(angular.isString(value) ? $injector.get(value) : $injector.invoke(value));
        });

        keys.push("$template");
        values.push(templatePromise);

        return $q.all(values).then(function(values2) {
          var locals = {};
          angular.forEach(values2, function(value, index) {
            locals[keys[index]] = value;
          });
          locals.growlNotification = self;
          return locals;
        });
      };

      /*
                    The actual `$growl` service that is injected in controllers.
                */
      return {
        // Creates a new `Growl` with the specified options.
        growlNotification: function(opts) {
          return new GrowlNotification(opts);
        },

        // creates a new `GrowlNotification` tied to the default box template and controller.
        //
        // * `result`: the result to pass to the `close` method of the dialog when the button is clicked
        // * `label`: the label of the button
        // * `cssClass`: additional css class(es) to apply to the button for styling
        box: function(insertBefore, title, text, options) {
          title = title || "";
          text = text || "";
          options = options || {};

          options = angular.extend(defaults, options);
          if (angular.isUndefined(insertBefore) || !angular.isObject(insertBefore)) {
            throw new Error("GrowlNotification Open an element for prepend");
          }
          if (text.length === 0 && title.length === 0) {
            throw new Error("GrowlNotification Open requires a title or a text (or both)");
          }

          return new GrowlNotification({
            templateUrl: "template/growl/notification.html",
            controller: "GrowlNotificationCtrl",
            resolve: {
              model: function() {
                return {
                  insertBefore: insertBefore,
                  title: title,
                  text: text,
                  options: angular.copy(options)
                };
              }
            }
          });
        }
      };
    };
  });

module.run(
  /* @ngInject */ function($templateCache) {
    $templateCache.put(
      "template/growl/notification.html",
      '<div class="growl-notif-item-wrapper {{showclass}}" style=""><div class="growl-notif-item"><div ng-if=\'options.sticky!==true\' class="growl-notif-close" ng-click="close()">&times;</div><div><b ng-show="(title.length > 0 )">{{title}}</b><p>{{text}}</p></div><div style="clear:both"></div></div></div>'
    );
  }
);
