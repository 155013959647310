// todo: incomplete - improve declaration when required

import DELIVERY_FREQUENCY_UNIT from '#/constants/delivery-frequency-unit';
import IName from './name';

interface IDocumentLinkInfo {
  linkingMethod: number;
  linkingTime: null | Date;
  linker: null | string;
  patient: null | string;
  patientCustomerId: null | string;
  enrollmentId: null | string;
}

interface IDocumentLifecycle {
  from: null | Date;
  renewel: null | Date;
  to: null | Date;
}

interface IDocumentValidationItem {
  key: string;
  value: boolean | null;
  type: number;
}

interface IDocumentValidationItems {
  documentType: string;
  checkboxes: string[];
  dropdowns: string[];
}

interface IDocumentFile {
  version: number;
  receivedTime: Date;
  receivedMethod: number;
  uploaderUserName: IName;
  fileName: string;
  fileId: string;
}

enum DocumentDataType {
  PatientVisitForm,
  RegistrationForm,
}

interface IRegistrationForm {
  timeOfReceipt: string | null;
  dateOfReceipt: Date | null;
  dateOfSignature: Date | null;
}

interface IDocumentData {
  documentDataType: DocumentDataType;
  data: IRegistrationForm;
}

interface IPrescriptionData {
  deliveryFrequency: number | null;
  deliveryFrequencyUnit: DELIVERY_FREQUENCY_UNIT | null;
  numberOfFills: number | null;
  doseChange: boolean | null;
}

interface IDocument {
  id: string;
  isNewlyGenerated: boolean;
  description: string;
  createTime: Date;
  updateTime: Date;
  creatorUserName: IName;
  customerID: string;
  // todo: customerName & customer share same
  customerName: string;
  customer: string;
  status: number;
  state: number;
  linkInfo: IDocumentLinkInfo;
  lifecycle: null | IDocumentLifecycle;
  validationItems: IDocumentValidationItem[];
  documentKind: number;
  patientName: IName;
  files: IDocumentFile[];
  originalDocumentRequired: boolean;
  originalDocumentReceived: boolean;
  archiveIDName: string;
  disabled: boolean;
  about: string;
  suspensionStatus: {
    reason: null | string;
    type: 0;
  };
  linkedPatientIsSuspendedOrRetired: boolean;
  activePurchaseOrderReference: string;
  clinicalScreenConfirmed: number | null;
  cycle: number | null;
  purchaseOrderRefOnArrival: number | null;
  dateSent: Date | null;
  approver: string;
  approvedAt: Date | null;
  documentData: IDocumentData | null;
  prescriptionData: IPrescriptionData | null;
}

export { IDocument as default, IDocumentValidationItem, IDocumentValidationItems, DocumentDataType };
