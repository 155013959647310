const NOTIFICATION_SEVERITY = {
  warning: { value: 0, text: 'Warning', cssClass: 'warning' },
  success: { value: 1, text: 'Success', cssClass: 'success' },
  error: { value: 2, text: 'Error', cssClass: 'danger' },
  info: { value: 3, text: 'Info', cssClass: 'info' },
  primary: { value: 4, text: 'Primary', cssClass: 'primary' }
};

type NOTIFICATION_SEVERITY = typeof NOTIFICATION_SEVERITY;

export default NOTIFICATION_SEVERITY;
