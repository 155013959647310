import angular from "angular";
import module from "./module";

import { CARE_SERVICE_TYPE } from "#/constants/index";

module.factory("ApiServicesService", ApiServicesService);

/* @ngInject */
function ApiServicesService(
  $q,
  $cacheFactory,
  $injector,
  ApiResourcesService,
  SortService,
  healthcareProfessionalFilter
) {
  var apiServices = ApiResourcesService.Services,
    cache = $cacheFactory("servicesDataCache"),
    api = {};

  function getHcpById(hchs, hcpId) {
    return hchs.find(_ => _.id === hcpId);
  }

  api.getServiceDirectiveNameByType = function(type) {
    const directive = CARE_SERVICE_TYPE.find(_ => _.type === type);

    return directive ? directive.directive : false;
  };

  api.isServiceDirectiveExist = function(prefix, directiveName) {
    return $injector.has(prefix + directiveName + "ServiceDirective");
  };

  api.updateScopeLeadContacts = function(scopeHcps, modelHcps, hcps, directiveName) {
    if (!modelHcps) {
      return;
    }
    for (var property in scopeHcps) {
      if (scopeHcps.hasOwnProperty(property) && modelHcps.hasOwnProperty(property)) {
        var hcp = modelHcps[property] ? getHcpById(hcps, modelHcps[property]) : false;
        if (hcp) {
          scopeHcps[property] = {
            id: modelHcps[property],
            name: healthcareProfessionalFilter(hcp)
          };
        } else {
          scopeHcps[property] = {
            name: null,
            id: ""
          };
        }
      } else {
        alert("Can't find property \"" + property + '" in "' + directiveName + '" service');
      }
    }
  };

  api.updateModelLeadContacts = function(scopeHcps, modelHcps, directiveName) {
    var contact;
    if (!modelHcps) {
      return;
    }
    for (var property in scopeHcps) {
      if (scopeHcps.hasOwnProperty(property) && modelHcps.hasOwnProperty(property)) {
        contact = scopeHcps[property];
        if (contact && contact.id) {
          modelHcps[property] = contact.id;
        } else {
          modelHcps[property] = "";
        }
      } else {
        alert("Can't find property \"" + property + '" in "' + directiveName + '" service');
      }
    }
  };

  api.getAvailableServices = function() {
    var list = cache.get("list");
    if (angular.isArray(list)) {
      return $q.when(angular.copy(list));
    } else {
      return apiServices
        .query()
        .$promise.then(SortService.naturalSorter(service => service.name))
        .then(function(data) {
          cache.put("list", angular.copy(data));
          return data;
        });
    }
  };

  api.getServiceData = function(type) {
    var services = cache.get("services") || [],
      index;

    index = services.findIndex(_ => _.type === type);
    if (index === -1) {
      services.push({ type });
      cache.put("services", angular.copy(services));
      index = services.findIndex(_ => _.type === type);
    }

    if (angular.isObject(services[index].data)) {
      return $q.when(angular.copy(services[index].data));
    } else {
      return apiServices.data({ type: type }, null).$promise.then(function(data) {
        services = cache.get("services");
        index = services.findIndex(_ => _.type === type);
        angular.extend(services[index], { data: data });
        cache.put("services", angular.copy(services));
        return data;
      });
    }
  };

  api.getServiceCustomerSettings = function(type) {
    var services = cache.get("services") || [],
      index;

    index = services.findIndex(_ => _.type === type);
    if (index === -1) {
      services.push({
        type: type
      });
      cache.put("services", angular.copy(services));
      index = services.findIndex(_ => _.type === type);
    }

    if (angular.isObject(services[index].customersettings)) {
      return $q.when(angular.copy(services[index].customersettings));
    } else {
      return apiServices.customersettings({ type: type }, null).$promise.then(function(data) {
        services = cache.get("services");
        index = services.findIndex(_ => _.type === type);
        angular.extend(services[index], { customersettings: data });
        cache.put("services", angular.copy(services));
        return data;
      });
    }
  };

  api.getServicePatientSettings = function(type) {
    var services = cache.get("services") || [],
      index;

    index = services.findIndex(_ => _.type === type);
    if (index === -1) {
      services.push({
        type: type
      });
      cache.put("services", angular.copy(services));
      index = services.findIndex(_ => _.type === type);
    }

    if (angular.isObject(services[index].patientsettings)) {
      return $q.when(angular.copy(services[index].patientsettings));
    } else {
      return apiServices.patientsettings({ type: type }, null).$promise.then(function(data) {
        services = cache.get("services");
        index = services.findIndex(_ => _.type === type);
        angular.extend(services[index], { patientsettings: data });
        cache.put("services", angular.copy(services));
        return data;
      });
    }
  };

  api.getAvailablePatientEnrollments = function(customer) {
    return api.getAvailableServices().then(services => {
      const typeMap = new Map(services.map(_ => [_.type, _])),
        result = customer.services.map(service => {
          const serviceCopy = Object.create(service),
            keyCopy = Object.create(service.key);

          keyCopy.fullName =
            typeMap.get(keyCopy.type).name + (keyCopy.name ? " - " + keyCopy.name : "");
          serviceCopy.key = keyCopy;

          return serviceCopy;
        });

      return SortService.naturalSorter(item => item.key.fullName)(result);
    });
  };

  api.placeholderConvertionTargetServices = function() {
    return $q
      .all([
        apiServices.placeholderConvertionTypes().$promise.then(_ => new Set(_)),
        api.getAvailableServices()
      ])
      .then(([targetTypeSet, services]) => services.filter(_ => targetTypeSet.has(_.type)));
  };

  return api;
}
