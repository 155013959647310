import module from "./module";
import REPOSITORY_KEY from "#/constants/repository-key";
import { getStatePermissions } from "#/utils/state-permissions";

module.run(ppmRun);
/* @ngInject */
function ppmRun(
  $rootScope,
  $state,
  $transitions,
  AuthorizationService,
  PrincipalService,
  PopoutService,
  RepositoryService,
  defaultPageService
) {
  PrincipalService.identity();

  $transitions.onStart({}, function(trans) {
    const toState = trans.to(),
      toStateParams = trans.params("to");

    $rootScope.toState = toState;
    $rootScope.toStateParams = toStateParams;

    if (PrincipalService.isIdentityResolved()) {
      AuthorizationService.authorize();
      if (toState.data && toState.data.pageState && toState.data.pageState.popout === true) {
        if (PopoutService.isMainWindow()) {
          const fromState = trans.from();

          if (fromState) {
            if (fromState.abstract) {
              return $state.target("app.close");
            } else {
              return $state.target(fromState, trans.params("from"));
            }
          } else {
            return $state.target(defaultPageService.get());
          }
        }
      }
    } else {
      const roles = getStatePermissions(toState.name) || (toState.data && toState.data.roles);

      if (!roles || !PrincipalService.isInAnyRole(roles)) {
        if (toState.name !== "account.login") {
          RepositoryService.save(
            {
              name: toState.name,
              params: toStateParams
            },
            REPOSITORY_KEY.LAST_UNAUTHORIZED_URL
          );

          return $state.target("account.login", {}, { reload: true });
        }
      }
    }
  });

  $rootScope.$on("user-logout", function(event) {
    $state.go("account.logout");
    event.preventDefault();
  });

  $rootScope.$on("user-login", function() {
    const stateData = RepositoryService.getAndRemove(REPOSITORY_KEY.LAST_UNAUTHORIZED_URL);
    if (stateData && PrincipalService.isInRole(stateData.name))
      $state.go(stateData.name, stateData.params);
    else $state.go(defaultPageService.get());
  });

  $state.onInvalid(unfoundState => {
    if (process.env.NODE_ENV === "development") {
      //eslint-disable-next-line
      console.log("state not found", unfoundState.name);
    }
  });

  PopoutService.init();
  RepositoryService.init();
}
