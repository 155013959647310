const REGISTRATION_STATUS = [
  { value: 0, text: 'Naive' },
  { value: 1, text: 'Switched Provider' },
  { value: 2, text: 'Switched Therapy' },
  { value: 3, text: 'Switched Service' }
];

type REGISTRATION_STATUS = typeof REGISTRATION_STATUS;

export default REGISTRATION_STATUS;
