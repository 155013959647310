import module from "../module";
import template from "./text-picker.tmpl.html";

import { COVER_STORY as coverStoryEnum } from "#/constants/index";

module.directive("coverStoryPicker" /* @ngInject */, function() {
  return {
    restrict: "A",
    require: "ngModel",
    replace: true,
    scope: {
      model: "=ngModel"
    },
    template,
    priority: 100,
    link: function(scope) {
      scope.enums = coverStoryEnum;
      scope.model = scope.model || null;

      scope.otherValue = "Other";

      scope.$watch("model", function(value) {
        if (value) {
          var coverStory = scope.enums.find(_ => _.value === value);
          if (coverStory) {
            scope.title = coverStory.value;
            scope.otherTitle = null;
          } else {
            scope.otherTitle = value;
            scope.title = scope.otherValue;
          }
          return;
        }
        scope.title = null;
        scope.otherTitle = null;
      });
    }
  };
});
