const SSA_TREATMENT_DURATION = [
  { value: 0, text: '0-3' },
  { value: 1, text: '3-6' },
  { value: 2, text: '6-12' },
  { value: 3, text: '>12' },
  { value: 4, text: 'Not Provided' }
];

type SSA_TREATMENT_DURATION = typeof SSA_TREATMENT_DURATION;

export default SSA_TREATMENT_DURATION;
